import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@EPIC'
import styles from './styles.styl'

const Card = ({
  icon,
  iconPath,
  title,
  subtitle,
  description,
  className,
  hover,
  height,
  width,
  children,
  ...props
}) => {
  if (icon && iconPath) throw new Error('The icon and iconPath props cannot be used together.')
  const epicIcon = icon ? <Icon type={icon} fontSize='24px' /> : null
  const imageIcon = iconPath ? <img src={iconPath} alt='card-logo' /> : null

  return (
    <div
      {...props}
      className={`${styles.card} ${hover ? styles.hover : ''} ${className}`}
      style={{ height, width }}
    >
      <div className={styles.icon}>{epicIcon || imageIcon}</div>
      {title !== '' ? <h1 className={styles.title}>{title}</h1> : null}
      {subtitle !== '' ? <h3 className={styles.subtitle}>{subtitle}</h3> : null}
      {description !== '' ? <p className={styles.description}>{description}</p> : null}
      {children}
    </div>
  )
}

Card.propTypes = {
  /** name of epic icon, if you pass in empty string nothing will be shown, this prop is mutually exclusive with iconPath  */
  icon: PropTypes.string,
  /** path to icon image, if you pass in empty string nothing will be shown, this prop is mutually exclusive with icon  */
  iconPath: PropTypes.string,
  /** Card title */
  title: PropTypes.string,
  /** Card subtitle */
  subtitle: PropTypes.string,
  /** Description about this card, in paragraph form */
  description: PropTypes.string,
  /** className passed to Card */
  className: PropTypes.string,
  /** Specifies whether or not hover effect to be present */
  hover: PropTypes.bool,
  /** Card height */
  height: PropTypes.string,
  /** Card width */
  width: PropTypes.string,
}

Card.defaultProps = {
  title: '',
  subtitle: '',
  description: '',
  className: '',
  hover: false,
  height: '250px',
  width: '250px',
}

export default Card
