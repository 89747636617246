import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.styl'
import { Button, Checkbox, IconButton, Icon, Select, ActionInput } from '@EPIC'
import { Tr, Td, chevronColor, IndeterminateCheckboxAlt } from './CommonTableComponents.js'

export function SearchBar({ globalFilter, setGlobalFilter, searchBarPlaceholder }) {
  return (
    <GlobalFilter
      filter={globalFilter}
      setFilter={setGlobalFilter}
      placeholderText={searchBarPlaceholder}
    />
  )
}

export function FilterButton({ filterCount, handleFilterBtnClick }) {
  return (
    <IconButton type='Filter' onClick={handleFilterBtnClick}>
      {`Filters${filterCount > 0 ? ` ${filterCount}` : ''}`}
    </IconButton>
  )
}

export function FilterSection({
  showAllFilters,
  setShowAllFilters,
  filters,
  selectedFilters,
  handleChangeSelectedFilters,
  handleSelectAllFilters,
  handleClearSelectedFilters,
  filterFilterQueries,
  handleUpdateFilterFilterQuery,
}) {
  const firstLevelNum = 5
  const visibleFilters = showAllFilters ? filters : filters.slice(0, firstLevelNum)
  return (
    <div className={styles.filtersSection}>
      <div className={styles.filtersInfo}>
        <p>
          <a onClick={() => handleClearSelectedFilters()}>Clear Filters</a>
        </p>
      </div>
      <div className={styles.filters}>
        {visibleFilters.map((v) => {
          const filterGroupName = v.name
          const filterGroup = v.accessor
          const selected = selectedFilters[filterGroup] || []
          const selectedCount = selected.length
          const filterFilterQuery = filterFilterQueries[filterGroup] || ''
          return (
            <FilterDropdown
              key={v.accessor}
              dropdownFilters={v}
              selected={selected}
              selectedCount={selectedCount}
              handleChangeSelectedFilters={handleChangeSelectedFilters}
              handleSelectAllFilters={handleSelectAllFilters}
              handleClearSelectedFilters={handleClearSelectedFilters}
              filterFilterQuery={filterFilterQuery}
              handleUpdateFilterFilterQuery={handleUpdateFilterFilterQuery}
              filterGroup={filterGroup}
              filterGroupName={filterGroupName}
            >
              <Button inverse={true} className={styles.filterDropdownButton}>
                {`${filterGroupName}${selectedCount > 0 ? ` (${selectedCount})` : ''}`}
                <Icon type='chevron-down' />
              </Button>
            </FilterDropdown>
          )
        })}
        {filters.length > firstLevelNum && (
          <Button
            onClick={() => setShowAllFilters(!showAllFilters)}
            inverse
          >{`Show ${filters.length - firstLevelNum} ${showAllFilters ? 'Less' : 'More'}`}</Button>
        )}
      </div>
    </div>
  )
}

export function FilterDropdown({
  children,
  dropdownFilters,
  selected,
  selectedCount,
  handleChangeSelectedFilters,
  handleSelectAllFilters,
  handleClearSelectedFilters,
  filterFilterQuery,
  handleUpdateFilterFilterQuery,
  filterGroup,
  filterGroupName,
}) {
  const dropdownFilterCount = dropdownFilters.filters.length
  const hasFilterFilters = dropdownFilterCount >= 15

  const hasActiveFilterQuery = !!filterFilterQuery.length
  const visibleFilterValues =
    hasFilterFilters && hasActiveFilterQuery
      ? dropdownFilters.filters.filter((x) =>
          x.name.toLowerCase().includes(filterFilterQuery.toLowerCase())
        )
      : dropdownFilters.filters || []
  const dropdownVisibleFilterCount = visibleFilterValues.length
  return (
    <div className={styles.filterDropdownWrapper}>
      {children}
      <div className={styles.filterDropdown}>
        <p className={styles.filterCount}>
          {`${filterGroupName} (${selectedCount}/${dropdownFilterCount})`}
        </p>
        {hasFilterFilters && (
          <ActionInput
            className={styles.actionInput}
            ariaLabel='dropdownFilter'
            value={filterFilterQuery}
            onChange={(e) => handleUpdateFilterFilterQuery(filterGroup, e)}
          />
        )}
        <div className={styles.filterCheckboxes}>
          {dropdownVisibleFilterCount > 0 ? (
            <Checkbox
              className={styles.checkTitle}
              label={
                hasActiveFilterQuery
                  ? `Select ${dropdownVisibleFilterCount} Matched`
                  : `Select All ${dropdownVisibleFilterCount}`
              }
              checked={!visibleFilterValues.filter((x) => !selected.includes(x.accessor)).length}
              onChange={() => handleSelectAllFilters(filterGroup, visibleFilterValues)}
            />
          ) : (
            <p>{'No matches'}</p>
          )}
          {visibleFilterValues.map((v) => {
            return (
              <Checkbox
                className={styles.checkboxFilter}
                key={`${filterGroup}-${v.accessor}`}
                label={v.name}
                name={v.accessor}
                checked={selected.includes(v.accessor) || false}
                onChange={(e) => handleChangeSelectedFilters(filterGroup, e)}
              />
            )
          })}
        </div>
        <div className={styles.filtersClearSelected}>
          <p>
            {selectedCount > 0 ? (
              <a
                onClick={() => handleClearSelectedFilters(filterGroup)}
              >{`Clear ${selectedCount} Selected`}</a>
            ) : (
              'Clear Selected'
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export function ColumnFilterButton({
  columns,
  toggleAll,
  columnFilterVisible,
  setColumnFilterVisible,
  columnFilterQuery,
  setColumnFilterQuery,
  withSelectableRows,
}) {
  const handleClick = () => {
    setColumnFilterVisible(!columnFilterVisible)
  }

  const handleOnClear = () => {
    setColumnFilterQuery('')
  }

  const handleBlur = () => {
    setColumnFilterVisible(false)
  }

  const usableColumns = withSelectableRows ? columns.slice(1) : columns

  const queryFilteredColumns = columnFilterQuery
    ? usableColumns.filter((c) => c.Header?.toLowerCase().includes(columnFilterQuery.toLowerCase()))
    : usableColumns || []

  const visibleColumnsCount = usableColumns.reduce((a, b) => {
    return b.isVisible ? a : a - 1
  }, usableColumns.length)

  const allColumnsVisible = visibleColumnsCount === usableColumns.length

  return (
    <div className={styles.columnFilterWrapper}>
      <IconButton type='PlusMinus' onClick={handleClick}>
        Columns
      </IconButton>
      <div
        className={classnames(
          styles.columnFilterDropdown,
          columnFilterVisible && styles.columnFilterDropdownVisible
        )}
        tabIndex='0'
        onMouseLeave={handleBlur}
      >
        <div>
          <div>
            Columns ({visibleColumnsCount}/{usableColumns.length})
          </div>
          {columns.length > 15 && (
            <ActionInput
              className={styles.actionInput}
              ariaLabel='columnFilter'
              value={columnFilterQuery}
              onChange={(e) => setColumnFilterQuery(e.target.value)}
              onClear={handleOnClear}
            />
          )}
          <div className={styles.filterCheckboxes}>
            {queryFilteredColumns.map((c) => (
              <Checkbox
                className={styles.checkboxFilter}
                key={c.id}
                label={c.Header}
                name={c.id}
                {...c.getToggleHiddenProps()}
              />
            ))}
          </div>
        </div>
        <div>
          <label className={allColumnsVisible && styles.resetDisabled}>
            <IndeterminateCheckboxAlt {...toggleAll()} disabled={allColumnsVisible} />
            <span>Reset</span>
          </label>
        </div>
      </div>
    </div>
  )
}

export function GlobalFilter({ filter, setFilter, placeholderText }) {
  return (
    <div className={styles.searchBarSection}>
      <ActionInput
        value={filter || ''}
        onChange={(e) => setFilter(e.target.value)}
        placeholder={placeholderText}
        ariaLabel='GlobalTableFilter'
      />
    </div>
  )
}

function getPagingRange(currentPage, { minLink = 1, pageCount = 10, linkCount = 5 } = {}) {
  if (linkCount > pageCount) linkCount = pageCount

  let start = currentPage - Math.floor(linkCount / 2)
  start = Math.max(start, minLink)
  start = Math.min(start, minLink + pageCount - linkCount)

  return Array.from({ length: linkCount }, (_, i) => start + i)
}

function PaginationNumbers({ gotoPage, pageIndex, pageCount, onPageClick }) {
  return (
    <>
      {getPagingRange(pageIndex + 1, { pageCount }).map((gotoPageNum) => {
        const isActive = gotoPageNum === pageIndex + 1
        return (
          <div
            key={gotoPageNum}
            className={classnames(styles.pageNumbers, isActive && styles.active)}
            onClick={() => {
              gotoPage(gotoPageNum - 1)
              onPageClick({ pageIndex: gotoPageNum - 1 })
            }}
          >
            {gotoPageNum}
          </div>
        )
      })}
    </>
  )
}

// TODO: Table Designs are WIP might be useful in the future
/*
  const PageNumberSelector = () => (
    <div>
      <div className={styles.label}>Select Page Number</div>
      <div className={styles.pageSizeSelect}>
        <Select
          border={false}
          options={pageNumbersOptions}
          value={{
            value: `${pageIndex + 1}`.toString(),
            label: `${pageIndex + 1}`.toString(),
          }}
          onChange={(selected) => gotoPage(selected.value)}
        />
      </div>
    </div>
  )
  */

export function PaginationControls({
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  pageIndex,
  totalRowCount,
  gotoPage,
  pageCount,
  pageSize,
  setPageSize,
  pageSizeOptions,
  // pageNumbersOptions, // TODO: Table Designs are WIP might be useful in the future
  onPageClick = (pageIndex) => pageIndex,
  inverse = false,
  className,
  pageSizeLabel = 'Rows Per Page:',
  allowPageSizeChange = true,
}) {
  const paginationNumbersProps = { gotoPage, pageIndex, pageCount, onPageClick }

  const goToFirstPage = () => gotoPage(0)
  const goToLastPage = () => gotoPage(pageCount - 1)
  const currentPageStart = pageIndex * pageSize + 1
  const currentPageEnd = pageIndex * pageSize + pageSize
  return (
    <div className={classnames(className, styles.paginationControls, inverse && styles.inverse)}>
      <div className={styles.section}>
        <div>
          {currentPageStart && currentPageEnd ? `${currentPageStart}-${currentPageEnd}` : null}
          {currentPageStart && currentPageEnd && totalRowCount ? ' of ' : null}
          {totalRowCount ? `${totalRowCount} Results` : null}
        </div>
      </div>

      <div className={classnames(styles.section, styles.navigation)}>
        <div className={styles.firstLast} onClick={goToFirstPage}>
          First
        </div>

        <IconButton
          bare
          type='CaretLeft'
          iconClassName={chevronColor(canPreviousPage)}
          buttonClassName={styles.caretButton}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        />

        <PaginationNumbers {...paginationNumbersProps} />

        <IconButton
          bare
          type='CaretRight'
          iconClassName={chevronColor(canNextPage)}
          buttonClassName={styles.caretButton}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        />

        <div className={styles.firstLast} onClick={goToLastPage}>
          Last
        </div>
      </div>
      <div
        className={classnames(
          styles.section,
          styles.selectionSection,
          !allowPageSizeChange && styles.hideSelectionSection
        )}
      >
        <div>
          <div className={styles.label}>{pageSizeLabel}</div>
          <div className={styles.pageSizeSelect}>
            <Select
              inverse={inverse}
              border={false}
              options={pageSizeOptions.map((sizeOpt) => ({
                value: sizeOpt,
                label: sizeOpt.toString(),
              }))}
              value={{
                value: Number(pageSize),
                label: pageSize.toString(),
              }}
              onChange={(selected) => setPageSize(selected.value)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

PaginationControls.propTypes = {
  /** can user navigate to previous page */
  canPreviousPage: PropTypes.bool,
  /** onClick for previous page button */
  previousPage: PropTypes.func,
  /** can user navigate to next page */
  canNextPage: PropTypes.bool,
  /** onClick for next page button */
  nextPage: PropTypes.func,
  /** current page index */
  pageIndex: PropTypes.number,
  /** total number of rows */
  totalRowCount: PropTypes.number,
  /** onClick navigate to page number */
  gotoPage: PropTypes.func,
  /** onClick for page number  */
  onPageClick: PropTypes.func,
  /** total number of pages */
  pageCount: PropTypes.number,
  /** number of items per page */
  pageSize: PropTypes.number,
  /** onClick for page size option */
  setPageSize: PropTypes.func,
  /** array of numbers for page size */
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  /** Default color scheme is reversed.  True will render white controls */
  inverse: PropTypes.bool,
  /** Label for Page Size dropdown */
  pageSizeLabel: PropTypes.string,
  /** Allow users to change page size */
  allowPageSizeChange: PropTypes.bool,
}

export function Pagination({ page, prepareRow, isCompact }) {
  return page.map((row, rowIx) => {
    prepareRow(row)
    return (
      <Tr key={`body-row-${rowIx}`} {...row.getRowProps()}>
        {row.cells.map((cell, cellIx) => {
          return (
            <Td
              key={`body-cell-${cellIx}`}
              {...cell.getCellProps()}
              className={isCompact && styles.compactCell}
            >
              {cell.render('Cell')}
            </Td>
          )
        })}
      </Tr>
    )
  })
}

export function NoPagination({ rows, prepareRow, isCompact }) {
  return rows.map((row, rowIx) => {
    prepareRow(row)
    return (
      <Tr key={`body-row-${rowIx}`} {...row.getRowProps()}>
        {row.cells.map((cell, cellIx) => {
          return (
            <Td
              key={`body-cell-${cellIx}`}
              {...cell.getCellProps()}
              className={isCompact && styles.compactCell}
            >
              {cell.render('Cell')}
            </Td>
          )
        })}
      </Tr>
    )
  })
}

export function NoDataRow() {
  return (
    <div className={styles.noData}>
      <div className={styles.title}>No Data Found</div>
      <div className={styles.subtitle}>Try to adjust your search or filters</div>
    </div>
  )
}

export function LoadingIndicator({ isCompact }) {
  return (
    <Tr>
      <Td className={classnames(styles.isLoading, isCompact && styles.compactCell)} colSpan='10000'>
        Loading...
      </Td>
    </Tr>
  )
}

export function LoadingRow({ message = 'Loading...', isCompact }) {
  return (
    <Tr>
      <Td className={classnames(styles.isLoading, isCompact && styles.compactCell)} colSpan='10000'>
        {message}
      </Td>
    </Tr>
  )
}
