import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './FileInput.styl'

import { Label, TextInput } from '@EPIC'

let id = 0
class FileInput extends React.Component {
  constructor(props) {
    super(props)
    this.id = id++
    this.fileInput = React.createRef()
    this.onRemove = this.onRemove.bind(this)
  }

  onRemove() {
    this.fileInput.value = ''
    if (this.props.onRemove) {
      this.props.onRemove()
    }
  }

  render() {
    const { label, required, hasErrors, errorMessage } = this.props
    let containerClass = classnames(styles.fileInput, this.props.containerClass)
    return (
      <Label
        htmlFor={`file-input-label-${this.id}`}
        label={label}
        className={containerClass}
        required={required}
        hasErrors={hasErrors}
        errorMessage={errorMessage}
      >
        <input
          ref={(ref) => {
            this.fileInput = ref
          }}
          onChange={this.props.onChange}
          name={this.props.name}
          id={`er-file-input-${this.id}`}
          accept={this.props.accept || '*'}
          type='file'
        />
        <label htmlFor={`er-file-input-${this.id}`}>
          <div className={styles.fileSelect}>
            <span className={styles.plus}>+</span>
          </div>
        </label>
        <span className={styles.textSection}>
          {this.props.imgUrl && (
            <button onClick={this.onRemove} className={styles.fileInputRemove}>
              <i className='epic-icon epic-icon-close-x'></i>
            </button>
          )}
          <TextInput
            disabled
            type='text'
            border={false}
            placeholder='Choose File'
            value={this.props.imgUrl ? `${this.props.imgUrl.slice(0, 35)}...` : ''}
            className={this.props.className || ''}
          />
        </span>
      </Label>
    )
  }
}

FileInput.propTypes = {
  /** name for input */
  name: PropTypes.string,
  /** label for input */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /** accept attribute for file input element. defaults to `*` */
  accept: PropTypes.string,
  /** imgUrl for display on input */
  imgUrl: PropTypes.string,
  /** class for textInput section of fileinput  */
  className: PropTypes.string,
  /** class for container of file input  */
  containerClass: PropTypes.string,
  /** onRemove handler for input, will remove file from input */
  onRemove: PropTypes.func,
  /** onChange handler for input, returns whole event for full control of file data */
  onChange: PropTypes.func.isRequired,
  /** Any html attributes to apply to component */
  attributes: PropTypes.object,
}

FileInput.defaultProps = {
  className: '',
}

export default FileInput
