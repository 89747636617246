import React, { useEffect, useState, useRef } from "react";
import { Text, TextField, Icon, Select, Button, Table, TableCell, TableHeading, ButtonGroup, Modal, Snack, Snackbar, Popover, Spinner, ToggleButton, RadioGroup, Radio, Toggle } from "@nike/eds";
import axios from 'axios';
import apiService from '../../services/service';
import API_INTERFACE_URI from "../../constants/uri.constants";
import InfoModal from "./InfoModal.js";
import './ShipVia.css';
import { nanoid } from "nanoid"
import * as XLSX from 'xlsx';
import AddEditShipVia from "./AddEditShipVia";
import SnackBar from "../SnackBar/SnackBar.js";

function ShipVia({ groups }) {
    let height = window.innerHeight;
    const [selectedCarrier, setSelectedCarrier] = useState([]);
    const [selectedDC, setSelectedDC] = useState([]);
    const [shipViaData, setShipViaData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [infoModal, setInfoModal] = useState({});
    const [dcArray, setDCarray] = useState([])
    const [carrierArray, setCarrierArray] = useState([])
    const [modeArray, setModeArray] = useState([])
    const [snackMessage, setShowSnack] = useState({ message: "", status: "" });
    const [snackModalMessage, setShowModalSnack] = useState({ message: "", status: "" });
    const [selectedFile, setSelectedFile] = useState();
    const [showResults, setShowResults] = useState(false);
    const [consolidatedResult, setConsolidatedResult] = useState([]);
    const [endOfRecords, setEndOfRecords] = useState();
    const [previousButton, setPreviousButton] = useState(true);
    const [displayMessage, setDisplayMessage] = useState('Select search criteria and submit to display results.')
    const [searchText, setSerachText] = useState('');
    const [showSpinner, setShowSpinner] = useState(true);
    const [modalSpinner, setModalSpinner] = useState(false);
    const [size, setSize] = useState(30);
    const [pageNo, setPageNo] = useState(1);
    const [addShipVIAModal, setAddShipViaModal] = useState(false);
    const [modalType, setModalType] = useState('add');
    const [editDetails, setEditDetails] = useState();
    const [addEditModal, setAddEditModal] = useState();
    const inputRef = useRef();

    const onChangeSearch = e => setSerachText(e.target.value);
    const handleFileInput = (file) => {
        setSelectedFile(file);
    }

    useEffect(() => {
        getMasterData()
    }, [])

    async function getShipVia() {
        if (selectedDC.length > 0 || selectedCarrier.length > 0 || searchText.length > 0) {
            let selectedDcValues = selectedDC.map((e) => e.value).toString();
            let selectedCarrierValues = selectedCarrier.map((e) => e.value).toString();
            let shipViaSearch = searchText;

            try {
                const res = await apiService.get(
                    API_INTERFACE_URI.GET_SHIPVIA_DATA + "nodeCode=" + selectedDcValues + "&carrier=" + selectedCarrierValues + "&shipVia=" + shipViaSearch
                );
                if (res && res.data && res.data.records) {
                    setShowResults(true);
                    let shipVia_Data = [];
                    res.data.records.map((a) => {
                        shipVia_Data.push({
                            activeIndicator: a.activeIndicator,
                            allocationColumnMappingAlteryx: a.allocationColumnMappingAlteryx,
                            byPassIndicator: a.byPassIndicator,
                            capacityBucket: a.capacityBucket,
                            carrierGroupName: a.carrierGroupName,
                            channel: a.channel,
                            coldLaneIndicator: a.coldLaneIndicator,
                            id: a.id,
                            meansCode: a.meansCode,
                            modeCode: a.modeCode,
                            nodeCode: a.nodeCode,
                            nodeName: a.nodeName,
                            oscLaneIndicator: a.oscLaneIndicator,
                            scacCode: a.scacCode,
                            secondaryProviderName: a.secondaryProviderName,
                            serviceLevel: a.serviceLevel,
                            serviceProviderId: a.serviceProviderId,
                            shipVia: a.shipVia,
                            shipViaDesc: a.shipViaDesc,
                            vdsColumnMapping: a.vdsColumnMapping,
                            history: a.history
                        })
                    })
                    setShipViaData(shipVia_Data);
                    if (shipVia_Data.length <= size) {
                        setEndOfRecords(true);
                    } else {
                        setEndOfRecords(false);
                    }
                }
                if (res && res.data && res.data.records.length == 0) {
                    setShowResults(false);
                    setShipViaData([])
                    setDisplayMessage("No Records found for above search criteria.")
                }
            } catch (e) {
                setShowResults(false);
                setShipViaData([])
                setDisplayMessage("Ship Via data could not be retrieved, Please try again later.")
            }
        } else {
            setShowSnack({ message: "Please select DC or Carrier or type Search text!", status: "error" })
            setShowResults(false);
        }
    }
    useEffect(() => {
        displayShipViaData();
    }, [shipViaData])

    async function getMasterData() {
        try {
            const res = await apiService.get(API_INTERFACE_URI.GET_MASTER_DATA);
            if (res && res.data && res.data.objects) {
                let dcArray = [
                    ...new Map(res.data.objects.map((item) => [item["nodeName"], item])).values()
                ];
                setDCarray([...dcArray.map((data) => ({ "value": data.PlntCd, "label": data.nodeName })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
                let carrierArray = [
                    ...new Map(res.data.objects.map((item) => [item["CarrierGroupName"], item])).values()
                ];
                setCarrierArray([...carrierArray.map((data) => ({ "value": data.CarrierGroupName, "label": data.CarrierGroupName })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
                let modeArray = [
                    ...new Map(res.data.objects.map((item) => [item["ServiceLevel"], item])).values()
                ];
                setModeArray([...modeArray.map((data) => ({ "value": data.ServiceLevel, "label": data.ServiceLevel })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
            }
        } catch (e) {
            setShowResults(false);
            setShipViaData([]);
            setDisplayMessage("Ship Via page can not be displayed at the moment, Please try again later.");
        }
    }

    function selectCarrierValues(data, item) {
        setSelectedCarrier([...data])
    }

    function selectDCValues(data, item) {
        setSelectedDC([...data])
    }

    function onInfo(history) {
        // setEditCapacityDetails({"date": date, "column": column, "capacity": capacityVal, "id":id, "statuses":statuses, "history":history});
        setInfoModal({ showInfoModal: true, history: history });
    }

    function validateFile(file) {
        const validNumber = new RegExp('^[0-9]*$');
        const validIndicator = new RegExp('^[0-1]*$');
        const validAlphaNumber = new RegExp('^[0-9a-zA-Z]*$');
        const validTMS = new RegExp("^[a-zA-Z0-9 ]*$");
        const reader = new FileReader();
        reader.onload = function (evt) {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[1];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { raw: false });
            let indicatorArray = ['bypassindicator', 'osclaneindicator', 'activeindicator', 'coldlaneindicator'];
            let alphanumericColumns = ['carriergroupname', 'servicelevel', 'mode', 'means', 'shipvia', 'scac', 'capacitybucket', 'channel'];
            let invalidRecords = [];
            if (data && data.length > 0) {
                data.forEach((elem, i) => {
                    Object.entries(elem).forEach(([key, value], index) => {
                        if (key.toLowerCase() === 'action') {
                            if (!(value === 'ADD' || value === 'UPDATE')) {                                
                                invalidRecords.push( "Invalid value for " + key+ " at row "+ (i+2) +" (Only ADD & UPDATE values are allowed).");                                
                            }
                        }
                        if (key.toLowerCase() === 'plntcd') {
                            if (!validNumber.test(value)) {                                
                                invalidRecords.push( "Invalid value for " + key+ " at row "+ (i+2));                                
                            }
                        }
                        if (indicatorArray.includes(key.toLowerCase())) {
                            if (!validIndicator.test(value)) {                                
                                invalidRecords.push( "Invalid value for " + key + " at row "+ (i+2));                                
                            }
                        }
                        if (key.toLowerCase() === 'tmspartner') {
                            if (!validTMS.test(value)) {                                
                                invalidRecords.push( "Invalid value for " + key+ " at row "+ (i+2));                                
                            }
                        }
                        if (alphanumericColumns.includes(key.toLowerCase())) {
                            if (!validAlphaNumber.test(value)) {                                
                                invalidRecords.push( "Invalid value for " + key+ " at row "+ (i+2));                                
                            }
                        }
                    })
                })
            } else {                
                invalidRecords.push( "Empty Sheet!");                
            }
            if(invalidRecords && invalidRecords.length > 0){
                setShowModalSnack({message :invalidRecords, status: "error"});
                setModalSpinner(false);
                inputRef.current.value = "";
                setSelectedFile(''); 
            }else{
                uploadFiletoS3(file);
            }
        };
        reader.readAsBinaryString(file);
    }
    async function uploadFile(file) {
        if (file && file.name) {
            let fileType = file.name.split('.')[1];
            if (file.name && (fileType == 'xlsx' || fileType == 'xls')) {
                await validateFile(file);
                setModalSpinner(true);
            } else {
                setShowModalSnack({ message: "Please select excel file !", status: "error" })
            }
        } else {
            setShowModalSnack({ message: "Please select excel file !", status: "error" })
        }
    }
    async function uploadFiletoS3(file) {        
        try {
            const res = await apiService.get(API_INTERFACE_URI.GET_PRESIGNED_URL + "fileName=ship-via/" + file.name);

            if (res.data.url) {
                const presignedS3Url = res.data.url;
                const axiosResponse = await axios.put(presignedS3Url, file);

                if (axiosResponse.status == 200) {
                    let fileDetails = { "fileName": file.name }
                    try {
                        const saveDataResponse = await apiService.post(API_INTERFACE_URI.SAVE_SHIPVIA_DATA, fileDetails);
                        let failedRecords = [];
                        if(saveDataResponse.data && (saveDataResponse.data.failedRecords.length > 0 || saveDataResponse.data.failedGeoOperationalEntities.length > 0 || saveDataResponse.data.failedServiceProviders.length > 0) ){
                            failedRecords = [...saveDataResponse.data.failedRecords, ...saveDataResponse.data.failedGeoOperationalEntities, ...saveDataResponse.data.failedServiceProviders];
                            inputRef.current.value = "";
                            setSelectedFile('');
                            setModalSpinner(false);
                            setShowModalSnack({ message: failedRecords, status: "error" });
                        } else {
                            inputRef.current.value = "";
                            setSelectedFile('');
                            setVisible(!visible);
                            setModalSpinner(false);
                            setShowSnack({ message: "File Uploaded Successfully !", status: "success" });
                            (selectedDC.length > 0 || selectedCarrier.length > 0 || searchText) && getShipVia();
                        }
                    } catch (e) {
                        console.log('error', e);
                        inputRef.current.value = "";
                        setSelectedFile('');
                        setModalSpinner(false);
                        setShowModalSnack({ message: "Something went wrong. Please contact your administrator.", status: "error" });
                    }
                }
            }
        } catch (e) {
            console.log('error', e);
            setModalSpinner(false);
            setShowModalSnack({ message: "Something went wrong. Please clear cache or contact your administrator.", status: "error" });
        }        
    }

    async function displayShipViaData() {
        if (shipViaData && shipViaData.length > 0) {
            setConsolidatedResult(shipViaData.slice(0, size));
            setShowSpinner(false);
        } else {
            setConsolidatedResult([])
        }
    }

    function onNext() {
        let page = pageNo + 1;
        let next = shipViaData.slice(size * pageNo, page * size);
        setPageNo(page);
        if (page * size >= shipViaData.length) {
            setEndOfRecords(true)
        }
        setPreviousButton(false)
        setConsolidatedResult(next);
    }

    function onPrevious() {
        let page = pageNo - 1;
        let previous = shipViaData.slice(size * (page - 1), page * size);
        setPageNo(page);
        if (page == 1) {
            setPreviousButton(true);
        }
        setEndOfRecords(false);
        setConsolidatedResult(previous);
    }

    async function formatShipViaData(shipViaResponse) {
        var formatedData = [];
        if (shipViaResponse && shipViaResponse.length > 0) {
            shipViaResponse.map((a) => {
                formatedData.push({
                    "Action": "UPDATE",
                    "PlntCd": a.nodeCode,
                    "TmsPartner": a.secondaryProviderName,
                    "CarrierGroupName": a.carrierGroupName,
                    "ServiceLevel": a.serviceLevel,
                    "Mode": a.modeCode,
                    "Means": a.meansCode,
                    "ShipVia": a.shipVia,
                    "SCAC": a.scacCode,
                    "ShipViaDescription": a.shipViaDesc,
                    "CapacityBucket": a.capacityBucket,
                    "Channel": a.channel,
                    "Allocation Column Mapping - Altyrex": a.allocationColumnMappingAlteryx,
                    "VDS  Column Mapping": a.vdsColumnMapping,
                    "BypassIndicator": a.byPassIndicator,
                    "OSCLaneIndicator": a.oscLaneIndicator,
                    "ActiveIndicator": a.activeIndicator,
                    "ColdLaneIndicator": a.coldLaneIndicator,
                });
            });
            return formatedData;
        } else {
            return []
        }
    }

    async function downloadExcel() {
        let selectedDcValues = selectedDC.map((e) => e.value).toString();
        let selectedCarrierValues = selectedCarrier.map((e) => e.value).toString();
        let shipViaSearch = searchText;
        let maxSize = 90;
        try {
            // change
            const res = await apiService.get(
                API_INTERFACE_URI.GET_SHIPVIA_DATA + "nodeCode=" + selectedDcValues + "&carrier=" + selectedCarrierValues + "&shipVia=" + shipViaSearch
            );
            const sheet2 = await formatShipViaData(res.data.records);
            const ws2 = XLSX.utils.json_to_sheet(sheet2);
            const sheet1 = [
                { 'Column Format & Index': 'Action', '': 'PlntCd', ' ': 'TmsPartner', '  ': 'CarrierGroupName', '    ': 'ServiceLevel', '     ': 'Mode', '      ': 'Means', '       ': 'ShipVia', '        ': 'SCAC', '         ': 'ShipViaDescription', '          ': 'CapacityBucket', '           ': 'Channel', '            ': 'Allocation Column Mapping - Altyrex', '             ': 'VDS  Column Mapping', '              ': 'BypassIndicator', '               ': 'OSCLaneIndicator', '                ': 'ColdLaneIndicator', '                  ': 'ActiveIndicator' },
                { 'Column Format & Index': '' },
                { 'Column Format & Index': 'TOP processes the data by the column Index and file with the exact column names is highly recommended' },
                { 'Column Format & Index': '' },
                { 'Column Format & Index': 'Row', '': 'Details', ' ': 'Sample', '  ': 'Comment' },
                { 'Column Format & Index': '' },
                { 'Column Format & Index': 'Action', '': 'Action Code', ' ': 'ADD', '  ': 'ADD/UPDATE' },
                { 'Column Format & Index': 'PlntCd', '': 'Node Code', ' ': 1008 },
                { 'Column Format & Index': 'TmsPartner', '': 'Name of TMs Partner', ' ': 'Centiro' },
                { 'Column Format & Index': 'CarrierGroupName', '': 'Carrier', ' ': 'FedEx' },
                { 'Column Format & Index': 'ServiceLevel', '': 'Serivce Level', ' ': 'GRND' },
                { 'Column Format & Index': 'Mode', '': 'Mode', ' ': 'ROAD' },
                { 'Column Format & Index': 'Means', '': 'Means', ' ': 'PARCEL' },
                { 'Column Format & Index': 'ShipVia', '': 'Ship Via Code', ' ': '3HB1' },
                { 'Column Format & Index': 'SCAC', '': 'SCAC Code', ' ': 'CDFG' },
                { 'Column Format & Index': 'ShipViaDescription', '': 'Ship Via Desc', ' ': 'FedEx Home Delivery Bypass E' },
                { 'Column Format & Index': 'CapacityBucket', '': 'Capacity Bucket code', ' ': '3HBE' },
                { 'Column Format & Index': 'Channel', '': 'Channel', ' ': 'DIGITAL' },
                { 'Column Format & Index': 'Allocation Column Mapping - Altyrex', '': 'Allocation Column Mapping', ' ': 'ADAPT Allocations' },
                { 'Column Format & Index': 'VDS  Column Mapping', '': 'VDS Column Mapping', ' ': 'FedEx' },
                { 'Column Format & Index': 'BypassIndicator', '': 'Bypass Lane Indicator', ' ': 0, '  ': '0/1' },
                { 'Column Format & Index': 'OSCLaneIndicator', '': 'OSC Lane Indicator', ' ': 1, '  ': '0/1' },
                { 'Column Format & Index': 'ColdLaneIndicator', '': 'Cold Lane Indicator', ' ': 0, '  ': '0/1' },
                { 'Column Format & Index': 'ActiveIndicator', '': 'Ship Via Active Indiactor', ' ': 1, '  ': '0/1' }
            ]
            const ws1 = XLSX.utils.json_to_sheet(sheet1);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws1, 'Definition');
            XLSX.utils.book_append_sheet(wb, ws2, 'ShipVia');
            XLSX.writeFile(wb, 'shipVia_data.xlsx');
        } catch (e) {
            console.log('error', e)
        }
    }

    function cancelUpload() {
        inputRef.current.value = "";
        setSelectedFile('');
        setVisible(!visible);
    }

    function onAdd() {
        setAddShipViaModal(true);
        setModalType("add");
        setEditDetails({});
    }

    function onEdit(row) {
        setAddShipViaModal(true);
        setModalType("edit");
        setEditDetails({
            ...row
        });
    }

    function onDismissSnack(prop) {
        setShowSnack(false);
        setShowModalSnack(false);
    }
    function closeShipViaModal(prop) {
        setAddShipViaModal(prop)
    }
    useEffect(() => {
        if (addEditModal) {
            shipViaUpdated(addEditModal);
        }
    }, [addEditModal])
    function shipViaUpdated(addEditModal) {
        setShowSnack(addEditModal);
        (selectedDC.length > 0 || selectedCarrier.length > 0 || searchText) && getShipVia();
    }
    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 main-container" style={{ minHeight: (height - 120) + 'px' }}>
            {snackMessage && snackMessage.message ?
                <SnackBar onDismissFunction={() => onDismissSnack()}
                    params={{ "status": snackMessage.status, "message": snackMessage.message }}
                />
                : null}
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-align-left">
                    <Text font={"title-6"} as={"h6"} className="allocation-text"> ShipVia Configurations </Text>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 flexBtn no-right-padding  no-left-padding">
                    <Button onClick={() => getShipVia()} variant="primary"><Icon name="Search" /></Button>

                    &nbsp;&nbsp;
                    {
                        showResults &&

                        <Button onClick={() => downloadExcel()}>
                            <Icon name="Download" />
                        </Button>
                    }
                    &nbsp;&nbsp;
                    <>
                        {(groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) &&
                            <Button onClick={() => setVisible(!visible)}>
                                <Icon name="Upload" />
                            </Button>
                        }
                    </>
                    &nbsp;&nbsp;
                    <>
                        {(groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) &&
                            <Button onClick={() => onAdd()} >
                                <Icon name="Plus" />
                            </Button>
                        }
                    </>


                    {addShipVIAModal ? <AddEditShipVia shipViaUpdated={(val) => setAddEditModal(val)} closeModal={(prop) => closeShipViaModal(prop)} showModal={addShipVIAModal} editDetails={editDetails} modalType={modalType} /> : null}

                    <>
                        {
                            visible ?
                                <Modal
                                    onDismiss={() => setVisible(!visible)}
                                    isOpen={visible}
                                    headerSlot={"Upload File"}
                                    footerSlot={
                                        <ButtonGroup>
                                            <div className="upload-btn">
                                                <Button disabled={modalSpinner} onClick={() => uploadFile(selectedFile)} size="small">
                                                    Upload
                                                </Button>
                                            </div>
                                            <div className="cancel-btn">
                                                <Button
                                                    onClick={() => cancelUpload()}
                                                    size="small"
                                                    variant="secondary"
                                                    disabled={modalSpinner}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </ButtonGroup>
                                    }
                                    className="text-align-left upload-modal"
                                >
                                    {snackModalMessage && snackModalMessage.message ?
                                        <SnackBar onDismissFunction={() => onDismissSnack()}
                                            params={{ "status": snackModalMessage.status, "message": snackModalMessage.message }}
                                        />
                                        : null}
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                        }}
                                        className="upload-modal-content"
                                    >
                                        <TextField
                                            id="file"
                                            type="file"
                                            subtitle="Select File"
                                            ref={inputRef}
                                            accept=".xlsx, .xls"
                                            onChange={(e) => handleFileInput(e.target.files[0])}
                                        />
                                        {
                                            modalSpinner &&
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 modal-spinner ">
                                                <Spinner size="large" />
                                            </div>
                                        }
                                    </div>
                                </Modal>
                                : null
                        }
                    </>
                </div>
                <form className="row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 no-right-padding">
                    <>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 no-right-padding">
                            <div className="text-align-left input-box">
                                <Select
                                    name={"DCValue"}
                                    isMulti
                                    options={dcArray}
                                    subtitle="DC"
                                    hasErrors={false}
                                    errorMessage="This is an error"
                                    onChange={(newValue, object) => selectDCValues(newValue, object)}
                                    value={selectedDC}
                                />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 no-right-padding">
                            <div className="text-align-left input-box">
                                <Select
                                    name={"CarrierValue"}
                                    isMulti
                                    options={carrierArray}
                                    subtitle="CARRIER"
                                    hasErrors={false}
                                    errorMessage="This is an error"
                                    onChange={(newValue, object) => selectCarrierValues(newValue, object)}
                                    value={selectedCarrier}
                                />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 no-right-padding text-align-left input-box">
                            <TextField
                                type="text"
                                subtitle="SEARCH"
                                id="search"
                                value={searchText}
                                onChange={onChangeSearch}
                                beforeSlot={<Icon name="Search" />}
                                placeholder="ShipVia"
                            />
                        </div>
                    </>
                </form>
                {
                    showResults ?
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-1rem no-right-padding">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 shipVia-box no-right-padding" style={{ maxHeight: (height) + 'px' }}>
                                {
                                    <div className="shipVia-table" >
                                        <Table id="shipVia-table">
                                            <thead>
                                                <tr>
                                                    {/* <TableHeading className="shipVia-header-cell">Sr No</TableHeading> */}
                                                    <TableHeading className="shipVia-header-cell">DC</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">TMS Partner</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">Carrier</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">Service Level</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">Mode</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">Means</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">Capacity Bucket</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">Channel</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">Allocation Column Mapping Alteryx</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">VDS Column</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">ShipVia</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">ShipVia Description</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">SCAC</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">Bypass Lane</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">OSC Lane</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">Cold Lane</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">ON/OFF</TableHeading>
                                                    <TableHeading className="shipVia-header-cell">Info</TableHeading>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    consolidatedResult && consolidatedResult.map((row, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                {/* <TableCell className="shipVia-cell">{(index + 1) + (size * (pageNo - 1))}</TableCell> */}
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.nodeName}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.secondaryProviderName}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.carrierGroupName}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.serviceLevel}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.modeCode}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.meansCode}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.capacityBucket}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.channel}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.allocationColumnMappingAlteryx}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.vdsColumnMapping}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.shipVia}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.shipViaDesc ? row.shipViaDesc : '-'}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.scacCode}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.byPassIndicator == "1" ? "YES" : "NO"}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.oscLaneIndicator == "1" ? "YES" : "NO"}</TableCell>
                                                                <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="shipVia-cell">{row.coldLaneIndicator == "1" ? "YES" : "NO"}</TableCell>
                                                                <TableCell className="shipVia-cell">
                                                                    <Toggle id={nanoid(10)} checked={row.activeIndicator} disabled={true} />
                                                                </TableCell>
                                                                <TableCell className="fills-action-cell">
                                                                    <Icon name="Info" size="s" onClick={() => onInfo(row.history)} backgroundShape="circle" backgroundColor="var(--eds-color-grey-2)" />
                                                                </TableCell>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                                {showSpinner &&
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 table-spinner">
                                        <Spinner size="large" />
                                    </div>
                                }
                                {infoModal.showInfoModal ? <InfoModal infoModal={infoModal} /> : null}
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 row padding-top-bottom-1rem">
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 paginationBtn">
                                    <Button disabled={previousButton} onClick={() => onPrevious()}>
                                        Previous
                                    </Button>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 page-no">
                                    {/* Page: 1/10 */}
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 paginationBtn">
                                    <Button disabled={endOfRecords} onClick={() => onNext()}>
                                        &nbsp;&nbsp;Next&nbsp;&nbsp;
                                    </Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-1rem no-right-padding">
                            <Text font={"title-6"} as={"h6"} className="">{displayMessage}</Text>
                        </div>
                }
            </div>
        </div>
    );
}
export default ShipVia;