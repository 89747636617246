import React, { useEffect, useState, useRef } from "react";
import { Text, TextField, Icon, Select, Button, Table, TableCell, TableHeading, ButtonGroup, Modal, Snack, Snackbar, Popover, Spinner } from "@nike/eds";
import "./Vds.css";
import apiService from '../../services/service';
import API_INTERFACE_URI from "../../constants/uri.constants";
import SnackBar from "../SnackBar/SnackBar";
import AddEditVDS from "./AddEditVDS";
import * as XLSX from 'xlsx';
import axios from 'axios';
import InfoModal from "./InfoModal.js";

const Vds = ({ groups }) => {
    let height = window.innerHeight;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [currentStartDate, setCurrentStartDate] = useState();
    const [dateRange, setDateRange] = useState()
    const [dcArray, setDCarray] = useState([]);
    const [modeArray, setModeArray] = useState([]);
    const [selectedDC, setSelectedDC] = useState([]);
    const [selectedMode, setSeletedMode] = useState({});
    const [searchText, setSearchText] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [vdsData, setVdsData] = useState([]);
    const [addEditModal, setAddEditModal] = useState(false);
    const [modalType, setModalType] = useState('add');
    const [editDetails, setEditDetails] = useState();
    const [addEditModalValue, setAddEditValueModal] = useState();
    const [size, setSize] = useState(30);
    const [endOfRecords, setEndOfRecords] = useState();
    const [nextDate, setNextDate] = useState(startDate);
    const [previousDate, setPreviousDate] = useState(startDate);
    const [previousButton, setPreviousButton] = useState(true);
    const [displayMessage, setDisplayMessage] = useState("Select Search criteria and submit to display results");
    const [snackMessage, setShowSnack] = useState({
        message: "",
        status: ""
    })
    const [consolidatedResult, setConsolidatedResult] = useState();
    const [showSpinner, setShowSpinner] = useState(true);
    const [header, setHeader] = useState([])
    const [snackModalMessage, setShowModalSnack] = useState({
        message: "",
        status: ""
    });
    const [visible, setVisible] = useState(false)
    const [modalSpinner, setModalSpinner] = useState(false);
    const inputRef = useRef();
    const [selectedFile, setSelectedFile] = useState()
    const [infoModal, setInfoModal] = useState({});
    const [popover, setPopoverShown] = useState({ "popoverShown": false, "id": "" });





    useEffect(() => {
        setPreviousDate(startDate);
        getMasterData();
    }, [])

    const getMasterData = async () => {
        try {
            const res = await apiService.get(API_INTERFACE_URI.GET_MASTER_DATA);
            if (res && res.data && res.data.objects) {

                let dcArray = [
                    ...new Map(res.data.objects.map((item) => [item["nodeName"], item])).values()
                ];

                setDCarray([{"value": "All", "label": "All"}, ...dcArray.map((data) => ({ "value": data.PlntCd, "label": data.nodeName })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
                let modeArray = [
                    ...new Map(res.data.objects.map((item) => [item["ModeCode"], item])).values()
                ];

                setModeArray([...modeArray.map((data) => ({ "value": data.ModeCode, "label": data.ModeCode })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
            }
        } catch (error) {
            console.log(error)
            setShowResults(false);
            setDisplayMessage("VDS page can not be displayed at the moment, Please try again later.");
        }

    }

    function selectDCValues(data, item) {
        setSelectedDC([...data])

        let array = dcArray.filter((e)=> e.value != 'All');
        if(data && data.length > 0){  
            let checkdata = data.filter((e)=> e.value == 'All');   
            if(checkdata && checkdata.length > 0){        
                setSelectedDC([...array]);
                setDCarray([...array]);
            }                   
        }
        if(dcArray && dcArray.length > 0){
                let checkmode = dcArray.filter((e)=> e.value == 'All');
                if(checkmode && checkmode.length == 0){
                    setDCarray([{"value": "All", "label": "All"}, ...array]);
                }
        }
    }

    const selectModeValues = async (data, item) => {
        setSeletedMode(data)
    }

    const onStartDate = async (e) => {
        setStartDate(e.target.value);
        let d = new Date(e.target.value);
        d.setDate(d.getDate() + 89);
        let dateRange = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
        setDateRange(dateRange);
        setEndDate('');
    }

    const getVdsData = async (startDates) => {
        let size = 30;
        setCurrentStartDate(startDates);
        setPreviousButton(startDates == startDate ? false : true);
        if (startDates && endDate && selectedDC.length > 0 && selectedMode.value) {
            var d = new Date(startDates);
            d.setDate(d.getDate() - 30);
            let tempDate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
            let preDate = new Date(tempDate).getTime() < new Date(startDate).getTime() ? startDate : tempDate;
            setPreviousDate(preDate);
            var p = new Date(preDate);
            p.setDate(p.getDate() - 30);
            let selectedDcValues = selectedDC.map((e) => e.value).toString();
            let selectedModeValues = selectedMode.value;

            try {
                const res = await apiService.get(API_INTERFACE_URI.GET_VDS_DATA + "size=" + size + "&fromDate=" + startDates + "&toDate=" + endDate + "&dc=" + selectedDcValues + "&mode=" + selectedModeValues);

                let vds_data = [];
                res.data.records.forEach((e) => {
                    if(e.nodeCode == 1005){
                        e.nodeName = 'Rebound';
                    }else{
                        let nodeName = dcArray.filter((a) => a.value == e.nodeCode)[0].label;
                        e.nodeName = nodeName;
                    }
                })
                if (res && res.data && res.data.records) {
                    setShowResults(true);
                    setVdsData(res.data.records);
                    setEndOfRecords(res.data.endOfRecords)
                    if (!res.data.endOfRecords) {
                        setNextDate(res.data.nextStartDate)
                    }
                }
                if (res && res.data && res.data.records.length == 0) {
                    setShowResults(false);
                    setVdsData([])
                    setDisplayMessage("No Records found for above search criteria.")
                }
            } catch (error) {
                setShowResults(false);
                setVdsData([])
                setDisplayMessage("Vds data could not be retrieved, Please try again later.")
            }

        }
        else {
            setShowSnack({ message: "Please select start date , end date , DC and Mode!", status: "error" })
            setShowResults(false);
        }
    }

    useEffect(() => {
        displayData(vdsData);
    }, [vdsData]);

    async function displayData(vdsDatas) {
        if (vdsDatas && vdsDatas.length > 0) {
            var t = [];
            vdsDatas.map(function (record) {
                if (record.vds && record.vds.length == 0 || (record.vdsColumnName && record.vds == null)) {
                    t.push({ ...record });
                } else if (record.vds && record.vds.length > 0) {
                    record.vds.map((item) => {
                        t.push({ ...record, ...item })
                    });
                }
            });
            var headerValues = [];
            vdsDatas.map(function (record) {
                if (!(headerValues.filter((a) => a.vdsColumnName == record.vdsColumnName).length > 0)) {
                    headerValues.push({ "vdsColumnName": record.vdsColumnName });
                }
            });
            headerValues.sort((a, b) => (a.vdsColumnName > b.vdsColumnName) ? 1 : ((b.vdsColumnName > a.vdsColumnName) ? -1 : 0))
            setHeader(headerValues);

            let array = [];
            t.forEach((ele, index) => {
                var vdsWithPercentage = t.filter((a) => a.nodeCode == ele.nodeCode && a.date == ele.date);
                var d = ele.date && ele.date.split('-');
                let tempDate = d && (d[1] + '/' + d[2] + '/' + d[0]);
                let obje = { checksum: 0, date: tempDate };
                vdsWithPercentage.forEach((b) => {
                    obje = {
                        ...b, ...obje,
                        [b.vdsColumnName]: b.vdsPercentage,
                        ["id|" + b.vdsColumnName]: b.vdsColumnName + "|" + b.shipVia + "|" + b.providerName + "|" + b.providerServiceId + "|" + b.nikeServiceLevelCode + "|" + b.id,
                        ["statuses|" + b.vdsColumnName]: b.statuses,
                        ["history|" + b.vdsColumnName]: b.history,
                        checksum: (obje.checksum + b.vdsPercentage)
                    }
                });

                var emptyVDS = t.filter((a) => a.nodeCode == ele.nodeCode && a.providerServiceId === ele.providerServiceId && ((a.vds && a.vds.length == 0) || (a.vds == null && a.vdsColumnName)));

                emptyVDS.forEach((e) => {
                    obje = {
                        ...obje,
                        [e.vdsColumnName]: 0,
                        ["id|" + e.vdsColumnName]: e.vdsColumnName + "|" + e.shipVia + "|" + e.providerName + "|" + e.providerServiceId + "|" + e.nikeServiceLevelCode + "|" + e.id,
                    }
                })

                var l = array.filter((c) => c.nodeCode == ele.nodeCode && c.date == obje.date);
                if (!(l && l.length > 0) && (obje.vds && obje.vds.length != 0)) {
                    array.push(obje);
                    obje = {};
                }
            });
            array.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
            setConsolidatedResult(array);
            if (array && array.length == 0) {
                setShowResults(false);
                setVdsData([])
                setDisplayMessage("No Records found for above search criteria.")
            }
            setShowSpinner(false);
        } else {
            setConsolidatedResult([])
        }

    }

    async function formatData(vdsRecords) {
        if (vdsRecords && vdsRecords.length > 0) {
            var t = [];
            vdsRecords.map(function (record) {
                if (record.vds && record.vds.length == 0 || (record.vdsColumnName && record.vds == null)) {
                    t.push({ ...record });
                } else if (record.vds && record.vds.length > 0) {
                    record.vds.map((item) => {
                        t.push({ ...record, ...item })
                    });
                }
            });
            var headerValues = [];
            vdsRecords.map(function (record) {
                if (!(headerValues.filter((a) => a.vdsColumnName == record.vdsColumnName).length > 0)) {
                    headerValues.push(record.vdsColumnName);
                }
            });
            headerValues.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))

            let array = [];
            t.forEach((ele, index) => {
                var vdsWithPercentage = t.filter((a) => a.nodeCode == ele.nodeCode && a.date == ele.date);
                var d = ele.date && ele.date.split('-');
                let tempDate = d && (d[1] + '/' + d[2] + '/' + d[0]);
                let obje = { date: tempDate, plntCd: ele.nodeCode, mode: ele.modeCode };
                headerValues.forEach((m) => {
                    obje = { ...obje, [m]: 0 }
                })
                vdsWithPercentage.forEach((b) => {
                    obje = { ...obje, [b.vdsColumnName]: b.vdsPercentage }
                });

                var emptyVDS = t.filter((a) => a.nodeCode == ele.nodeCode && a.providerServiceId === ele.providerServiceId && ((a.vds && a.vds.length == 0) || (a.vds == null && a.vdsColumnName)));

                emptyVDS.forEach((e) => {
                    obje = { ...obje, [e.vdsColumnName]: 0 }
                })

                var l = array.filter((c) => c.plntCd == ele.nodeCode && c.date == obje.date);
                if (!(l && l.length > 0) && (obje.date)) {
                    array.push(obje);
                    obje = {};
                }
            });
            array.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
            return array;
        } else {
            return [];
        }
    }

    async function downloadExcel() {
        let selectedDcValues = selectedDC.map((e) => e.value).toString();
        let selectedModeValues = selectedMode.value;
        let maxSize = 90;
        try {
            // change
            const res = await apiService.get(API_INTERFACE_URI.GET_VDS_DATA + "size=" + maxSize + "&fromDate=" + startDate + "&toDate=" + endDate + "&dc=" + selectedDcValues + "&mode=" + selectedModeValues);
            const sheet2 = await formatData(res.data.records);
            const ws2 = XLSX.utils.json_to_sheet(sheet2);
            const sheet1 = [
                { 'Column Format & Index': 'date', '': 'PlntCd', ' ': 'mode', '  ': '' },
                { 'Column Format & Index': '' },
                { 'Column Format & Index': 'TOP processes the data by the column Index and file with the exact column names is highly recommended' },
                { 'Column Format & Index': '' },
                { 'Column Format & Index': 'Row', '': 'Details', ' ': 'Sample', '  ': 'Comment' },
                { 'Column Format & Index': '' },
                { 'Column Format & Index': 'date', '': 'date', ' ': '07/02/2023', '  ': '' },
                { 'Column Format & Index': 'plntCd', '': 'node code', ' ': 1014, '  ': '' },
                { 'Column Format & Index': 'mode', '': 'mode code', ' ': 'ROAD', '  ': 'AIR/ROAD' },
                { 'Column Format & Index': 'vdsColumn', '': 'VDS column name', ' ': 0.1, '  ': 'Sample VDS column name : FedEx, UPS' }
            ]
            const ws1 = XLSX.utils.json_to_sheet(sheet1);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws1, 'Definition');
            XLSX.utils.book_append_sheet(wb, ws2, 'VDS');
            XLSX.writeFile(wb, 'VDS_data.xlsx');
        } catch (e) {
            console.log('error', e)
        }
    }

    const onDismissSnack = () => {
        setShowSnack(false);
        setShowModalSnack(false)
    }

    function onNext() {
        getVdsData(nextDate)
    }

    function onPrevious() {
        getVdsData(previousDate)
    }

    function onEdit(row) {
        setAddEditModal(true);
        setModalType("edit");
        let nodeName = '';
        if(row.nodeCode == 1005){
            nodeName = 'Rebound';
        }
        else {
            nodeName = dcArray.filter((ele) => ele.value === row.nodeCode)[0].label;
        }
        setEditDetails({
            ...row, nodeName: nodeName
        });
    }

    function closeAddEditModal(prop) {
        setAddEditModal(prop)
    }
    useEffect(() => {
        if (addEditModalValue) {
            vdsUpdated(addEditModalValue);
        }
    }, [addEditModalValue])
    function vdsUpdated(addEditModalValue) {
        setShowSnack(addEditModalValue);
        (startDate && endDate && selectedDC.length > 0 && selectedMode.value) && getVdsData(startDate);
    }

    const cancelUpload = () => {
        inputRef.current.value = "";
        setSelectedFile('');
        setVisible(!visible);
    }

    const handleFileInput = (file) => {
        setSelectedFile(file)
    }
    
    async function uploadFile(file){
        if (file && file.name) {
            let fileType = file.name.split('.')[1];
            if (file.name && (fileType == 'xlsx' || fileType == 'xls')) {
                await validateFile(file);
                setModalSpinner(true);
            } else {
                setShowModalSnack({ message: "Please select excel file !", status: "error" })
            }
        } else {
            setShowModalSnack({ message: "Please select excel file !", status: "error" })
        }
    }

    const validateFile = (file) => {
        const validDate = new RegExp('^(1[0-2]|0?[1-9])/(3[01]|[12][0-9]|0?[1-9])/(?:[0-9]{2})?[0-9]{2}$');
        const validNumber = new RegExp('^[0-9]*$');
        const validVdsValue = new RegExp(/^[0-1]*\.?\d{1,2}$/);
        const reader = new FileReader()
        reader.onload = function (evt) {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[1];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { raw: false })
            let headerColumns = ["date", "plntcd", "mode"];
            let invalidRecords = [];
            if (data && data.length > 0) {
                data.forEach((r, i) => {
                    // let checksum = 0.00;
                    Object.entries(r).forEach(([key, value], index) => {                        
                        if (key.toLowerCase() === "date") {
                            if (!validDate.test(value)) {
                                invalidRecords.push( "Invalid Date format at row " + (i+2));
                            }
                        }
                        if (key.toLowerCase() === "plntcd") {
                            if (!validNumber.test(value)) {
                                invalidRecords.push( "Invalid DC value for " + key + " at row " + (i+2));
                            }
                        }
                        if (!headerColumns.includes(key.toLowerCase().trim())) {                         
                            // checksum = parseInt(checksum + (value * 100));                           
                            if (parseFloat(value) > 1 || parseFloat(value) < 0 || !validVdsValue.test(value)) {
                                invalidRecords.push( "Invalid VDS value for " + key + " at row "+(i+2));
                            }
                        }                        
                    })
                    // if ((checksum/100) != 1) {
                    //     invalidRecords.push("Invalid checksum value at row "+(i+2));
                    // }
                })
            } else {                
                invalidRecords.push( "Empty Sheet!");
            }
            if(invalidRecords && invalidRecords.length > 0){
                setShowModalSnack({message :invalidRecords, status: "error"});
                setModalSpinner(false);
                inputRef.current.value = "";
                setSelectedFile(''); 
            }else{
                uploadFiletoS3(file);
            }
        };
        reader.readAsBinaryString(file);
    }

    const uploadFiletoS3 = async (file) => {        
        try {
            const res = await apiService.get(API_INTERFACE_URI.GET_PRESIGNED_URL + "fileName=volume-distribution/" + file.name);
            if (res.data.url) {
                const presignedS3Url = res.data.url;
                const axiosResponse = await axios.put(presignedS3Url, file);
                if (axiosResponse.status == 200) {
                    let fileDetails = { "fileName": file.name }
                    try {
                        const saveDataResponse = await apiService.post(API_INTERFACE_URI.SAVE_VDS_DATA, fileDetails);
                        if (saveDataResponse.data.failedRecords.length == 0) {
                            inputRef.current.value = "";
                            setSelectedFile('');
                            setVisible(!visible);
                            setModalSpinner(false);
                            setShowSnack({ message: "File Uploaded Successfully !", status: "success" });
                            (startDate && endDate && selectedDC.length > 0 && selectedMode.value) && getVdsData(startDate);
                        } else if (saveDataResponse.data.failedRecords.length > 0) {
                            inputRef.current.value = "";
                            setSelectedFile('');
                            setModalSpinner(false);
                            setShowModalSnack({ message: saveDataResponse.data.failedRecords, status: "error" });
                        }
                    } catch (e) {
                        console.log('error', e);
                        inputRef.current.value = "";
                        setSelectedFile('');
                        setModalSpinner(false);
                        setShowModalSnack({ message: "Something went wrong. Please contact your administrator.", status: "error" });
                    }
                }
            }
        } catch (e) {
            console.log('error', e);
            setModalSpinner(false);
            setShowModalSnack({ message: "Something went wrong. Please clear cache or contact your administrator.", status: "error" });
        }
    }

    const popoverBody = (statuses, history) => (
        <>
            <Icon name="Info" size="s" onClick={() => onInfo(statuses, history)} backgroundShape="circle" backgroundColor="var(--eds-color-grey-2)" />
        </>
    );

    const cellClick = (date, columnName, id) => {
        setPopoverShown({ "popoverShown": true, "date": date, "columnName": columnName, "id": id });
    }

    const onInfo = (statuses, history) => {
        setInfoModal({ showInfoModal: true, statuses: statuses, history: history });
    }


    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 main-container" style={{ minHeight: (height - 120) + 'px' }}>
            {snackMessage && snackMessage.message ? <SnackBar onDismissFunction={() => onDismissSnack()} params={{ "status": snackMessage.status, "message": snackMessage.message }} /> : null}
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-align-left">
                    <Text font={"title-6"} as={"h6"} className="vds-text"> VDS</Text>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl vdsBtn no-right-padding no-left-padding">
                    <Button variant="primary" onClick={() => getVdsData(startDate)} ><Icon name="Search" />  </Button>
                    &nbsp;&nbsp;
                    {showResults && <Button onClick={() => downloadExcel()} variant="primary" ><Icon name="Download" />  </Button>}
                    &nbsp;&nbsp;
                    {(groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) && <Button variant="primary" onClick={() => setVisible(!visible)} ><Icon name="Upload" />  </Button>}
                    <div className="text-align-left upload-vds-modal">
                        <Modal
                            isOpen={visible}
                            onDismiss={() => setVisible(!visible)}
                            headerSlot={"Upload File"}
                            footerSlot={
                                <ButtonGroup>
                                    <div className="upload-vds-btn">
                                        <Button
                                            disabled={modalSpinner}
                                            size="small"
                                            onClick={() => uploadFile(selectedFile)}
                                        >
                                            Upload
                                        </Button>
                                    </div>
                                    <div className="cancel-vds-btn">
                                        <Button
                                            disabled={modalSpinner}
                                            size="small"
                                            onClick={() => cancelUpload()}
                                        >
                                            Cancel
                                        </Button>

                                    </div>
                                </ButtonGroup>
                            }
                        >
                            {snackModalMessage && snackModalMessage.message ?
                                <SnackBar onDismissFunction={() => onDismissSnack()}
                                    params={{ "status": snackModalMessage.status, "message": snackModalMessage.message }}
                                />
                                : null}
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                                className="upload-vds-modal-content">
                                <TextField
                                    id="file"
                                    type="file"
                                    subtitle="Select File"
                                    ref={inputRef}
                                    accept=".xlsx, .xls"
                                    onChange={(e) => handleFileInput(e.target.files[0])}
                                />

                                {modalSpinner &&
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 fills-modal-spinner ">
                                        <Spinner size="large" />
                                    </div>
                                }


                            </div>
                        </Modal>
                    </div>
                </div>
                {addEditModal ? <AddEditVDS dcArray={dcArray} modeArray={modeArray} editDetails={editDetails} modalType={'save'} showModal={addEditModal} vdsUpdated={(val) => setAddEditValueModal(val)} closeAddEditModal={(prop) => closeAddEditModal(prop)} /> : null}
                <form className=" row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 no-right-padding">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 text-align-left padding-right-point-3-rem vds-input-box">
                        <TextField
                            id="start-date"
                            onChange={e => onStartDate(e)}
                            value={startDate}
                            type="date"
                            subtitle="START DATE"
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 text-align-left padding-right-point-3-rem vds-input-box">
                        <TextField
                            id="end-date"
                            onChange={e => setEndDate(e.target.value)}
                            value={endDate}
                            type="date"
                            subtitle="END DATE"
                            max={dateRange}
                            min={startDate}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 text-align-left no-right-padding vds-input-box">
                        <Select
                            name={"DCValue"}
                            isMulti
                            options={dcArray}
                            subtitle="DC"
                            hasErrors={false}
                            errorMessage="This is an error"
                            onChange={(newValue, object) => selectDCValues(newValue, object)}
                            value={selectedDC}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 text-align-left no-right-padding  vds-input-box">
                        <Select
                            name={"MODE"}
                            options={modeArray}
                            subtitle="MODE"
                            hasErrors={false}
                            errorMessage="This is an error"
                            onChange={(newValue, object) => selectModeValues(newValue, object)}
                            value={selectedMode}
                        />
                    </div>
                </form>

                {showResults ?
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-1rem no-right-padding">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 vds-box no-right-padding" style={{ maxHeight: (height) + 'px' }}>
                            {
                                !showSpinner && <div className="vds-table">
                                    <Table id="vds-table">
                                        <thead>
                                            <tr>
                                                <TableHeading className="vds-header-cell">Action</TableHeading>
                                                <TableHeading className="vds-header-cell">Date</TableHeading>
                                                <TableHeading className="vds-header-cell">Plntcd</TableHeading>
                                                <TableHeading className="vds-header-cell">Mode</TableHeading>
                                                <TableHeading className="vds-header-cell">Checksum</TableHeading>

                                                {
                                                    header.map((column, index) => {
                                                        return (
                                                            <TableHeading key={index} className="vds-header-cell">{column.vdsColumnName}</TableHeading>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                consolidatedResult && consolidatedResult.map((row, index1) => (

                                                    <tr key={index1}>
                                                        <TableCell onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row) : {}} className="vds-cell">
                                                            <Icon size="s" name="Edit" />
                                                        </TableCell>
                                                        <TableCell className="vds-cell">{row.date}</TableCell>
                                                        <TableCell className="vds-cell">{row.nodeName}</TableCell>
                                                        <TableCell className="vds-cell">{row.modeCode}</TableCell>
                                                        <TableCell className="vds-cell">{parseFloat(row.checksum).toFixed(2)}</TableCell>

                                                        {header.map((column, index2) => {
                                                            let vdsValue = row && row[column.vdsColumnName] ? row[column.vdsColumnName] : 0;
                                                            let vdsId = row && row.vds?.length > 0 && row["vds"][0]?.id;

                                                            return (
                                                                <TableCell key={index1 + "" + index2} className="vds-cell">
                                                                    {
                                                                        (row["statuses|" + column.vdsColumnName] || row["history|" + column.vdsColumnName] ?
                                                                            <Popover className="cellPopover"
                                                                                onClickOutside={() => setPopoverShown({ "popoverShown": false })}
                                                                                isOpen={popover.popoverShown && popover.date == row.date && popover.columnName == column.vdsColumnName && popover.id == vdsId}
                                                                                bodySlot={popoverBody(row["statuses|" + column.vdsColumnName], row["history|" + column.vdsColumnName])}
                                                                                placement={"right"}
                                                                            >
                                                                                <span onClick={() => cellClick(row.date, column.vdsColumnName, vdsId)} > {vdsValue}</span>
                                                                            </Popover>
                                                                            : <span className="disabledCell"> {vdsValue}</span>
                                                                        )
                                                                    }
                                                                </TableCell>
                                                            )
                                                        })}

                                                    </tr>
                                                )
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            }
                            {showSpinner && <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 vds-table-spinner"> <Spinner size="large" /></div>}
                            {infoModal.showInfoModal ? <InfoModal infoModal={infoModal} /> : null}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 row padding-top-bottom-1rem">
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 paginationBtn">
                                <Button disabled={!previousButton} onClick={() => onPrevious()}>
                                    Previous
                                </Button>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 page-no">
                                {/* Page: 1/10 */}
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 paginationBtn">
                                <Button disabled={endOfRecords} onClick={() => onNext()}>
                                    &nbsp;&nbsp;Next&nbsp;&nbsp;
                                </Button>
                            </div>
                        </div>

                    </div>
                    :
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-1rem no-right-padding">
                        <Text font={"title-6"} as={"h6"}> {displayMessage} </Text> </div>}
            </div>
        </div>
    )
}



export default Vds;
