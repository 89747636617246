import React from 'react'

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export default function({ onClick, href, children, ...props }) {
  return (
    <a
      {...props}
      href={href}
      onClick={(event) => {
        let { target } = props
        onClick()
        if (
          event.button === 0 && // ignore everything but left clicks
          (!target || target === '_self') && // let browser handle "target=_blank" etc.
          !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
          event.preventDefault()
          window.history.pushState({}, '', href)
        }
      }}
    >
      {children}
    </a>
  )
}
