import React from 'react'
import PropTypes from 'prop-types'

import styles from './Spinner.styl'

const Spinner = ({ medium, large, color, className }) => {
  const mediumSpinner = medium ? styles.spinnerMD : ''
  const largeSpinner = large ? styles.spinnerLG : ''
  const colorStyle = color ? { borderTop: `2px solid ${color}` } : ''
  return (
    <div
      className={`${styles.spinner} ${mediumSpinner} ${largeSpinner} ${className}`}
      style={colorStyle}
    />
  )
}

Spinner.propTypes = {
  /** Specifies the spinner to be max size */
  large: PropTypes.bool,
  /** Specifies the spinner to be med size */
  medium: PropTypes.bool,
  /** custom className for the Spinner */
  className: PropTypes.string,
  /** custom spinner color, default is blue */
  color: PropTypes.string,
}

Spinner.defaultProps = {
  className: '',
  large: false,
  medium: false,
  color: '#03ade0',
}

export default Spinner
