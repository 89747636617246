import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Button, Icon } from '@EPIC'
import styles from './styles.styl'

function IconButton({
  positionFirst,
  type,
  bare = false,
  children,
  iconFontSize,
  iconClassName,
  buttonClassName,
  iconSize,
  ...props
}) {
  const size = iconFontSize || (props.large ? '30px' : '')
  let buttonClass = `${styles.button} ${bare ? styles.bare : ''}`
  if (positionFirst) buttonClass += ` ${styles.positionFirst}`
  let icon = <Icon className={iconClassName} type={type} fontSize={size} size={iconSize} />
  let content = <div className={styles.content}>{children}</div>

  return positionFirst ? (
    <Button {...props} className={classnames(buttonClass, buttonClassName)}>
      {icon}
      {content}
    </Button>
  ) : (
    <Button {...props} className={classnames(buttonClass, buttonClassName)}>
      {content}
      {icon}
    </Button>
  )
}

IconButton.propTypes = {
  /** Icon type */
  type: PropTypes.string.isRequired,
  /** Allow to custom webfont size (px values) */
  iconFontSize: PropTypes.string,
  /** Set to true if the icon should appear before the text */
  positionFirst: PropTypes.bool,
  /** Remove the background */
  bare: PropTypes.bool,
  /** Allow you to pass in className to the icon part*/
  iconClassName: PropTypes.string,
  /** Allow you to pass in className to the icon part*/
  iconSize: PropTypes.string,
  /** Allow you to pass in className to the button part */
  buttonClassName: PropTypes.string,
}

IconButton.defaultProps = {
  positionFirst: false,
  iconFontSize: '14px',
  iconSize: 'm',
  bare: false,
}

export default IconButton
