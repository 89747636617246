exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___Hr_Iz,\n.badge___3reBr::after {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___3fSuu {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___2hpNK {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___2RUsJ {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.ripple___1XAJw {\n  overflow: hidden;\n  transform: translateZ(0);\n}\n.ripple___1XAJw:active:after {\n  transform: scale(0);\n  opacity: 0.5;\n  transition: 0s;\n}\n.ripple___1XAJw:after {\n  content: '';\n  display: block;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  pointer-events: none;\n  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);\n  background-repeat: no-repeat;\n  background-position: 50%;\n  transform: scale(10);\n  opacity: 0;\n  transition: transform 0.5s, opacity 1s;\n}\n.badge___3reBr {\n  position: relative;\n  display: block;\n  white-space: nowrap;\n}\n.badge___3reBr::after {\n  position: absolute;\n  line-height: 1.95em;\n  box-sizing: border-box;\n  -webkit-box-sizing: border-box;\n  content: attr(badge-data);\n  top: -0.2em;\n  right: -2em;\n  background-color: #fa5400;\n  color: #fff;\n  padding: 0em;\n  border-radius: 50%;\n  font-size: 0.8em;\n  text-align: center;\n  height: 1.9em;\n  width: 1.9em;\n}\n.badge___3reBr[badge-data=\"0\"]::after {\n  display: none;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___Hr_Iz",
	"badge": "badge___3reBr",
	"epic-font-base-md": "epic-font-base-md___3fSuu",
	"epic-font-marketing": "epic-font-marketing___2hpNK",
	"epic-font-brand": "epic-font-brand___2RUsJ",
	"ripple": "ripple___1XAJw"
};