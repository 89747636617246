import "./App.css";
import "@nike/eds/dist/index.css";
import React, { useEffect, useState } from "react";
import { AegisClient, useAuth } from "@nike/aegis-auth-react";
import { Button, PageSpinner } from "@nike/epic-react-ui";
import config from "./config/config.js";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./components/HomePage/HomePage";
import CarrierAllocation from "./components/CarrierAllocation/CarrierAllocation";
import Header from "./components/Header/Header";
import { setGlobalHeader } from "./utilities/http";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Fills from "./components/Fills/Fills";
import ShipVia from "./components/ShipVia/ShipVia";
import Vds from "./components/Vds/Vds";
import ChangeLog from "./components/ChangeLog/ChangeLog";
import CarrierTransitTimes from "./components/CarrierTransitTimes/CarrierTransitTimes";

const client = new AegisClient({
  qa: !config.isProd,
  ...config.oAuth,
});

function App() {
  const { loginComplete, isLoggedIn, clearLogin } = useAuth(client);
  const [userToken, setUserToken] = useState();
  const [loggedInUser, setLoggedInUser] = useState();
  const [groups, setGroups] = useState();

  useEffect(() => {
    function handleLogin({ accessToken }) {
      setGlobalHeader("Authorization", `Bearer ${accessToken.accessToken}`);
      setUserToken(accessToken.accessToken);
      setGroups([...accessToken.claims.groups]);
      setLoggedInUser(accessToken.claims.sub);
    }

    client.on("login:success", handleLogin);
    client.on("renew:access:success", handleLogin);
  }, []);

  // if (!loginComplete || !userToken) return <PageSpinner show={true} />;
  if (!isLoggedIn) return <LoggedOut />;

  function handleLogout(params) {
    if (params) {
      setGlobalHeader("Authorization", null);
      clearLogin();
      client.logout();
      setUserToken();
      setGroups([]);
      setLoggedInUser();
    }
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        {loginComplete && userToken && (
          <div className="App" style={{ backgroundImage: "url(./circle.jpg)" }}>
            <Header loggedInUser={loggedInUser} handleLogout={handleLogout} />
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route
                exact
                path="/carrier-allocation"
                element={<CarrierAllocation groups={groups} />}
              />
              <Route
                exact
                path="/priority-and-secondary-fills"
                element={<Fills groups={groups} />}
              />
              <Route
                exact
                path="/shipvia-configurations"
                element={<ShipVia groups={groups} />}
              />
              <Route exact path="/vds" element={<Vds groups={groups} />} />
              <Route
                exact
                path="/change-log"
                element={
                  <ChangeLog groups={groups} loggedInUser={loggedInUser} />
                }
              />
              {/* <Route
                exact
                path="/carrier-transit-times"
                element={
                  <CarrierTransitTimes
                    groups={groups}
                    loggedInUser={loggedInUser}
                  />
                }
              /> */}
            </Routes>
          </div>
        )}
        <div>
          <Routes>
            <Route path="/logout" element={<LoggedOut></LoggedOut>}></Route>
          </Routes>
        </div>
      </BrowserRouter>
    </React.Fragment>
  );
}

function LoggedOut() {
  return (
    <div style={{ margin: "50px", textAlign: "center" }}>
      <div style={{ marginBottom: "20px" }}>
        <span className="epic-body-text-1 table-label">
          You have been logged out from application.
        </span>
        <p>
          <span className="epic-body-text-1 table-label">
            Close the browser to log out from Okta session.
          </span>
        </p>
      </div>
      <Button onClick={(event) => (window.location.href = "/")}>Login</Button>
    </div>
  );
}

export default App;
