import React, { useEffect, useState, useRef } from "react";
import { Text, TextField, Icon, Select, Button, Table, TableCell, TableHeading, ButtonGroup, Modal, Snack, Snackbar, Popover, Spinner } from "@nike/eds";
import axios from 'axios';
import apiService from '../../services/service';
import API_INTERFACE_URI from "../../constants/uri.constants";
import './ChangeLog.css';
import SnackBar from "../SnackBar/SnackBar.js";

function ChangeLog({ groups, loggedInUser }) {
    let height = window.innerHeight;
    const [snackMessage, setShowSnack] = useState({ message: "", status: "" });
    const [selectedWorkflow, setSelectedWorkflow] = useState([]);
    const [selectedDC, setSelectedDC] = useState([]);
    const [dcArray, setDCarray] = useState([]);
    const [workflowArray, setWorkflowArray] = useState([
        {value: 'CAPACITIES', label: 'CarrierAllocation'},
        {value: 'FILLS', label: 'CapacityFills'},
        {value: 'SHIPVIA', label: 'ShipVia'},
        {value: 'VDS', label: 'VDS'}
    ]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [dateRange, setDateRange] = useState();
    const [displayMessage, setDisplayMessage] = useState('')
    const [showSpinner, setShowSpinner] = useState(true);

    async function getMasterData() {
        try {
            const res = await apiService.get(API_INTERFACE_URI.GET_MASTER_DATA);
            if (res && res.data && res.data.objects) {
                let dcArray = [
                    ...new Map(res.data.objects.map((item) => [item["nodeName"], item])).values()
                ];
                setDCarray([{"value": "All", "label": "All"}, ...dcArray.map((data) => ({ "value": data.PlntCd, "label": data.nodeName })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])                
            }
        } catch (e) {
            setDisplayMessage("Change Log page can not generate email at the moment, Please try again later.");
        }
    }

    useEffect(() => {
        getMasterData();
    }, [])

    function selectWorkflowValues(data, item) {
        setSelectedWorkflow(data)
    }

    function selectDCValues(data, item) {
        setSelectedDC([...data])

        let array = dcArray.filter((e)=> e.value != 'All');
        if(data && data.length > 0){  
            let checkdata = data.filter((e)=> e.value == 'All');   
            if(checkdata && checkdata.length > 0){        
                setSelectedDC([...array]);
                setDCarray([...array]);
            }                   
        }
        if(dcArray && dcArray.length > 0){
                let checkmode = dcArray.filter((e)=> e.value == 'All');
                if(checkmode && checkmode.length == 0){
                    setDCarray([{"value": "All", "label": "All"}, ...array]);
                }
        }
    }
    
    function onStartDate(e) {
        setStartDate(e.target.value)
        var d = new Date(e.target.value);
        d.setDate(d.getDate() + 89);
        var dateRange = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
        setDateRange(dateRange);
        setEndDate('');
    }
    
    function onDismissSnack(prop) {
        setShowSnack(false);
    }
    
    async function generateReport(){
        if(startDate && endDate && selectedDC.length > 0 && selectedWorkflow.value){
            const payload = {
                "startDate": startDate,
                "endDate": endDate,
                "dc": selectedDC.map((e)=>{return {dcCode: e.value}}),            
                "workflowList": [
                    {
                    "functionalInterfaceName": selectedWorkflow.value
                    }
                ],
                "userEmail":loggedInUser
            };
            try{
                const res = await apiService.post(API_INTERFACE_URI.GENERATE_REPORT, payload);
                if(res.status == 200 && res.data.successRecords && res.data.successRecords.length > 0){
                    setShowSnack({ message: res.data.successRecords[0], status: "success" });
                }
            } catch (e){
                console.log('error', e);
                setShowSnack({ message: "Something went wrong. Please contact your administrator.", status: "error" });
            }
        }else{
            setShowSnack({ message: "Please select Start Date, End Date, DC and Workflow.", status: "error" });
        }
    }
    
    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 main-container" style={{ minHeight: (height - 120) + 'px' }}>
            {snackMessage && snackMessage.message ?
                <SnackBar  onDismissFunction={() => onDismissSnack()}
                    params={{ "status": snackMessage.status, "message": snackMessage.message }}
                />
                : null}
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-align-left">
                    <Text font={"title-6"} as={"h6"} className="changeLog-text"> Change Log </Text>
                </div>
                
                <form className="row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 no-right-padding">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 text-align-left padding-right-point-3-rem changeLog-input-box">
                        <TextField
                            id="start-date"
                            onChange={e => onStartDate(e)}
                            value={startDate}
                            type="date"
                            subtitle="START DATE"
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 text-align-left padding-right-point-3-rem changeLog-input-box">
                        <TextField
                            id="end-date"
                            onChange={e => setEndDate(e.target.value)}
                            value={endDate}
                            type="date"
                            subtitle="END DATE"
                            max={dateRange}
                            min={startDate}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 no-right-padding">
                        <div className="text-align-left changeLog-input-box">
                            <Select
                                name={"DCValue"}
                                isMulti
                                options={dcArray}
                                subtitle="DC"
                                hasErrors={false}
                                errorMessage="This is an error"
                                onChange={(newValue, object) => selectDCValues(newValue, object)}
                                value={selectedDC}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 no-right-padding">
                        <div className="text-align-left changeLog-input-box">
                            <Select
                                name={"WorkflowValue"}
                                options={workflowArray}
                                subtitle="WORKFLOW"
                                hasErrors={false}
                                errorMessage="This is an error"
                                onChange={(newValue, object) => selectWorkflowValues(newValue, object)}
                                value={selectedWorkflow}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 changeLogBtn no-right-padding  no-left-padding">
                        <Button onClick={() => generateReport()} variant="primary" title="Generate">Generate</Button>
                    </div>
                </form>                
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-1rem no-right-padding">
                    <Text font={"title-6"} as={"h6"} className="">{displayMessage}</Text>
                </div>
            </div>
        </div>
    )
}
export default ChangeLog;