import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { IconButton } from '@EPIC'
import styles from './styles.styl'

function ListItem({ iconType, onAction, thumbnail, content, subtitle, name, className, noBorder }) {
  let containerClass = classnames(styles.listItem, className)
  if (noBorder) {
    containerClass = classnames(containerClass, styles.noBorder)
  }

  return (
    <div className={containerClass}>
      <div className={styles.inner}>
        <div className={styles.title}>
          <div className={styles.thumbnail}>{thumbnail && thumbnail}</div>
          <div className={styles.info}>
            <div className={styles.name}>{name}</div>
            <div className={styles.subtitle}>{subtitle}</div>
          </div>
        </div>
        <div className={styles.content}>{content}</div>
        {iconType && (
          <div className={styles.action}>
            <IconButton bare type={iconType} iconSize='m' onClick={onAction} />
          </div>
        )}
      </div>
    </div>
  )
}

ListItem.propTypes = {
  /** Type of icon for action button (right side) */
  iconType: PropTypes.string,
  /** Function fired onClick of action button (right side) */
  onAction: PropTypes.func,
  /** Center content of list item */
  content: PropTypes.oneOf([PropTypes.string, PropTypes.node, PropTypes.func]),
  /** Dom node that render desired content in "thumbnail" section of list item (left side) */
  thumbnail: PropTypes.node,
  /** Name (or title) string that renders as primary text in left side of list item */
  name: PropTypes.string,
  /** Subtitle string that renders as subtext in left side of list item */
  subtitle: PropTypes.string,
  /** Class for container of component */
  className: PropTypes.string,
}

ListItem.defaultProps = {
  className: '',
  name: '',
  date: '',
  onAction: () => undefined,
}

export default ListItem
