import React, { useEffect, useState } from "react";
import { NikeApp } from "@nike/nike-design-system-icons";
import {
  AppHeader,
  Avatar,
  Icon,
  Text,
  Menu,
  MenuItem,
  IconButton,
  Button,
  Tooltip,
} from "@nike/eds";
import { useNavigate } from "react-router-dom";

function Header({ loggedInUser, handleLogout }) {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  useEffect(() => {
    getUserDetails(loggedInUser);
  }, []);

  let getUserDetails = (loggedInUser) => {
    const splitEmail = loggedInUser.split(".");
    const initials = (
      splitEmail[0].charAt(0) + splitEmail[1].charAt(0)
    ).toUpperCase();
    let userDetails = {
      firstName: splitEmail[0],
      lastName: splitEmail[1].split("@")[0],
      initials: initials,
      email: loggedInUser,
    };
    setUserDetails(userDetails);
  };

  const menuBody = (
    <>
      <MenuItem
        onClick={() => {
          navigate("/");
        }}
      >
        Home
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/");
        }}
      >
        VDS
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/carrier-allocation");
        }}
      >
        Carrier Allocation
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/");
        }}
      >
        Priority & Secondary Fills
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/");
        }}
      >
        ShipVia Configurations
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/");
        }}
      >
        Change Log
      </MenuItem>
      {/* <MenuItem
        onClick={() => {
          navigate("/");
        }}
      >
        Carrier Transit Times
      </MenuItem> */}
    </>
  );

  const DropdownMenuPlaceholder = ({ isDark: boolean }) => (
    <>
      <Menu isOpen={open} placement={"bottom-start"}>
        <Text
          style={{
            color: "#dedede",
            cursor: "pointer",
            padding: "12px 2px 12px 2px",
          }}
          onClick={() => {
            navigate("/");
          }}
          font={"subtitle-2"}
          as="span"
          className={window.location.pathname == "/" ? "activeHeaderClass" : ""}
        >
          Home
        </Text>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Text
          style={{
            color: "#dedede",
            cursor: "pointer",
            padding: "12px 2px 12px 2px",
          }}
          onClick={() => {
            navigate("/carrier-allocation");
          }}
          font={"subtitle-2"}
          as="span"
          className={
            window.location.pathname == "/carrier-allocation"
              ? "activeHeaderClass"
              : ""
          }
        >
          Carrier Allocation
        </Text>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Text
          style={{
            color: "#dedede",
            cursor: "pointer",
            padding: "12px 2px 12px 2px",
          }}
          onClick={() => {
            navigate("/priority-and-secondary-fills");
          }}
          font={"subtitle-2"}
          as="span"
          className={
            window.location.pathname == "/priority-and-secondary-fills"
              ? "activeHeaderClass"
              : ""
          }
        >
          Priority & Secondary Fills
        </Text>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Text
          style={{
            color: "#dedede",
            cursor: "pointer",
            padding: "2px 2px 12px 2px",
          }}
          onClick={() => {
            navigate("/shipvia-configurations");
          }}
          font={"subtitle-2"}
          as="span"
          className={
            window.location.pathname == "/shipvia-configurations"
              ? "activeHeaderClass"
              : ""
          }
        >
          ShipVia Configurations
        </Text>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Text
          style={{
            color: "#dedede",
            cursor: "pointer",
            padding: "2px 2px 12px 2px",
          }}
          onClick={() => {
            navigate("/vds");
          }}
          font={"subtitle-2"}
          as="span"
          className={
            window.location.pathname == "/vds" ? "activeHeaderClass" : ""
          }
        >
          VDS
        </Text>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Text
          style={{
            color: "#dedede",
            cursor: "pointer",
            padding: "2px 2px 12px 2px",
          }}
          onClick={() => {
            navigate("/change-log");
          }}
          font={"subtitle-2"}
          as="span"
          className={
            window.location.pathname == "/change-log" ? "activeHeaderClass" : ""
          }
        >
          Change Log
        </Text>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* <Text
          style={{
            color: "#dedede",
            cursor: "pointer",
            padding: "2px 2px 12px 2px",
          }}
          onClick={() => {
            navigate("/carrier-transit-times");
          }}
          font={"subtitle-2"}
          as="span"
          className={
            window.location.pathname == "/carrier-transit-times"
              ? "activeHeaderClass"
              : ""
          }
        >
          Carrier Transit Times
        </Text> */}
      </Menu>
    </>
  );
  const userMenuHeader = (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Avatar
        initials={
          userDetails && userDetails.initials ? userDetails.initials : ""
        }
        size={48}
        url=""
      />
      <div style={{ display: "inline-flex", flexDirection: "column" }}>
        <Text font="title-5">
          {userDetails && userDetails.firstName && userDetails.lastName
            ? userDetails.firstName + " " + userDetails.lastName
            : ""}
        </Text>
        <Text font="body-3">
          {userDetails && userDetails.email ? userDetails.email : ""}
        </Text>
      </div>
    </div>
  );
  function logout() {
    handleLogout(true);
    navigate("/logout", {
      state: {},
    });
  }
  const userMenuBody = (
    <>
      <MenuItem onClick={() => logout()}>Logout</MenuItem>
    </>
  );

  const UserMenuPlaceholder = ({ isDark: boolean }) => (
    <>
      <Menu
        onClickOutside={() => {
          setUserMenuOpen(!userMenuOpen);
        }}
        isOpen={userMenuOpen}
        bodySlot={userMenuBody}
        headerSlot={userMenuHeader}
        placement={"bottom-start"}
      >
        <Avatar
          size={48}
          initials={
            userDetails && userDetails.initials ? userDetails.initials : ""
          }
          onClick={() => setUserMenuOpen(!userMenuOpen)}
        />
      </Menu>
    </>
  );

  return (
    <>
      <AppHeader
        isDark
        appName="Transportation Ops Portal"
        logoSlot={<NikeApp color={"white"} width={"56px"} height={"64px"} />}
        commandBarSlot={<DropdownMenuPlaceholder isDark />}
        avatarSlot={<UserMenuPlaceholder />}
        size={48}
      />
    </>
  );
}
export default Header;
