import React from 'react'
import classnames from 'classnames'
import ReactSelect from 'react-select'
import { default as ReactSelectCreatable } from 'react-select/creatable'
import PropTypes from 'prop-types'
import { FormBase } from '../FormBase/FormBase.js'
import { Icon } from '@EPIC'

import styles from './style.styl'

const DropdownIndicator = () => <Icon type='CaretDown' />

const Select = ({ isCreatable, className, errorMessage, required, ...props }) => {
  const Component = isCreatable ? ReactSelectCreatable : ReactSelect
  let selectClass = classnames(styles.NextGenSelect, className)

  return (
    <FormBase
      helperText={props.helperText}
      hasErrors={props.hasErrors}
      errorMessage={errorMessage}
      required={required}
      static
      className={styles.Select}
      disabled={props.disabled}
    >
      <Component
        className={selectClass}
        classNamePrefix={'NextGenSelect'}
        isDisabled={props.disabled}
        components={{ DropdownIndicator }}
        {...props}
      />
    </FormBase>
  )
}

Select.propTypes = {
  /** Placeholder text displayed when no option is selected */
  placeholder: PropTypes.string,
  /** The options the user can select */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  /** Class Name escape hatch */
  className: PropTypes.string,
  /** Currently selected option */
  value: PropTypes.any,
  /** Function triggered on change events */
  onChange: PropTypes.func.isRequired,
  /** Focus this field on load */
  autoFocus: PropTypes.bool,
  /** Disable this field */
  disabled: PropTypes.bool,
  /** Sets input as required and also adds red star to label */
  required: PropTypes.bool,
  /** Make clearable this field */
  isClearable: PropTypes.bool,
  /** Allows the user to add options */
  isCreatable: PropTypes.bool,
  /** Select is currently open */
  menuIsOpen: PropTypes.bool,
  /** Allows the user to select multiple values */
  isMulti: PropTypes.bool,
  /** Current filter input value */
  inputValue: PropTypes.string,
  /** Function triggered on filter input change */
  onInputChange: PropTypes.func,
  /** Function triggered on selection of user-provided option (requires *isCreatable*) */
  onCreateOption: PropTypes.func,
  /** Function triggered on select open */
  onMenuOpen: PropTypes.func,
  /** Function triggered on select closed */
  onMenuClose: PropTypes.func,
  /** Text to help user */
  helperText: PropTypes.string,
  /** Sets whether input is in error state */
  hasErrors: PropTypes.bool,
  /** Error message to be displayed to user. If errorMessage is supplied, helperText will not be displayed */
  errorMessage: PropTypes.string,
}

Select.defaultProps = {
  options: [],
  autoFocus: false,
  disabled: false,
  isClearable: false,
  isCreatable: false,
  placeholder: 'Select...',
}

export default Select
