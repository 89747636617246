import React from 'react'
import PropTypes from 'prop-types'
import { Tabs as ReactTabs, TabList as ReactTabList, Tab as ReactTab, TabPanel } from 'react-tabs'

import styles from './styles.styl'

const Tabs = ({
  tabs,
  className,
  tabClassName,
  tabListClassName,
  selectedTabClassName,
  selectedTabPanelClassName,
  defaultFocus,
  defaultIndex,
  onSelect,
  forceRenderTabPanel,
  selectedIndex,
  children,
}) => {
  return (
    <ReactTabs
      className={className || ''}
      selectedTabClassName={`${styles.active} ${selectedTabClassName || ''}`}
      selectedTabPanelClassName={selectedTabPanelClassName}
      defaultFocus={defaultFocus}
      defaultIndex={defaultIndex}
      forceRenderTabPanel={forceRenderTabPanel}
      onSelect={onSelect}
      selectedIndex={selectedIndex}
    >
      <ReactTabList className={`${styles.tabList} ${tabListClassName || ''}`}>
        {tabs.map((title, i) => {
          return (
            <ReactTab key={`title-${i}`} className={`${styles.tab} ${tabClassName || ''}`}>
              {title}
            </ReactTab>
          )
        })}
      </ReactTabList>
      {children}
    </ReactTabs>
  )
}

Tabs.propTypes = {
  /** An array of strings to populate each tab */
  tabs: PropTypes.array.isRequired,
  /** Style the outermost element */
  className: PropTypes.string,
  /** Override for styling the TabList */
  tabListClassName: PropTypes.string,
  /** Override for styling the inner Tab component*/
  tabClassName: PropTypes.string,
  /** Override for styling the active Tab component */
  selectedTabClassName: PropTypes.string,
  /** Style the active TabPanel component */
  selectedTabPanelClassName: PropTypes.string,
  /** Focus tabs on initial render */
  defaultFocus: PropTypes.bool,
  /** Set which tab is active on initial render */
  defaultIndex: PropTypes.number,
  /** Determines whether or not all TabPanels are rendered to the DOM */
  forceRenderTabPanel: PropTypes.bool,
  /** Event handler for when a Tab is about to change */
  onSelect: PropTypes.bool,
  /** Specify the selected tab index */
  selectedIndex: PropTypes.number,
}

Tabs.defaultProps = {
  defaultFocus: false,
  defaultIndex: 0,
  forceRenderTabPanel: false,
}

Tabs.TabPanel = TabPanel

export default Tabs
