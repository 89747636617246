exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___10rH5 {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___2G1HP {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___307-A {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___1n4wE {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.ripple___3nN-e,\n.epFavorites___3xkr0 {\n  overflow: hidden;\n  transform: translateZ(0);\n}\n.ripple___3nN-e:active:after,\n.epFavorites___3xkr0:active:after {\n  transform: scale(0);\n  opacity: 0.5;\n  transition: 0s;\n}\n.ripple___3nN-e:after,\n.epFavorites___3xkr0:after {\n  content: '';\n  display: block;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  pointer-events: none;\n  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);\n  background-repeat: no-repeat;\n  background-position: 50%;\n  transform: scale(10);\n  opacity: 0;\n  transition: transform 0.5s, opacity 1s;\n}\n.epFavorites___3xkr0 {\n  display: inline-block;\n}\n.epFavorites___3xkr0 .favoritesIcon___3lvzX {\n  padding: 6px;\n  border-radius: 50%;\n  cursor: pointer;\n  outline: none;\n}\n.epFavorites___3xkr0 .favoritesIcon___3lvzX:hover {\n  background: #e5e5e5;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___10rH5",
	"epic-font-base-md": "epic-font-base-md___2G1HP",
	"epic-font-marketing": "epic-font-marketing___307-A",
	"epic-font-brand": "epic-font-brand___1n4wE",
	"ripple": "ripple___3nN-e",
	"epFavorites": "epFavorites___3xkr0",
	"favoritesIcon": "favoritesIcon___3lvzX"
};