import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Label } from '@EPIC'

import styles from './TextArea.styl'

const TextArea = React.forwardRef((props, ref) => {
  const { className, border, required, hasErrors, errorMessage, label, ...others } = props
  let textAreaClass = styles.textarea

  if (!border) {
    textAreaClass = classnames(textAreaClass, styles.noBorder)
  }

  if (hasErrors) {
    textAreaClass = classnames(textAreaClass, styles.hasErrors)
  }

  return (
    <Label
      label={label}
      className={className}
      required={required}
      hasErrors={hasErrors}
      errorMessage={errorMessage}
    >
      <textarea ref={ref} required={required} className={textAreaClass} {...others} />
    </Label>
  )
})

TextArea.propTypes = {
  /** Sets label for element */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /** Specifies if border is present */
  border: PropTypes.bool,
  /** Handles textarea change event */
  onChange: PropTypes.func,
  /** Sets className on container of textarea element */
  className: PropTypes.string,
  /** Sets placeholder of textarea element */
  placeholder: PropTypes.string,
  /** Sets textarea as required and also adds red star to label */
  required: PropTypes.bool,
  /** Sets whether input is in error state */
  hasErrors: PropTypes.bool,
  /** Error message to be displayed to user, appears in top right of textarea */
  errorMessage: PropTypes.string,
}

TextArea.defaultProps = {
  border: true,
  className: '',
}

export default TextArea
