exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___2i59X {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___3VdPZ {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___3pQg6 {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___g9M8y {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.ripple___1aW4I {\n  overflow: hidden;\n  transform: translateZ(0);\n}\n.ripple___1aW4I:active:after {\n  transform: scale(0);\n  opacity: 0.5;\n  transition: 0s;\n}\n.ripple___1aW4I:after {\n  content: '';\n  display: block;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  pointer-events: none;\n  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);\n  background-repeat: no-repeat;\n  background-position: 50%;\n  transform: scale(10);\n  opacity: 0;\n  transition: transform 0.5s, opacity 1s;\n}\n.tabList___3EuQP {\n  display: flex;\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n  margin-bottom: 20px;\n  justify-content: center;\n}\n.tab___aUqgO {\n  cursor: pointer;\n  text-transform: uppercase;\n  font-size: 24px;\n  padding: 0 15px 10px 15px;\n  margin: 0 10px 0 10px;\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.tab___aUqgO:hover {\n  color: #6d6d6d;\n}\n.active___2CPbZ {\n  border-bottom: 5px solid #fa5400;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___2i59X",
	"epic-font-base-md": "epic-font-base-md___3VdPZ",
	"epic-font-marketing": "epic-font-marketing___3pQg6",
	"epic-font-brand": "epic-font-brand___g9M8y",
	"ripple": "ripple___1aW4I",
	"tabList": "tabList___3EuQP",
	"tab": "tab___aUqgO",
	"active": "active___2CPbZ"
};