import React, { useState, useEffect, useRef } from "react";
import {Icon, Popover} from "@nike/eds";
import "./SnackBar.css";

function SnackBar({params, onDismissFunction}) {
    const [textCopied, setTextCopied] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function copyButton(){
        let messageCheck = '';
        if (params && params.message && Array.isArray(params.message)) {
            for (const lineText of params.message) {
                if (messageCheck.length == 0) {
                    messageCheck = lineText
                } else {
                    messageCheck = `${messageCheck} \n ${lineText}`
                }
            }
            navigator.clipboard.writeText(messageCheck);
        } else {
            navigator.clipboard.writeText(params.message);
        }
        setTextCopied(true);        
    }
    function useOutsideAlerter(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onDismissFunction(false);
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }
    return(
    <div className="eds-snackbar" ref={wrapperRef}>
        <div className="eds-snack" aria-live="polite" role="alert" id="unique-id">
            <div className="eds-status-icon">
                <Icon name={params.status == 'error'? "BrightnessNone" : "Check"} backgroundColor={params.status == 'error'? "var(--eds-color-background-danger)" : "var(--eds-color-background-success)"} color="white" backgroundShape="circle"/>
            </div>
            <div className="eds-snack__body text-align-center">
                <div  style={{whiteSpace: 'pre'}}>{params && params.message && Array.isArray(params.message) ? params.message.join("\r\n") : params.message}</div>
            </div>
            <div className="snackButtons">                
                <Popover
                    isOpen={textCopied}
                    onClickOutside={() => setTextCopied(false)}
                    bodySlot={<span style={{color:"var(--eds-color-background-success)", fontWeight: 'bold'}}>Copied!</span>}
                >
                    <Icon title="Copy" onClick={()=> copyButton()} name="CopyPaste"/>
                </Popover>                   
                &nbsp;
                <Icon title="Close" onClick={()=>onDismissFunction(false)} name="Close"/>
            </div>
        </div>
    </div>
    );
}
export default SnackBar;