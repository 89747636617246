exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___vkMbY {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___1oxiG {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___18-5m {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___3lvpM {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.ripple___2UJtr {\n  overflow: hidden;\n  transform: translateZ(0);\n}\n.ripple___2UJtr:active:after {\n  transform: scale(0);\n  opacity: 0.5;\n  transition: 0s;\n}\n.ripple___2UJtr:after {\n  content: '';\n  display: block;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  pointer-events: none;\n  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);\n  background-repeat: no-repeat;\n  background-position: 50%;\n  transform: scale(10);\n  opacity: 0;\n  transition: transform 0.5s, opacity 1s;\n}\n.overlay___1UcS6 {\n  display: none;\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100vw;\n  background-color: rgba(0,0,0,0.65);\n}\n.overlay___1UcS6.show___1JaoV {\n  display: initial;\n  z-index: 6;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___vkMbY",
	"epic-font-base-md": "epic-font-base-md___1oxiG",
	"epic-font-marketing": "epic-font-marketing___18-5m",
	"epic-font-brand": "epic-font-brand___3lvpM",
	"ripple": "ripple___2UJtr",
	"overlay": "overlay___1UcS6",
	"show": "show___1JaoV"
};