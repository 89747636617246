import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.styl'

import { TableContainer, Thead, Th, Tbody, Tr, Td } from '@EPIC'

function StaticTable({ className, columns, data, isCompact }) {
  const rowOrder = columns.map(({ accessor }) => accessor)

  if (columns.length === 0) return null

  return (
    <TableContainer {...className}>
      <Thead>
        <Tr>
          {columns.map(({ Header }, i) => (
            <Th key={i} className={isCompact && styles.compactCell}>
              {Header}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((rowData, ix) => {
          return (
            <Tr key={`${ix}`}>
              {rowOrder.map((cellData, jx) => (
                <Td key={`${ix}-${jx}`} className={isCompact && styles.compactCell}>
                  {rowData[cellData]}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </TableContainer>
  )
}

StaticTable.propTypes = {
  /** Allow custom className to the component */
  className: PropTypes.string,
  /** Column headers (`Header` value is the text that will show up in the table & `accessor` value is which value shows up in the cell)*/
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      accessor: PropTypes.string,
    })
  ).isRequired,
  /** Array of data with `accessor` as the keys  */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Less padding around the Header & Body's cells */
  isCompact: PropTypes.bool,
}

StaticTable.defaultProps = {
  columns: [],
  data: [],
  className: '',
  isCompact: false,
}
export default StaticTable
