import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './Label.styl'

function Label({ hasErrors, errorMessage, required, label, className, children, ...props }) {
  let labelClass = classnames(styles.label, className)

  return (
    <label {...props} className={labelClass}>
      <span className={styles.labelWrapper}>
        {label && (
          <span className={styles.labelText}>
            {label} {required && <span className={styles.required}> *</span>}
          </span>
        )}
        {hasErrors && errorMessage && <span className={styles.errorText}>{errorMessage}</span>}
      </span>
      {children}
    </label>
  )
}

Label.propTypes = {
  /** Text/Node for the component (the label itself) */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /** Whether or not contents of label are a required field, will display a red star if so */
  required: PropTypes.bool,
  /** Children for label */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Sets whether input is in error state */
  hasErrors: PropTypes.bool,
  /** Error message to be displayed to user, appears in top right of textarea */
  errorMessage: PropTypes.string,
  /** Classname that is applied to container */
  className: PropTypes.string,
}

Label.defaultProps = {
  className: '',
}

export default Label
