import React, { useEffect, useState, useRef } from "react";
import { Text, TextField, Icon, Select, Button, Table, TableCell, TableHeading, ButtonGroup, Modal, Snack, Snackbar, Popover, Spinner} from "@nike/eds";
import axios from 'axios';
import apiService from '../../services/service';
import API_INTERFACE_URI from "../../constants/uri.constants";
import './CarrierAllocation.css';
import InfoModal from "./InfoModal.js";
import * as XLSX from 'xlsx';
import SnackBar from "../SnackBar/SnackBar.js";

function CarrierAllocation({groups}) {
    let height = window.innerHeight;
    const fieldOptionsArray = [{ value: "Carrier", label: "Carrier" }, { value: "DC", label: "DC" }, { value: "Mode", label: "Service Level" }]
    const [selectedCarrier, setSelectedCarrier] = useState([]);
    const [selectedDC, setSelectedDC] = useState([]);
    const [selectedMode, setSelectedMode] = useState([]);
    const [fieldOptions, setFieldOptions] = useState(fieldOptionsArray)
    const [carrierAllocationData, setCarrierAllocationData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [showEditInput, setEditInput] = useState(false);
    const [infoModal, setInfoModal] = useState({});
    const [dcArray, setDCarray] = useState([])
    const [carrierArray, setCarrierArray] = useState([])
    const [modeArray, setModeArray] = useState([])
    const [hideFilter, setHideFilter] = useState(false)
    const [snackMessage, setShowSnack] = useState({message: "", status:""});
    const [snackModalMessage, setShowModalSnack] = useState({message: "", status:""});
    const [selectedFile, setSelectedFile] = useState();
    const [showResults, setShowResults] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [header, setHeader] = useState([]);
    const [consolidatedResult, setConsolidatedResult] = useState([]);
    const [endOfRecords, setEndOfRecords] = useState();
    const [nextDate, setNextDate] = useState(startDate);
    const [previousDate, setPreviousDate] = useState(startDate);
    const [previousButton, setPreviousButton] = useState(true);
    const [displayMessage, setDisplayMessage] = useState('Select search criteria and submit to display results.')
    const [dateRange, setDateRange] = useState();
    const [popover, setPopoverShown] = useState({"popoverShown" :false , "id": ""});
    const [editCapacityDetails, setEditCapacityDetails] = useState();
    const [capacity, setCapacity] = useState();
    const [currentStartDate, setCurrentStartDate] = useState();
    const [searchText, setSerachText] = useState('');
    const [showSpinner, setShowSpinner] = useState(true);
    const [modalSpinner, setModalSpinner] = useState(false);
    const [outboxFunction, setOutboxFunction] = useState(true);
    const inputRef = useRef();
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const onChangeSearch = e => setSerachText(e.target.value);
    const handleFileInput = (file) => {
        setSelectedFile(file);
    }
    
    useEffect(() => {
        setPreviousDate(startDate)
        getMasterData()
    }, [])
    
    async function getCarrierAllocation(startDates) {
        let size = 30;
        setCurrentStartDate(startDates);
        setPreviousButton(startDates == startDate ? false : true)
        if(startDates && endDate){
            var d = new Date(startDates);
            d.setDate(d.getDate() - 30);
            let tempDate= d.getFullYear()+"-"+((d.getMonth() + 1)<10 ? '0'+(d.getMonth() + 1): (d.getMonth() + 1))+"-"+(d.getDate() < 10 ? '0'+d.getDate() : d.getDate());
            let preDate = new Date(tempDate).getTime() < new Date(startDate).getTime() ? startDate : tempDate;
            setPreviousDate(preDate);

            let selectedDcValues = selectedDC.map((e)=>e.value).toString();
            let selectedCarrierValues = selectedCarrier.map((e)=>e.value).toString();
            let selectedModeValues= selectedMode.map((e)=>e.value).toString();
            let shipViaSearch = searchText;
            
            try{
                const res = await apiService.get(
                    API_INTERFACE_URI.GET_CAPACITIES+"size="+size+"&fromDate="+startDates+"&toDate="+endDate+"&dc="+selectedDcValues+"&carrier="+selectedCarrierValues+"&mode="+selectedModeValues+"&shipVia="+shipViaSearch
                );
                if(res && res.data && res.data.records){
                    setShowResults(true);
                    setCarrierAllocationData(res.data.records)
                    setEndOfRecords(res.data.endOfRecords)
                    if(!res.data.endOfRecords){
                        setNextDate(res.data.nextStartDate)
                    }
                }
                 if(res && res.data && res.data.records.length == 0){
                    
                    setShowResults(false);
                    setCarrierAllocationData([])
                    setDisplayMessage("No Records found for above search criteria.")
                }
            } catch (e){
                setShowResults(false);
                setCarrierAllocationData([])
                setDisplayMessage("Carrier Allocations data could not be retrieved, Please try again later.")
            }
        }else{
            setShowSnack({message : "Please select start date and end date!", status: "error"})
        }
    }
    useEffect(()=>{
        displayCarrierAllocationData();        
    }, [carrierAllocationData])
    async function getMasterData() {
        try{
            const res = await apiService.get(API_INTERFACE_URI.GET_MASTER_DATA);
            if(res && res.data && res.data.objects){
                let dcArray = [
                    ...new Map(res.data.objects.map((item) => [item["nodeName"], item])).values()
                ];
                setDCarray([...dcArray.map((data) => ({ "value": data.PlntCd, "label": data.nodeName })).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
                let carrierArray = [
                    ...new Map(res.data.objects.map((item) => [item["CarrierGroupName"], item])).values()
                ];
                setCarrierArray([...carrierArray.map((data) => ({ "value": data.CarrierGroupName, "label": data.CarrierGroupName })).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
                let modeArray = [
                    ...new Map(res.data.objects.map((item) => [item["ServiceLevel"], item])).values()
                ];
                setModeArray([...modeArray.map((data) => ({ "value": data.ServiceLevel, "label": data.ServiceLevel })).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
            }   
        } catch (e){
            setShowResults(false);
            setCarrierAllocationData([]);
            setDisplayMessage("Carrier Allocations page can not be displayed at the moment, Please try again later.");
        }
    }

    function selectCarrierValues(data, item) {
        setSelectedCarrier([...data])
    }

    function selectDCValues(data, item) {
        setSelectedDC([...data])
    }

    function selectModeValues(data, item) {
        setSelectedMode([...data])
    }
    
    function validateFile(file){       
        const validDate = new RegExp('^(1[0-2]|0?[1-9])/(3[01]|[12][0-9]|0?[1-9])/(?:[0-9]{2})?[0-9]{2}$');
        const validNumber = new RegExp('^[0-9]*$');
        const reader = new FileReader();
        reader.onload = function (evt){ 
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type:'binary'});
            const wsname = wb.SheetNames[1];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, {raw: false});
            let invalidRecords = [];
            if (data && data.length > 0) {
                data.forEach((r, i) => {
                    if(i != 0){
                        Object.entries(r).forEach(([key, value], index) =>{
                            if(key.toLowerCase() == 'date'){
                                if (!validDate.test(value)) {                                    
                                    invalidRecords.push("Invalid Date format at row "+ (i+2));                                                          
                                }
                            }else if(i!=0){
                                if (!validNumber.test(value)) {                                    
                                    invalidRecords.push( "Invalid data found at row "+ (i+2))                                                            
                                }
                            }                                       
                        })
                    }
                })
            } else {                
                invalidRecords.push("Empty Sheet!")
            }
            
            if(invalidRecords && invalidRecords.length > 0){
                setShowModalSnack({message :invalidRecords, status: "error"});
                setModalSpinner(false);
                inputRef.current.value = "";
                setSelectedFile(''); 
            }else{
                uploadFiletoS3(file);
            }
        };
        reader.readAsBinaryString(file);
    }
    async function uploadFile(file){
        if (file && file.name) {
            let fileType = file.name.split('.')[1];
            if (file.name && (fileType == 'xlsx' || fileType == 'xls')) {
                await validateFile(file);
                setModalSpinner(true);
            } else {
                setShowModalSnack({ message: "Please select excel file !", status: "error" })
            }
        } else {
            setShowModalSnack({ message: "Please select excel file !", status: "error" })
        }
    }
    async function uploadFiletoS3(file){
        try{
            const res = await apiService.get(API_INTERFACE_URI.GET_PRESIGNED_URL+"fileName=carrier-allocation/"+file.name);
            if(res.data.url){
                const presignedS3Url = res.data.url;
                const axiosResponse = await axios.put(presignedS3Url, file);
                if(axiosResponse.status == 200){
                    let fileDetails = {"fileName": file.name}
                    try{
                        const saveDataResponse = await apiService.post(API_INTERFACE_URI.SAVE_S3_DATA, fileDetails);
                        
                        if(saveDataResponse.data.failedRecords.length == 0){
                            inputRef.current.value = "";
                            setSelectedFile('');
                            setVisible(!visible);
                            setModalSpinner(false);
                            setShowSnack({message : "File Uploaded Successfully !", status: "success"});
                            (startDate && endDate) && getCarrierAllocation(startDate);
                        } else if(saveDataResponse.data.failedRecords.length > 0){
                            inputRef.current.value = "";
                            setSelectedFile('');
                            setModalSpinner(false);
                            setShowModalSnack({message :  saveDataResponse.data.failedRecords, status: "error"});
                        }
                    } catch (e){
                        console.log('error', e);
                        inputRef.current.value = "";
                        setSelectedFile('');
                        setModalSpinner(false);
                        setShowModalSnack({message : "Something went wrong. Please contact your administrator.", status: "error"});
                    }
                }
            }
        } catch (e){
            console.log('error', e);
            setShowSnack({message : "Something went wrong. Please clear cache or contact your administrator.", status: "error"});
        }
    }
    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }
    async function getDaysArray(start, end) {
        for (
          var arr = [], dt = new Date(start);
          dt <= new Date(end);
          dt.setDate(dt.getDate() + 1)
        ) {
          arr.push(dt.toISOString().slice(0, 10));
        }
    
        const uniqueDates = arr.filter(onlyUnique);
        return uniqueDates;
    }
    
    async function displayCarrierAllocationData(){
        if(carrierAllocationData && carrierAllocationData.length > 0){
            var daysArray = await getDaysArray(startDate, endDate);
            const dateSet = new Set();
            carrierAllocationData.map(function(record){
                record.capacities.map((capacity) => {
                    dateSet.add(capacity.date);
                });
            });
            var dateListToDisplayBasedOnServiceResults = [];
            daysArray.map(days => {
                if (dateSet.has(days)){
                    var d = days.split('-');
                    let tempDate = d[1]+'/'+d[2]+'/'+d[0];
                    dateListToDisplayBasedOnServiceResults.push(tempDate);     
                }
            });
            
            var headerValues = carrierAllocationData.map(function (record) {
                // var compositeKey = record.nodeCode + "_" + record.providerName + "_" + record.nikeServiceLevelCode + "_" + record.shipVia;
                // record.compositeKey = compositeKey;
                // return record.nodeCode + "_" + record.providerName + "_" + record.nikeServiceLevelCode + "_" + record.shipVia
                var subKey = record.nodeCode + "_" + record.providerName + "_" + record.nikeServiceLevelCode + "_" + record.shipVia;
                var compositeKey = record.allocationColumnMappingAlteryx;
                record.compositeKey = compositeKey;
                record.subKey = subKey;
                var splitRank = record.allocationColumnOrderRank.split('_');
                var ranking = splitRank.map((e)=> parseInt(e) <= 9 ? '0'+e : e).join('');
                return {"compositeKey": record.allocationColumnMappingAlteryx, "subKey": subKey, "allocationColumnOrderRank": ranking};
            }).sort((a,b) => (a.allocationColumnOrderRank > b.allocationColumnOrderRank) ? 1 : ((b.allocationColumnOrderRank > a.allocationColumnOrderRank) ? -1 : 0));
            setHeader(headerValues);
            
            var consolidatedResultList = [];
            dateListToDisplayBasedOnServiceResults.forEach(function(dayItem, index){
                var consolidatedResult = {};
                consolidatedResult.date = dayItem;
                headerValues.forEach(function (headerItem, headerIndex){
                    consolidatedResult[headerItem.subKey] = {};
                    consolidatedResult[headerItem.subKey].subKey = headerItem.subKey;
                    consolidatedResult[headerItem.subKey].compositeKey = headerItem.compositeKey;
                    consolidatedResult[headerItem.subKey].capacities = [];
                    
                    var filteredItemByCompositeKey = carrierAllocationData.filter(record => record.subKey === headerItem.subKey)[0]
                    
                    var capacitiesFilter = filteredItemByCompositeKey.capacities.filter(record => {
                        var dte = record.date.split('-');
                        let tempDte = dte[1]+'/'+dte[2]+'/'+dte[0];
                        return tempDte == dayItem
                    })[0];
                    if(!!capacitiesFilter)
                        consolidatedResult[headerItem.subKey].capacities.push(capacitiesFilter);
                    
                });
                consolidatedResultList.push(consolidatedResult);
                setConsolidatedResult(consolidatedResultList);
                setShowSpinner(false);
            });
        }else{
            setConsolidatedResult([])
        }
        
    }
   
    function onNext(){
        getCarrierAllocation(nextDate)
    }

    function onPrevious(){
        getCarrierAllocation(previousDate)
    }
    
    function onStartDate(e){
        setStartDate(e.target.value)
        var d = new Date(e.target.value);
        d.setDate(d.getDate() + 89);
        var dateRange = d.getFullYear()+"-"+((d.getMonth() + 1)<10 ? '0'+(d.getMonth() + 1): (d.getMonth() + 1))+"-"+(d.getDate() < 10 ? '0'+d.getDate() : d.getDate());
        setDateRange(dateRange);
        setEndDate('');
    }
    async function formatCarrierAllocationData(carrierAllocationResponse){
        if(carrierAllocationResponse && carrierAllocationResponse.length > 0){
            var daysArray = await getDaysArray(startDate, endDate);
            const dateSet = new Set();
            carrierAllocationResponse.map(function(record){
                record.capacities.map((capacity) => {
                    var d = capacity.date.split('-');
                    let tempDate = d[1]+'/'+d[2]+'/'+d[0];
                    dateSet.add(tempDate);
                });
            });
            var dateListToDisplayBasedOnServiceResults = [];
            daysArray.map(days => {
                let dmy = days.split('-');
                let temporaryDate = dmy[1]+'/'+dmy[2]+'/'+dmy[0];
                if (dateSet.has(temporaryDate)){
                    dateListToDisplayBasedOnServiceResults.push(temporaryDate);     
                }
            });
            
            var headerValues = carrierAllocationResponse.map(function (record) {
                var compositeKey = record.nodeCode + "_" + record.providerName + "_" + record.nikeServiceLevelCode + "_" + record.shipVia;
                record.compositeKey = compositeKey;
                var splitRank = record.allocationColumnOrderRank.split('_');
                var ranking = splitRank.map((e)=> parseInt(e) <= 9 ? '0'+e : e).join('');
                return {header: compositeKey, subHeader: record.allocationColumnMappingAlteryx, "allocationColumnOrderRank": ranking}
            }).sort((a,b) => (a.allocationColumnOrderRank > b.allocationColumnOrderRank) ? 1 : ((b.allocationColumnOrderRank > a.allocationColumnOrderRank) ? -1 : 0));
            

            var consolidatedResultList = [];
            
            dateListToDisplayBasedOnServiceResults.forEach(function(dayItem, index){
                var consolidatedResult = {};
                consolidatedResult.date = dayItem;
                headerValues.forEach(function (headerItem, headerIndex){
                    consolidatedResult[headerItem.header] = 0;
                    var filteredItemByCompositeKey = carrierAllocationResponse.filter(record => record.compositeKey === headerItem.header)[0]
                    var capacitiesFilter = filteredItemByCompositeKey.capacities.filter(record => { 
                        let dmt = record.date.split('-');
                        let tDate = dmt[1]+'/'+dmt[2]+'/'+dmt[0];
                        return tDate == dayItem
                    })[0];
                    if(!!capacitiesFilter)
                        consolidatedResult[headerItem.header] = capacitiesFilter.maxCapacity;
                    
                });
                consolidatedResultList.push(consolidatedResult);                
            });
            var subHeader = {'date' : ''};
            headerValues.forEach(function (headerItem, headerIndex){
                subHeader[headerItem.header] = headerItem.subHeader;
            });
            consolidatedResultList.splice(0, 0, subHeader);
            return consolidatedResultList;
        }else{
            return []
        }
    }
    async function downloadExcel(){
        let selectedDcValues = selectedDC.map((e)=>e.value).toString();
        let selectedCarrierValues = selectedCarrier.map((e)=>e.value).toString();
        let selectedModeValues= selectedMode.map((e)=>e.value).toString();
        let shipViaSearch = searchText;
        let maxSize = 90;
        try{
            const res = await apiService.get(
                API_INTERFACE_URI.GET_CAPACITIES+"size="+maxSize+"&fromDate="+startDate+"&toDate="+endDate+"&dc="+selectedDcValues+"&carrier="+selectedCarrierValues+"&mode="+selectedModeValues+"&shipVia="+shipViaSearch
            );
            const sheet2 = await formatCarrierAllocationData(res.data.records);
            const ws2 = XLSX.utils.json_to_sheet(sheet2);
            const sheet1 = [
                {'Row' :'Row1', 'Details':'${DC_Name}_${Carrier}_${ServiceLevel}_${shipVia}', 'Description':'Unique identifier to identify the capacity allocation for a Date, DC, Carrier, Service Level, Ship Via'}, 
                {'Row' :'Row2', 'Details':'ShipViaDescription', 'Description':'Used for readability purpose'}, 
                {'Row' :'', 'Details':'Additional columns can be added to Row1 with the format ${DC_Name}_${Carrier}_${ServiceLevel}_${shipVia}', 'Description':''},
            ]
            const ws1 = XLSX.utils.json_to_sheet(sheet1);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws1, 'Definition');
            XLSX.utils.book_append_sheet(wb, ws2, 'capacity_allocations');
            XLSX.writeFile(wb, 'capacity_allocations_data.xlsx');
        } catch (e){
            console.log('error', e)
        }
    }

    function cancelUpload(){
        inputRef.current.value = "";
        setSelectedFile('');
        setVisible(!visible);
    }
    function cellClick(date, column, capacityVal, id, statuses, history){
        setEditInput(false);        
        setPopoverShown({"popoverShown" :true , "date": date, "column": column, "capacity": capacity, "id":id});
        setEditCapacityDetails({"date": date, "column": column, "capacity": capacityVal, "id":id, "statuses":statuses, "history":history});
        setCapacity(capacityVal);
        
    }
    
    function onEdit(){
        setEditInput(true);
        setOutboxFunction(true);
    }
    
    function onInfo(){
        setInfoModal({showInfoModal:true, statuses:editCapacityDetails.statuses, history:editCapacityDetails.history});
    }
    
    function noNeedtoUpdate(){
        setEditInput(false);
        setPopoverShown({"popoverShown" :false , "date": "", "column": "", "capacity": ""});
        setEditCapacityDetails({"date": "", "column": "", "capacity": "", "id":""})
    }
    async function updateCapacity(){
        if(capacity >= 0 && capacity){
            let body = {"allocationId": editCapacityDetails.id, "maxCapacity": capacity }
            try{
                const res = await apiService.put(
                    API_INTERFACE_URI.UPDATE_CAPACITY , body
                );
                if(res && res.data && res.data.records){
                    getCarrierAllocation(currentStartDate);
                    setEditInput(false);
                    setPopoverShown({"popoverShown" :false})
                    setShowSnack({message: "Capacity updated successfully !", status: "success"});
                }
            } catch (e){
                console.log('error', e);
                setEditInput(false);
                setPopoverShown({"popoverShown" :false})
                setShowSnack({message : "Something went wrong. Please contact your administrator.", status: "error"});
            }
        }else{
            setEditInput(false);
            setPopoverShown({"popoverShown" :false})
            setShowSnack({message : "Please enter valid capacity value.", status: "error"});
        }
        
    }
    function useOutsideAlerter(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setEditInput(false);
                setPopoverShown({"popoverShown" :false , "date": "", "column": "", "capacity": ""});
                setEditCapacityDetails({"date": "", "column": "", "capacity": "", "id":""})
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }
    function disableOutboxFunction(){
        setOutboxFunction(false);
    }
    function blockE(event){
        if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57){
            event.preventDefault();
        }
    }
    function onDismissSnack(prop){
        setShowSnack(false);
        setShowModalSnack(false);
    }
    const popoverBody = (
        <>
            { (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) && <Icon name="Edit" size="m" onClick={() => onEdit()}/>}
            &nbsp;&nbsp;
            <Icon name="Info" size="s" onClick={() => onInfo()}  backgroundShape="circle"  backgroundColor="var(--eds-color-grey-2)"/>
        </>
    );
    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 main-container" style={{minHeight:(height-120)+'px'}}>
            {snackMessage && snackMessage.message ? 
                <SnackBar   onDismissFunction={() => onDismissSnack()} 
                            params={{"status": snackMessage.status, "message": snackMessage.message}} 
                /> 
            : null}
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-align-left">
                    <Text font={"title-6"} as={"h6"} className="allocation-text"> Carrier Allocation </Text>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 flexBtn no-right-padding  no-left-padding">
                        { !hideFilter && <Button onClick={() => getCarrierAllocation(startDate)} variant="primary"><Icon name="Search" /></Button>}
                        &nbsp;&nbsp;
                        {
                            showResults &&
                        
                            <Button onClick={() => downloadExcel()}>
                                <Icon name="Download" />
                            </Button>
                        }
                        &nbsp;&nbsp;
                        { !hideFilter ?
                        <>
                            { (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) &&
                            <Button onClick={() => setVisible(!visible)}>
                                <Icon name="Upload" />
                            </Button>
                            }
                        </>
                        :null
                        }
                        <div className="text-align-left upload-modal">
                            <Modal
                                onDismiss={() => setVisible(!visible)}
                                isOpen={visible}
                                headerSlot={"Upload File"}
                                footerSlot={
                                    <ButtonGroup>
                                        <div className="upload-btn">
                                            <Button disabled={modalSpinner} onClick={() => uploadFile(selectedFile)} size="small">
                                                Upload
                                            </Button>
                                        </div>
                                        <div className="cancel-btn">
                                            <Button
                                                onClick={() => cancelUpload()}
                                                size="small"
                                                variant="secondary"
                                                disabled={modalSpinner}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </ButtonGroup>
                                }
                            >
                                {snackModalMessage && snackModalMessage.message ? 
                                        <SnackBar   onDismissFunction={() => onDismissSnack()} 
                                                    params={{"status": snackModalMessage.status, "message": snackModalMessage.message}} 
                                        /> 
                                : null}
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                    className="upload-modal-content"
                                >
                                    <TextField
                                        id="file"
                                        type="file"
                                        subtitle="Select File"
                                        ref={inputRef} 
                                        accept=".xlsx, .xls"
                                        onChange={(e) => handleFileInput(e.target.files[0])}
                                    />
                                    {modalSpinner &&
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 modal-spinner ">
                                        <Spinner size="large" />
                                    </div>
                                    }
                                </div>
                            </Modal>
                        </div>
                    </div>
                <form className="row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 no-right-padding">
                    { !hideFilter ? 
                        <>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 text-align-left padding-right-point-3-rem input-box">
                                <TextField
                                id="start-date"
                                onChange={e => onStartDate(e)}
                                value={startDate}
                                type="date"
                                subtitle="START DATE"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 text-align-left padding-right-point-3-rem input-box">
                                <TextField
                                id="end-date"
                                onChange={e => setEndDate(e.target.value)}
                                value={endDate}
                                type="date"
                                subtitle="END DATE"
                                max={dateRange}
                                min={startDate}
                                />
                            </div>
                        </>
                    :<div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-8"></div>
                    }
                    
                    

                    { !hideFilter ?  
                        <>   
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 no-right-padding">
                            <div className="text-align-left input-box">
                                <Select
                                    name={"DCValue"}
                                    isMulti                                            
                                    options={dcArray}
                                    subtitle="DC"
                                    hasErrors={false}
                                    errorMessage="This is an error"
                                    onChange={(newValue, object) => selectDCValues(newValue, object)}
                                    value={selectedDC}
                                />
                            </div>
                        </div>                     
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 no-right-padding">
                            <div className="text-align-left input-box">
                                <Select
                                    name={"CarrierValue"}
                                    isMulti
                                    options={carrierArray}
                                    subtitle="CARRIER"
                                    hasErrors={false}
                                    errorMessage="This is an error"
                                    onChange={(newValue, object) => selectCarrierValues(newValue, object)}
                                    value={selectedCarrier}
                                />
                            </div>
                        </div> 
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 no-right-padding">
                            <div className="text-align-left input-box ">
                                <Select
                                    name={"ModeValue"}
                                    isMulti                                            
                                    options={modeArray}
                                    subtitle="SERVICE LEVEL"
                                    hasErrors={false}
                                    errorMessage="This is an error"
                                    onChange={(newValue, object) => selectModeValues(newValue, object)}
                                    value={selectedMode}
                                />
                            </div>
                        </div> 
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 no-right-padding text-align-left input-box">
                            <TextField
                                type="text" 
                                subtitle="SEARCH"
                                id="search"
                                value={searchText}
                                onChange={onChangeSearch}
                                beforeSlot={<Icon name="Search" />}
                                placeholder="ShipVia"
                            />
                        </div>
                        </>                           
                        :<></>
                    }
                    
                </form>
                {
                    showResults ?
                    
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-1rem no-right-padding">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 carrier-allocation-box no-right-padding" style={{ maxHeight: (height) + 'px' }}>
                            {
                                !showSpinner && <div className="carrier-allocation-table" >
                                    <Table id="carrier-allocation-table">
                                        <thead>
                                            <tr>
                                            <TableHeading className="date-header-cell">Date</TableHeading>
                                            {header.map((column,index) => (
                                                <TableHeading key={index} className="header-cell">{column.compositeKey}</TableHeading>
                                            ))}
                                            {/* <TableHeading className="action-cell">Action</TableHeading> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {consolidatedResult && consolidatedResult.map((row, index1) => (
                                                <tr key={index1}>
                                                    <TableCell className="date-cell">{row.date}</TableCell>
                                                    {header.map((column, index2) => {                                                        
                                                        let capacityId = row[column.subKey].capacities.length &&  row[column.subKey].capacities[0].id;
                                                        let capacityValue = row[column.subKey].capacities.length && row[column.subKey].capacities[0].maxCapacity ? row[column.subKey].capacities[0].maxCapacity : 0;
                                                        let statuses = row[column.subKey].capacities.length && row[column.subKey].capacities[0].statuses.length ?  row[column.subKey].capacities[0].statuses : [];
                                                        let history = row[column.subKey].capacities.length && row[column.subKey].capacities[0].history.length ?  row[column.subKey].capacities[0].history : [];
                                                        let failed = statuses.filter((e)=>e.status == 'failure')
                                                        return(  <TableCell key={index1+""+index2} className="cell" hasError={failed.length > 0}>
                                                                    {
                                                                        showEditInput && popover.date == row.date && popover.column == column.subKey ?
                                                                        <div ref={outboxFunction ? wrapperRef : null} className=" text-align-left edit-cell-input-box">
                                                                            <TextField
                                                                            id={capacityId}
                                                                            onChange={(e) => setCapacity(e.target.value)}
                                                                            onKeyPress={(event)=>blockE(event)}
                                                                            value={capacity}
                                                                            type="number"
                                                                            min={0}
                                                                            onFocus={()=> disableOutboxFunction()}
                                                                            onBlur={() => ( editCapacityDetails.capacity == capacity ? noNeedtoUpdate() : updateCapacity())}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        (capacityId != '' ?
                                                                        <Popover className="cellPopover"
                                                                            onClickOutside={() => setPopoverShown({"popoverShown" :false})}
                                                                            isOpen={popover.popoverShown && popover.date == row.date && popover.column == column.subKey}
                                                                            bodySlot={popoverBody}
                                                                            placement={"right"}
                                                                            >
                                                                            <span onClick={() => cellClick(row.date, column.subKey, capacityValue, capacityId, statuses, history)}> {capacityValue}</span>
                                                                        </Popover>
                                                                        : <span className="disabledCell"> {capacityValue}</span>
                                                                        )
                                                                    
                                                                    }
                                                                </TableCell> )
                                                                                            
                                                    })}
                                                    {/* <TableCell className="edit-cell"><Icon name="Edit"></Icon></TableCell> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            }
                            {showSpinner && 
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 table-spinner">
                                    <Spinner size="large" />
                                </div>
                            }
                            {infoModal.showInfoModal ? <InfoModal infoModal={infoModal} /> : null}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 row padding-top-bottom-1rem">
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 paginationBtn">
                                <Button disabled={!previousButton} onClick={() => onPrevious()}>
                                    Previous
                                </Button>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 page-no">
                                {/* Page: 1/10 */}
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 paginationBtn">
                                <Button disabled={endOfRecords} onClick={() => onNext()}>
                                        &nbsp;&nbsp;Next&nbsp;&nbsp;
                                </Button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-1rem no-right-padding">
                        <Text font={"title-6"} as={"h6"} className="">{displayMessage}</Text>
                    </div>
                }
            </div>
        </div>
    );
}
export default CarrierAllocation;
