import styles from './PageSpinner.styl'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Overlay } from '@EPIC'

const PageSpinner = ({ show, overlay }) => (
  <Fragment>
    {overlay && <Overlay show={show} />}
    {show && <div className={styles.pageSpinner} />}
  </Fragment>
)

PageSpinner.propTypes = {
  /** Specifies showing Page Spinner */
  show: PropTypes.bool,
  /** Specifies showing Overlay backdrop */
  overlay: PropTypes.bool,
}

PageSpinner.defaultProps = {
  show: false,
  overlay: true,
}

export default PageSpinner
