import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Tooltip } from '@EPIC'
import styles from './Button.styl'

const Button = ({
  className,
  children,
  extraSmall,
  small,
  large,
  isDark,
  theme,
  inverse,
  text,
  style,
  type = 'button',
  disabledTip,
  ...others
}) => {
  let buttonClass = classnames(styles.button, className)

  if (extraSmall) buttonClass = classnames(styles.extraSmall, buttonClass)
  if (small) buttonClass = classnames(styles.small, buttonClass)
  if (large) buttonClass = classnames(styles.large, buttonClass)
  if (inverse) buttonClass = classnames(styles.inverse, buttonClass)
  if (text) buttonClass = classnames(styles.text, buttonClass)

  let themeStyles = {}
  if (theme.primary && theme.secondary) {
    if (inverse) {
      themeStyles = {
        background: `${theme.secondary} radial-gradient(circle, transparent 1%, ${theme.secondary} 1%) center / 15000%`,
        color: theme.primary,
        border: `1px solid ${theme.primary}`,
        transition: 'background 0.2s',
      }
    } else if (text) {
      themeStyles = {
        color: theme.primary,
        background: `${theme.secondary} radial-gradient(circle, transparent 1%, ${theme.secondary} 1%) center / 15000%`,
        transition: 'background 0.2s',
      }
    } else {
      themeStyles = {
        background: `${theme.primary} radial-gradient(circle, transparent 1%, ${theme.primary} 1%) center / 15000%`,
        color: theme.secondary,
        border: `1px solid ${theme.primary}`,
        transition: 'background 0.2s',
      }
    }
  }

  let buttonStyles = {
    ...style,
    ...themeStyles,
  }

  const button = (
    <button
      className={classnames(buttonClass, styles.ripple, isDark && styles.isDark)}
      style={buttonStyles}
      type={type}
      {...others}
    >
      {children}
    </button>
  )

  const { message, ...tooltipProps } = disabledTip || {}
  const wrapInTooltip = others.disabled && message

  return wrapInTooltip ? (
    <Tooltip message={message} {...tooltipProps}>
      {button}
    </Tooltip>
  ) : (
    button
  )
}

Button.propTypes = {
  /** Sets className on button element */
  className: PropTypes.string,
  /** Content inside button */
  children: PropTypes.any,
  /** Bool setting size of button to extra-small */
  extraSmall: PropTypes.bool,
  /** Bool setting size of button to small */
  small: PropTypes.bool,
  /** Bool setting size of button to large */
  large: PropTypes.bool,
  /** Default buttons styles to be user on a dark background */
  isDark: PropTypes.bool,
  /** Sets primary and Secondary colors for button */
  theme: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
  /** Bool specifying that button color scheme is reversed. If not used with theme it reverses with default colors (blue and white). */
  inverse: PropTypes.bool,
  /** Bool specifying button without bubble (until on hover). */
  text: PropTypes.bool,
  /** Explain why this button is disabled.
   *  When Button is disabled and this prop is set, wrap Button in a Tooltip passing these props to it.
   *  At minimum, should provide `message` prop.
   */
  disabledTip: PropTypes.shape({
    message: PropTypes.string,
    position: PropTypes.string,
  }),
}

Button.defaultProps = {
  className: '',
  disabledTip: {},
  theme: {},
  extraSmall: false,
  small: false,
  large: false,
  inverse: false,
  isDark: false,
}

export default Button
