import React, { useEffect, useState } from "react";
import './HomePage.css'
import apiService from '../../services/service'
import config from "../../config/config.js";
import { Button } from "@nike/eds";

function HomePage(props) {
  let height = window.innerHeight;
  const [response, setResponse] = useState();
  useEffect(() => {
    async function get() {
      const res = await apiService.callApi();
      if (res.status === 200) {
        setResponse(res.data);
      }
    }
    get()
  }, []);
  
  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 btnLink no-right-padding  no-left-padding" style={{display:"flex",flexDirection:"row-reverse"}}>
         <Button onClick={() => {window.open('https://niketech.service-now.com/nikenow?id=nike_incident&sysparm_service=&sysparm_service_area=','_blank') }} variant="secondary" title="ServiceNow">ServiceNow</Button> &nbsp;&nbsp;
         <Button onClick={() => {window.open('https://nike.box.com/s/fnreof5m8k0cpt54omycr8xraufr37px','_blank') }} variant="secondary" title="Documents">Training Docs</Button>
      </div>
      <div className="video-container" style={{height:(height)+'px'}}>
        <iframe width="80%" height={"100%"}
                src={config.homePageVideoLink}
                title="YouTube video player" frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen></iframe>
      </div>
      <h5>{response}</h5>
    </>
  );
}

export default HomePage;
