import React from 'react'
import PropTypes from 'prop-types'
import styles from './FormSection.styl'

const FormSection = ({ title, children, subtitle, attributes, className }) => {
  let formClassName = className ? `${styles.formSection} ${className}` : styles.formSection
  return (
    <div {...attributes} className={formClassName}>
      <div className={styles.header}>
        {title && <h1>{title}</h1>}
        {subtitle && <h3>{subtitle}</h3>}
      </div>
      {children}
    </div>
  )
}

FormSection.propTypes = {
  /** className passed to FormSection */
  className: PropTypes.string,
  /** Title Header for form section */
  title: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** SubTitle Header for form section */
  subtitle: PropTypes.string,
  /** Children */
  children: PropTypes.PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Any html attributes to apply to component */
  attributes: PropTypes.object,
}

FormSection.defaultProps = {
  className: '',
}

export default FormSection
