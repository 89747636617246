import React from 'react'
import classnames from 'classnames'
import styles from '../styles.styl'
import Search from './Search.js'

const SearchButton = ({
  searchOpen,
  searchValue,
  onClick,
  onChange,
  onSubmit,
  onClose,
  showButtonOnOpen,
  showButtonFirst,
  previewItems,
  placeholder,
}) => {
  let iconClass = 'epic-icon-search'
  if (searchOpen) {
    if (searchValue && searchValue.length > 0) {
      iconClass = classnames('epic-icon-search', styles.active)
    } else {
      iconClass = classnames('epic-icon-search', styles.disabled)
    }
    if (!showButtonOnOpen) iconClass = classnames('hide')
  }

  return (
    <button
      aria-label='button to open search bar'
      className={`${styles.searchBtn} ${
        showButtonFirst && !searchOpen ? styles.buttonLeft : ''
      }`.trim()}
      onClick={onClick}
    >
      <i id='er-nav-search-submit' className={`epic-icon ${iconClass}`} />
      {searchOpen ? (
        <Search
          value={searchValue}
          onChange={onChange}
          onSubmit={onSubmit}
          onClose={onClose}
          previewItems={previewItems}
          placeholder={placeholder}
          showButtonFirst={showButtonFirst}
        />
      ) : null}
    </button>
  )
}

export default SearchButton
