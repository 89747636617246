import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './Drawer.styl'

const Drawer = ({ show, className, offsetTop, children }) => (
  <div className={classnames(styles.drawerContainer, className, show && styles.show)}>
    <div className={styles.drawer} style={{ top: `${offsetTop}px` }}>
      {children}
    </div>
  </div>
)

Drawer.propTypes = {
  /** Dictates whether or not the drawer is visible */
  show: PropTypes.bool.isRequired,
  /** Optional additional custom className */
  className: PropTypes.string,
  /** Optional number to offset the top of the drawer to accommodate a navbar */
  offsetTop: PropTypes.number,
  /** children rendered inside of drawer */
  children: PropTypes.any,
}

Drawer.defaultProps = {
  show: false,
  className: '',
  offsetTop: 0,
}

export default Drawer
