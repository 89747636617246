import React, { useEffect, useState, useRef } from "react";
import { useRecoilState } from "recoil";
import {
  Text,
  TextField,
  Icon,
  Select,
  Button,
  Table,
  TableCell,
  TableHeading,
  ButtonGroup,
  Modal,
  Spinner,
  Toggle,
} from "@nike/eds";
import apiService from "../../services/service";
import API_INTERFACE_URI from "../../constants/uri.constants";
import "./CarrierTransitTimes.css";
import * as XLSX from "xlsx";
import SnackBar from "../SnackBar/SnackBar.js";
import GetCountModal from "./GetCountModal.js";
import EditModal from "./EditModal.js";
import InfoModal from "./InfoModal.js";
import { carrierListState } from "../../recoilStates/carrier.js";
import { serviceLevelListState } from "../../recoilStates/serviceLevel.js";
import { olListState } from "../../recoilStates/originLocations.js";

function CarrierTransitTimes({ groups, loggedInUser }) {
  let height = window.innerHeight;
  const size = 100;
  const [selectedCarrier, setSelectedCarrier] = useState([]);
  const [selectedOL, setSelectedOL] = useState([]);
  const [selectedMode, setSelectedMode] = useState([]);
  const [carrierTransitData, setCarrierTransitTimesData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [snackMessage, setShowSnack] = useState({ message: "", status: "" });
  const [snackModalMessage, setShowModalSnack] = useState({
    message: "",
    status: "",
  });
  const [selectedFile, setSelectedFile] = useState();
  const [showResults, setShowResults] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(
    "Select search criteria and submit to display results."
  );
  const [editData, setEditDetails] = useState();
  const [dzValue, setDzValue] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [modalSpinner, setModalSpinner] = useState(false);
  const [showGetCountModal, setGetCountModal] = useState(false);
  const [outboxFunction, setOutboxFunction] = useState(true);
  const [editTTModal, setTTEditModal] = useState();
  const [infoModal, setInfoModal] = useState({});
  const [olList, setOLList] = useRecoilState(olListState);
  const [carrierList, setCarrierListState] = useRecoilState(carrierListState);
  const [serviceLevelList, setServiceLevelListState] = useRecoilState(
    serviceLevelListState
  );

  const [pageData, setPageData] = useState({
    records: [],
    pageNumber: 1,
    length: 0,
  });
  const inputRef = useRef();
  const onChangeDz = (e) => setDzValue(e.target.value);
  async function checkPincode() {
    var status = true;
    const allPinCodes = dzValue.split(",");
    const isValidPin = new RegExp("^[0-9][0-9]{4}$");
    const isValidPincode = new RegExp("^[0][0]{4}$");
    await allPinCodes.forEach((e) => {
      if ((isValidPin.test(e) == false || isValidPincode.test(e) == true) && dzValue) {
        setShowSnack({
          message: "Please enter correct pincode(s). Ex: 12345,78910",
          status: "error",
        });
        status = false;
      }
    });
    return status;
  }
  function isValidPinCode(event) {
    event = event ? event : window.event;
    var charCode = event.which ? event.which : event.keyCode;
    if (
      charCode > 31 &&
      (charCode < 44 || charCode > 44) &&
      (charCode < 48 || charCode > 57)
    ) {
      event.preventDefault();
    }
  }
  const handleFileInput = (file) => {
    setSelectedFile(file);
  };

  useEffect(() => {
    getMasterData();
  }, []);
  async function onInfo(row) {
    try {
      const res = await apiService.get(
        API_INTERFACE_URI.CT_GET_STATUS +
          "originLocationCode=" +
          row.originLocation.originLocationCode +
          "&carrier=" +
          row.carrierName +
          "&serviceLevel=" +
          row.serviceLevel +
          "&nikeScacCode=" +
          row.SCACCode +
          "&destinationPostalCode=" +
          row.destinationLocation.destinationPostalCode
      );
      if (res && res.data) {
        setInfoModal({
          showInfoModal: true,
          statuses: res && res.data.status ? res.data.status : [],
          history: res && res.data.history ? res.data.history : [],
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  }
  async function getMasterData() {
    try {
      const res = await apiService.get(API_INTERFACE_URI.CT_CARRIER_SERVICE);
      if (res && res.data) {
        setOLList([
          ...res.data.origins
            .map((item) => ({
              value: item.originCode,
              label:
                item.originName +
                " - " +
                item.originCode +
                " - " +
                item.originType,
            }))

            .sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            ),
        ]);

        let carrierArray = [
          ...new Map(
            res.data.providerServices.map((item) => [item["carrier"], item])
          ).values(),
        ];

        setCarrierListState([
          ...carrierArray
            .map((data) => ({
              value: data.carrier,
              label: data.carrier,
            }))
            .sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            ),
        ]);

        let modeArray = [
          ...new Map(
            res.data.providerServices.map((item) => [
              item["serviceLevel"],
              item,
            ])
          ).values(),
        ];
        setServiceLevelListState([
          ...modeArray
            .map((data) => ({
              value: data.serviceLevel,
              label: data.serviceLevel,
            }))
            .sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            ),
        ]);
      }
    } catch (e) {
      setShowResults(false);
      setCarrierTransitTimesData([]);
      setDisplayMessage(
        "Carrier Transit Times page can not be displayed at the moment, Please try again later."
      );
    }
  }

  function selectCarrierValues(data) {
    setSelectedCarrier({ ...data });
  }

  function selectOLValues(data) {
    if (data && data.length <= 5) {
      setSelectedOL([...data]);
    }
  }

  function selectModeValues(data) {
    setSelectedMode({ ...data });
  }

  async function formatData(shipViaResponse) {
    var formatedData = [];
    if (shipViaResponse && shipViaResponse.length > 0) {
      shipViaResponse.map((a) => {
        formatedData.push({
          Carrier_Name: a.carrierName,
          Service_Level: a.serviceLevel,
          Carrier_SCAC: a.SCACCode,
          Origin_Zip: a.originLocation.originLocationCode,
          Destination_Zip: a.destinationLocation.destinationPostalCode,
          Zone: a.transitZoneCode,
          Transit_Days: a.transitTimeValue,
          Saturday_Eligible_Delivery:
            a.serviceCategoryCode == "SATURDAY_DELIVERY" ||
            a.serviceCategoryCode == "WEEKEND_DELIVERY"
              ? 1
              : 0,
          Sunday_Eligible_Delivery:
            a.serviceCategoryCode == "SUNDAY_DELIVERY" ||
            a.serviceCategoryCode == "WEEKEND_DELIVERY"
              ? 1
              : 0,
          Ship_From_Country: a.originLocation.isoCountryCode,
          Ship_To_Country: a.destinationLocation.isoCountryCode,
        });
      });
      return formatedData;
    } else {
      return [];
    }
  }

  async function downloadExcel() {
    let selectedDcValues = selectedOL.map((e) => e.value).toString();
    let selectedCarrierValues = selectedCarrier.value;
    let selectedModeValues = selectedMode.value;
    try {
      const res = await apiService.get(
        API_INTERFACE_URI.CT_GET +
          "dc=" +
          selectedDcValues +
          "&destinationZip=" +
          dzValue +
          (selectedCarrierValues ? "&carrier=" + selectedCarrierValues : "") +
          (selectedModeValues ? "&serviceLevel=" + selectedModeValues : "")
      );

      const sheet2 = await formatData(res.data.records);
      const ws2 = XLSX.utils.json_to_sheet(sheet2);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws2, "carrier_Transit_Times");
      XLSX.writeFile(wb, "carrier_Transit_Times.xlsx");
    } catch (e) {
      console.log("error", e);
    }
  }

  function cancelUpload() {
    inputRef.current.value = "";
    setSelectedFile("");
    setVisible(!visible);
  }

  function disableOutboxFunction() {
    setOutboxFunction(false);
  }

  function onDismissSnack(prop) {
    setShowSnack(false);
    setShowModalSnack(false);
  }

  function closeGetCountModal(visible, message) {
    setGetCountModal(visible);
    setShowSnack({
      message: message,
      status: "success",
    });
  }

  function uploadFile(file) {
    if (file && file.name) {
      let fileType = file.name.split(".")[1];
      if (file.name && (fileType == "xlsx" || fileType == "xls")) {
        uploadFiletoS3(file);
        setModalSpinner(true);
      } else {
        setShowModalSnack({
          message: "Please select excel file !",
          status: "error",
        });
      }
    } else {
      setShowModalSnack({
        message: "Please select excel file !",
        status: "error",
      });
    }
  }

  async function uploadFiletoS3(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("userEmail", loggedInUser);
    try {
      const res = await apiService.post(
        API_INTERFACE_URI.CT_FILE_UPLOAD,
        bodyFormData
      );

      if (res && res.status == 200) {
        inputRef.current.value = "";
        setSelectedFile("");
        setVisible(!visible);
        setModalSpinner(false);
        setShowSnack({
          message:
            "File Uploaded successfully and the validation results will be sent to email.",
          status: "success",
        });
      }
    } catch (e) {
      console.log("error", e);
      inputRef.current.value = "";
      setSelectedFile("");
      setModalSpinner(false);
      setShowSnack({
        message:
          "Something went wrong. Please clear cache or contact your administrator.",
        status: "error",
      });
    }
  }
  async function getTTData() {
    let selectedDcValues = selectedOL.map((e) => e.value).toString();
    let selectedCarrierValues = selectedCarrier.value;
    let selectedModeValues = selectedMode.value;

    if (selectedDcValues && dzValue) {
      const validPin = await checkPincode();
      if (validPin) {
        try {
          const res = await apiService.get(
            API_INTERFACE_URI.CT_GET +
              "dc=" +
              selectedDcValues +
              "&destinationZip=" +
              dzValue +
              (selectedCarrierValues
                ? "&carrier=" + selectedCarrierValues
                : "") +
              (selectedModeValues ? "&serviceLevel=" + selectedModeValues : "")
          );

          if (res && res.data && res.data.records) {
            setShowResults(true);
            setCarrierTransitTimesData(res.data.records);
            setPageData({
              records: res.data.records.slice(0, size),
              pageNumber: 1,
              length: size,
            });
          }
          if (res && res.data && res.data.records.length == 0) {
            setShowSpinner(false);
            setShowResults(false);
            setCarrierTransitTimesData([]);
            setDisplayMessage("No Records found for above search criteria.");
          }
        } catch (e) {
          console.log("error", e);
        }
      }
    } else {
      setShowSnack({
        message: "Please select origin location and enter destination zip",
        status: "error",
      });
    }
  }

  const nextPage = () => {
    let currentPageNumber = pageData.pageNumber + 1;
    setPageData({
      records: carrierTransitData.slice(
        pageData.length,
        pageData.length + size
      ),
      pageNumber: currentPageNumber,
      length: pageData.length + size,
    });
  };
  const previousPage = () => {
    let currentPageNumber = pageData.pageNumber - 1;
    setPageData({
      records: carrierTransitData.slice(
        pageData.length - size * 2,
        pageData.length - size
      ),
      pageNumber: currentPageNumber,
      length: pageData.length - size,
    });
  };
  function onEdit(row) {
    setEditModal(true);
    setEditDetails({
      ...row,
    });
  }
  function closeEditModal(prop) {
    setEditModal(prop);
  }
  useEffect(() => {
    if (editTTModal) {
      transitDataUpdated(editTTModal);
    }
  }, [editTTModal]);
  function transitDataUpdated(editTTModal) {
    setShowSnack(editTTModal);
    (selectedOL.length > 0 || selectedCarrier.length > 0) && getTTData();
  }

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 main-container"
      style={{ minHeight: height - 120 + "px" }}
    >
      {snackMessage && snackMessage.message ? (
        <SnackBar
          onDismissFunction={() => onDismissSnack()}
          params={{
            status: snackMessage.status,
            message: snackMessage.message,
          }}
        />
      ) : null}
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-align-left">
          <Text font={"title-6"} as={"h6"} className="allocation-text">
            {" "}
            Carrier Transit Times{" "}
          </Text>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 flexBtn no-right-padding  no-left-padding">
          <Button onClick={() => getTTData()} variant="primary">
            <Icon name="Search" />
          </Button>
          &nbsp;&nbsp;
          {showResults && (
            <Button onClick={() => downloadExcel()}>
              <Icon name="Download" />
            </Button>
          )}
          &nbsp;&nbsp;
          <>
            {(groups.includes("App.Transportation.OpsPortal.Test.Admin") ||
              groups.includes("App.Transportation.OpsPortal.Prod.Admin")) && (
              <Button onClick={() => setVisible(!visible)}>
                <Icon name="Upload" />
              </Button>
            )}
          </>
          &nbsp;&nbsp;
          <>
            {(groups.includes("App.Transportation.OpsPortal.Test.Admin") ||
              groups.includes("App.Transportation.OpsPortal.Prod.Admin")) && (
              <Button onClick={() => setGetCountModal(!showGetCountModal)}>
                <Icon name="Plus" />
              </Button>
            )}
          </>
          <div className="text-align-left upload-modal">
            <Modal
              onDismiss={() => setVisible(!visible)}
              isOpen={visible}
              headerSlot={"Upload File"}
              footerSlot={
                <ButtonGroup>
                  <div className="upload-btn">
                    <Button
                      disabled={modalSpinner}
                      onClick={() => uploadFile(selectedFile)}
                      size="small"
                    >
                      Upload
                    </Button>
                  </div>
                  <div className="cancel-btn">
                    <Button
                      onClick={() => cancelUpload()}
                      size="small"
                      variant="secondary"
                      disabled={modalSpinner}
                    >
                      Cancel
                    </Button>
                  </div>
                </ButtonGroup>
              }
            >
              {snackModalMessage && snackModalMessage.message ? (
                <SnackBar
                  onDismissFunction={() => onDismissSnack()}
                  params={{
                    status: snackModalMessage.status,
                    message: snackModalMessage.message,
                  }}
                />
              ) : null}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                className="upload-modal-content"
              >
                <TextField
                  id="file"
                  type="file"
                  subtitle="Select File"
                  ref={inputRef}
                  accept=".xlsx, .xls"
                  onChange={(e) => handleFileInput(e.target.files[0])}
                />
                {modalSpinner && (
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 modal-spinner ">
                    <Spinner size="large" />
                  </div>
                )}
              </div>
            </Modal>
          </div>
        </div>
        <form className="row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 no-right-padding">
          <>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 no-right-padding">
              <div className="text-align-left input-box">
                <Select
                  name={"OLValue"}
                  isMulti
                  options={olList}
                  subtitle="ORIGIN LOCATION"
                  hasErrors={false}
                  errorMessage="This is an error"
                  onChange={(newValue) => selectOLValues(newValue)}
                  value={selectedOL}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 no-right-padding">
              <div className="text-align-left input-box">
                <TextField
                  type="text"
                  subtitle="DESTINATION ZIP"
                  id="DZValue"
                  value={dzValue}
                  onChange={onChangeDz}
                  onBlur={checkPincode}
                  maxLength={29}
                  onKeyPress={(event) => isValidPinCode(event)}
                  placeholder="Ex: 12345,67890"
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 no-right-padding">
              <div className="text-align-left input-box">
                <Select
                  name={"CarrierValue"}
                  options={[{value: "", label:"Select..."}, ...carrierList]}
                  subtitle="CARRIER"
                  hasErrors={false}
                  errorMessage="This is an error"
                  onChange={(newValue) => selectCarrierValues(newValue)}
                  value={selectedCarrier}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 no-right-padding">
              <div className="text-align-left input-box ">
                <Select
                  name={"ModeValue"}
                  options={[{value: "", label:"Select..."}, ...serviceLevelList]}
                  subtitle="SERVICE LEVEL"
                  hasErrors={false}
                  errorMessage="This is an error"
                  onChange={(newValue) => selectModeValues(newValue)}
                  value={selectedMode}
                />
              </div>
            </div>
          </>
        </form>
        <EditModal
          showEditModal={showEditModal}
          editData={editData}
          closeModal={(prop) => closeEditModal(prop)}
          transitDataUpdated={(val) => setTTEditModal(val)}
          loggedInUser={loggedInUser}
        />
        <GetCountModal
          showGetCountModal={showGetCountModal}
          closeModal={(prop, message) => closeGetCountModal(prop, message)}
          loggedInUser={loggedInUser}
        />
        {showResults ? (
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-1rem no-right-padding">
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 container-box no-right-padding"
              style={{ maxHeight: height + "px" }}
            >
              {!showSpinner && (
                <div className="top-table">
                  <Table id="top-table">
                    <thead>
                      <tr>
                        <TableHeading className="header-cell">
                          Origin Location
                        </TableHeading>
                        <TableHeading className="header-cell">
                          Destination Zip
                        </TableHeading>
                        <TableHeading className="header-cell">
                          Carrier
                        </TableHeading>
                        <TableHeading className="header-cell">
                          Service Level
                        </TableHeading>
                        <TableHeading className="header-cell">
                          SCAC Code
                        </TableHeading>
                        <TableHeading className="header-cell">
                          Transit Zone Code
                        </TableHeading>
                        <TableHeading className="header-cell">
                          Transit Time Days
                        </TableHeading>
                        <TableHeading className="header-cell">
                          Saturday Eligible
                        </TableHeading>
                        <TableHeading className="header-cell">
                          Sunday Eligible
                        </TableHeading>
                        <TableHeading className="header-cell">
                          Info
                        </TableHeading>
                      </tr>
                    </thead>
                    <tbody>
                      {pageData &&
                        pageData.records &&
                        pageData.records.length > 0 &&
                        pageData.records.map((row, index1) => (
                          <tr key={index1}>
                            <TableCell className="cell">
                              {row.originLocation.originLocationCode}
                            </TableCell>
                            <TableCell className="cell">
                              {row.destinationLocation.destinationPostalCode}
                            </TableCell>
                            <TableCell className="cell">
                              {row.carrierName}
                            </TableCell>
                            <TableCell className="cell">
                              {row.serviceLevel}
                            </TableCell>
                            <TableCell
                              className="cell"
                              onClick={() => onEdit(row)}
                            >
                              {row.SCACCode}
                            </TableCell>
                            <TableCell
                              className="cell"
                              onClick={() => onEdit(row)}
                            >
                              {row.transitZoneCode}
                            </TableCell>
                            <TableCell
                              className="cell"
                              onClick={() => onEdit(row)}
                            >
                              {row.transitTimeValue}
                            </TableCell>
                            <TableCell className="cell">
                              <Toggle
                                disabled
                                id={"saturdayEligible"}
                                name={"saturdayEligible"}
                                checked={
                                  row.serviceCategoryCode ==
                                    "SATURDAY_DELIVERY" ||
                                  row.serviceCategoryCode == "WEEKEND_DELIVERY"
                                    ? true
                                    : false
                                }
                              />
                            </TableCell>
                            <TableCell className="cell">
                              <Toggle
                                disabled
                                id={"sundayEligible"}
                                name={"sundayEligible"}
                                checked={
                                  row.serviceCategoryCode ==
                                    "SUNDAY_DELIVERY" ||
                                  row.serviceCategoryCode == "WEEKEND_DELIVERY"
                                    ? true
                                    : false
                                }
                              />
                            </TableCell>
                            <TableCell className="tt-action-cell">
                              <Icon
                                name="Info"
                                size="s"
                                onClick={() => onInfo(row)}
                                backgroundShape="circle"
                                backgroundColor="var(--eds-color-grey-2)"
                              />
                            </TableCell>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {showSpinner && (
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 table-spinner">
                  <Spinner size="large" />
                </div>
              )}
              {infoModal.showInfoModal ? (
                <InfoModal infoModal={infoModal} />
              ) : null}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 row padding-top-bottom-1rem">
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 paginationBtn">
                <Button
                  disabled={pageData.length == size}
                  onClick={() => previousPage()}
                >
                  Previous
                </Button>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 page-no">
                {/* Page: 1/10 */}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 paginationBtn">
                <Button
                  disabled={carrierTransitData.length <= pageData.length}
                  onClick={() => nextPage()}
                >
                  &nbsp;&nbsp;Next&nbsp;&nbsp;
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-1rem no-right-padding">
            <Text font={"title-6"} as={"h6"} className="">
              {displayMessage}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
export default CarrierTransitTimes;
