// Next Generation
import TextInput from './TextInput/TextInput.js'
import TextArea from './TextArea/TextArea.js'
import FileInput from './FileInput/FileInput.js'
import Select from './Select/Select.js'
import Modal from './Modal/Modal.js'
import NavBar from './NavBar/NavBar.js'
import ListItem from './ListItem/ListItem.js'

export default {
  TextInput,
  TextArea,
  FileInput,
  Modal,
  Select,
  NavBar,
  ListItem,
}
