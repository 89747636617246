import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactSelect from 'react-select'

import stylesDefault from './styles.styl'
import stylesInverse from './styles-inverted.styl'
import { Icon, IconButton, Spinner } from '@EPIC'

const styles = { ...stylesDefault, ...stylesInverse }

const ActionInput = forwardRef(function ActionInput(
  {
    className,
    type,
    inverse,
    onAction,
    large,
    rightButton,
    ariaLabel,
    icon,
    iconSize,
    isPending,
    selectProps,
    value,
    onClear,
    dropdownItems,
    onListItemClick,
    dropdownIsOpen,
    renderListItem,
    spinnerColor,
    ...other
  },
  ref
) {
  const componentClassName = classnames(
    className,
    styles.component,
    // TODO: This is temporary until select styles are updated
    inverse || selectProps || dropdownItems.length ? styles.inverted : styles.default,
    {
      [styles.large]: large,
      [styles.dropdownOpen]: dropdownIsOpen,
    }
  )
  const buttonClassName = classnames(
    styles.button,
    rightButton ? styles.buttonRight : styles.buttonLeft
  )
  const IconButtonClassName = classnames(styles.clearButton, styles.button, {
    [styles.closeHidden]: !value,
    [styles.buttonRight]: !rightButton,
  })
  const iconClassName = `${large ? styles.largeIcon : ''}`
  const inputClassName = classnames(styles.input, {
    [styles.inputRight]: rightButton,
    [styles.inputLeft]: !rightButton,
    [styles.withSelect]: selectProps,
    [styles.dropdownOpen]: dropdownIsOpen,
  })

  function onResultClick(result, i) {
    if (typeof onResultClick === 'function') {
      onListItemClick(result, i)
    }
  }

  function fireOnAction(e) {
    if (typeof onAction === 'function') {
      onAction(e)
    }
  }

  function renderDefaultListItem(result, i) {
    return dropdownItems.length === 0 ? (
      <li>No results</li>
    ) : (
      <li onClick={() => onResultClick(result, i)} key={result}>
        {result}
      </li>
    )
  }

  return (
    <div className={componentClassName}>
      <input
        {...other}
        value={value}
        className={inputClassName}
        aria-label={`${ariaLabel} field`}
        type='text'
        ref={ref}
      />
      {onClear && (
        <IconButton
          iconSize='m'
          bare
          onClick={onClear}
          type='Close'
          buttonClassName={IconButtonClassName}
        />
      )}

      <button
        aria-label={`${ariaLabel} action`}
        type={type}
        className={buttonClassName}
        onClick={fireOnAction}
      >
        {isPending ? (
          <Spinner color={spinnerColor} />
        ) : (
          <Icon type={icon} size={iconSize} className={iconClassName} />
        )}
      </button>

      {selectProps && (
        <ReactSelect
          formatOptionLabel={({ value, label }, props) => {
            return selectProps.value.value !== value ? (
              label
            ) : (
              <div className={props.context === 'menu' ? styles.selected : ''}>
                {props.context === 'menu' && <Icon type='Check' size='s' />}
                {label}
              </div>
            )
          }}
          {...selectProps}
          classNamePrefix={'ActionInput'}
          className={classnames(styles.select, selectProps.className)}
        />
      )}

      {dropdownIsOpen && (
        <ul className={classnames(styles.dropdownList, selectProps ? styles.withSelect : '')}>
          {dropdownItems.map((result, i) => {
            return renderListItem ? renderListItem(result, i) : renderDefaultListItem(result, i)
          })}
        </ul>
      )}
    </div>
  )
})

ActionInput.propTypes = {
  /** Define custom css class to override styles */
  className: PropTypes.string,

  /** Type of button */
  type: PropTypes.string,

  /** Handle change of the input value */
  onChange: PropTypes.func,

  /** Handle when the search button is clicked */
  onAction: PropTypes.func,

  /** Handle when `x` icon is clicked in input. `X` is only rendered if onClear is present */
  onClear: PropTypes.func,

  /** Render custom list item for dropdown. This prop will render the `onListItemClick` obsolete, you must pass in a custom `onClick` on on the element in this render. */
  renderListItem: PropTypes.func,

  /** Handle when a list item is clicked (Used in default render only). */
  onListItemClick: PropTypes.func,

  /** Predefined large search option */
  large: PropTypes.bool,

  /** Show the button on the right */
  rightButton: PropTypes.bool,

  /** Aria label */
  ariaLabel: PropTypes.string.isRequired,

  /** Epic icon for the button */
  icon: PropTypes.string,

  /** Epic icon size (s, m, l) */
  iconSize: PropTypes.oneOf(['s', 'm', 'l']),

  /** Spin the icon in the button when pending */
  isPending: PropTypes.bool,

  /** Color passed to spinner in isPending state */
  spinnerColor: PropTypes.string,

  /** Props for Select component, presence of this prop will render the Select */
  selectProps: PropTypes.object,

  /** Array of items to pass to dropdown options */
  dropdownItems: PropTypes.array,

  /** Boolean dictating open/closed state of dropdown */
  dropdownIsOpen: PropTypes.bool,
}

ActionInput.defaultProps = {
  className: '',
  rightButton: false,
  icon: 'Search',
  iconSize: 'm',
  dropdownItems: [],
  isPending: false,
  spinnerColor: '#111',
}

export default ActionInput
