import config from "../config/config.js";

const API_INTERFACE_URI = {
  TEST_API: `${config.apiUrl}/home`,
  GET_CAPACITIES: `${config.apiUrl}/carrier-allocation/capacities/v1?`,
  GET_MASTER_DATA: `${config.apiUrl}/master-data/v1`,
  SAVE_S3_DATA: `${config.apiUrl}/carrier-allocation/capacities/v1`,
  GET_PRESIGNED_URL: `${config.apiUrl}/presigned-url/upload-url/v1?`,
  UPDATE_CAPACITY: `${config.apiUrl}/carrier-allocation/capacities/v1`,
  SAVE_FILLS_DATA: `${config.apiUrl}/capacity-fills/fills/v1`,
  GET_FILLS_DATA: `${config.apiUrl}/capacity-fills/fills/v1?`,
  UPDATE_FILLS: `${config.apiUrl}/capacity-fills/fills/v1`,
  CAPACITY_BUCKET: `${config.apiUrl}/geo-operational-data/capacity-bucket/v1`,
  GET_SHIPVIA_DATA: `${config.apiUrl}/ship-via/shipVia/v1?`,
  GET_MASTER_DATA_2: `${config.apiUrl}/geo-operational-data/capacity-buckets-and-channels`,
  SAVE_SHIPVIA_DATA: `${config.apiUrl}/ship-via/shipVia/v1`,
  CREATE_SHIPVIA: `${config.apiUrl}/ship-via/shipVia/v2`,
  GET_VDS_DATA: `${config.apiUrl}/volume-distribution/vds/v1?`,
  SAVE_VDS_DATA: `${config.apiUrl}/volume-distribution/vds/v1`,
  UPDATE_VDS_DATA: `${config.apiUrl}/volume-distribution/vds/v1`,
  GENERATE_REPORT: `${config.apiUrl}/audit-report/v1`,
  CT_FILE_UPLOAD: `${config.transitTimeApiUrl}/logistics/na_transittime/v1/upload/`,
  CT_ADD: `${config.transitTimeApiUrl}/logistics/na_transittime/v1/`,
  CT_EDIT: `${config.transitTimeApiUrl}/logistics/na_transittime/v1/`,
  CT_GET: `${config.transitTimeApiUrl}/logistics/na_transittime/v1/?`,
  CT_ORIGIN: `${config.transitTimeApiUrl}/logistics/na_transittime/v1/location/`,
  CT_GET_STATUS: `${config.transitTimeApiUrl}/logistics/na_transittime/v1/auditTrail?`,
  CT_CARRIER_SERVICE: `${config.transitTimeApiUrl}/logistics/na_transittime/v1/locationAndProviderServicesDetails/`,
};

export default API_INTERFACE_URI;
