import React, { useEffect, useState, useRef } from "react";
import { Text, TextField, Icon, Select, Button, Table, TableCell, TableHeading, ButtonGroup, Modal, Snack, Snackbar, Popover, Spinner, RadioGroup, Radio} from "@nike/eds";
import axios from 'axios';
import apiService from '../../services/service';
import API_INTERFACE_URI from "../../constants/uri.constants";
import './AddEditShipVia.css';
import SnackBar from "../SnackBar/SnackBar.js";

function AddEditShipVia({showModal, closeModal, modalType, editDetails, shipViaUpdated}) {
    const [visible, setVisible] = useState(showModal);
    const [dcArray, setDCarray] = useState([]);
    const [carrierArray, setCarrierArray] = useState([]);
    const [modeArray, setModeArray] = useState([]);
    const [meansArray, setMeansArray] = useState([]);
    const [channelsArray, setChannelsArray] = useState([]);
    const [serviceLevelArray, setServiceLevelArray] = useState([]);
    const [tmsPartnerArray, setTMSPartnerArray] = useState([]);
    const [snackModalMessage, setShowModalSnack] = useState({message: "", status:""});
    const [validation, setValidation] = useState();
    const [activateValidation, setActivateValidation] = useState(false);
    const [modalValues, setModalValues] = useState({
        activeIndicator: "1",
        byPassIndicator: "0",
        oscLaneIndicator: "0",
        coldLaneIndicator: "0"
    });
    async function closeShipViaModal(prop){
        await setVisible(false);
        closeModal(prop);
    }

    useEffect(()=>{
        if(editDetails && editDetails.id){
            setModalValues({...editDetails, 
                secondaryProviderLabel: editDetails.secondaryProviderName ? editDetails.secondaryProviderName :"Native",
                activeIndicator: ""+editDetails.activeIndicator+"",
                byPassIndicator: ""+editDetails.byPassIndicator+"",
                oscLaneIndicator: ""+editDetails.oscLaneIndicator+"",
                coldLaneIndicator: ""+editDetails.coldLaneIndicator+""
            })
        }else{
            setModalValues({...modalValues, 
                activeIndicator: "1",
                byPassIndicator: "0",
                oscLaneIndicator: "0",
                coldLaneIndicator: "0"
            })
        }
    }, [editDetails])

    async function getMasterData() {
        try{
            const res = await apiService.get(API_INTERFACE_URI.GET_MASTER_DATA);
            const res2 = await apiService.get(API_INTERFACE_URI.GET_MASTER_DATA_2);
            if(res && res.data && res.data.objects){
                let dcArray = [
                    ...new Map(res.data.objects.map((item) => [item["nodeName"], item])).values()
                ];
                setDCarray([...dcArray.map((data) => ({ "value": data.PlntCd, "label": data.nodeName })).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
                let carrierArray = [
                    ...new Map(res.data.objects.map((item) => [item["CarrierGroupName"], item])).values()
                ];
                setCarrierArray([...carrierArray.map((data) => ({ "value": data.CarrierGroupName, "label": data.CarrierGroupName })).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
                let modeArray = [
                    ...new Map(res.data.objects.map((item) => [item["ModeCode"], item])).values()
                ];
                setModeArray([...modeArray.map((data) => ({ "value": data.ModeCode, "label": data.ModeCode })).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
                let serviceLevelArray = [
                    ...new Map(res.data.objects.map((item) => [item["ServiceLevel"], item])).values()
                ];
                setServiceLevelArray([...serviceLevelArray.map((data) => ({ "value": data.ServiceLevel, "label": data.ServiceLevel })).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
                let meansArray = [
                    ...new Map(res.data.objects.map((item) => [item["MeansCode"], item])).values()
                ];
                setMeansArray([...meansArray.map((data) => ({ "value": data.MeansCode, "label": data.MeansCode })).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
                let tmsPartnerArray = [
                    ...new Map(res.data.objects.map((item) => [item["secondaryProviderName"], item])).values()
                ];
                setTMSPartnerArray([{ "value": "", "label": "Native" }, ...tmsPartnerArray.map((data) => ({ "value": data.secondaryProviderName, "label": data.secondaryProviderName })).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)).filter((e)=> e.value != undefined)])
            }  
            if(res2 && res2.data){
                setChannelsArray([...res2.data.Channels.map((data) => ({ "value": data, "label": data })).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])               
            }
        } catch (e){
            console.log('error', e)
        }
    }
    
    useEffect(() => {
        getMasterData();
    }, []);

    // useEffect(()=>{
    //     setValidation(modalValues)
    // }, [[], modalValues])
    async function saveShipVia(){
        const alphaNum = new RegExp("^[a-zA-Z0-9]*$");
        if(modalValues && modalValues.shipViaDesc && modalValues.nodeCode && modalValues.carrierGroupName && modalValues.serviceLevel && modalValues.shipVia && modalValues.modeCode && modalValues.meansCode && modalValues.scacCode && modalValues.capacityBucket && modalValues.channel && modalValues.allocationColumnMappingAlteryx && modalValues.vdsColumnMapping){
            if(!(alphaNum.test(modalValues.shipVia) && alphaNum.test(modalValues.scacCode) && alphaNum.test(modalValues.capacityBucket))){          
                setShowModalSnack({message : "Please Enter alphanumeric values only.", status: "error"});
            }else{
                let data = {
                    "secondaryProviderName": modalValues.secondaryProviderName,
                    "shipViaDesc": modalValues.shipViaDesc,
                    "nodeCode": modalValues.nodeCode,
                    "carrierGroupName": modalValues.carrierGroupName,
                    "serviceLevel": modalValues.serviceLevel,
                    "shipVia": modalValues.shipVia,
                    "modeCode": modalValues.modeCode,
                    "meansCode": modalValues.meansCode,
                    "scacCode": modalValues.scacCode,
                    "capacityBucket": modalValues.capacityBucket,
                    "channel": modalValues.channel,
                    "allocationColumnMappingAlteryx": modalValues.allocationColumnMappingAlteryx,
                    "vdsColumnMapping": modalValues.vdsColumnMapping,
                    "activeIndicator": parseInt(modalValues.activeIndicator),
                    "oscLaneIndicator": parseInt(modalValues.oscLaneIndicator),
                    "coldLaneIndicator": parseInt(modalValues.coldLaneIndicator),
                    "byPassIndicator":  parseInt(modalValues.byPassIndicator)
                }
                try{
                    const res = await apiService.post(
                        API_INTERFACE_URI.CREATE_SHIPVIA , data
                    );
                    if(res && res.data && res.data.shipViaRecord){    
                        shipViaUpdated({message : "ShipVia is added successfully.", status: "success"});
                        setModalValues({});
                        closeShipViaModal(!visible); 
                    }
                    if(res && res.data && res.data.failedRecords.length > 0){    
                        shipViaUpdated({message : res.data.failedRecords[0], status: "error"});
                        setModalValues({});
                        closeShipViaModal(!visible); 
                    }
                } catch(e){
                    console.log('error', e);
                    setShowModalSnack({ message: "Something went wrong. Please contact your administrator.", status: "error" });
                } 
            }
        }else{
            setActivateValidation(true);
            setValidation({...modalValues});
        }
    }

    async function updateShipVia(){
        const alphaNum = new RegExp("^[a-zA-Z0-9]*$");
        if(modalValues && modalValues.capacityBucket && modalValues.allocationColumnMappingAlteryx && modalValues.vdsColumnMapping){
            if(!(alphaNum.test(modalValues.capacityBucket))){          
                setShowModalSnack({message : "Please Enter alphanumeric values only.", status: "error"});
            }else{
                if (editDetails && editDetails.activeIndicator != modalValues.activeIndicator || editDetails.secondaryProviderName != modalValues.secondaryProviderName || editDetails.allocationColumnMappingAlteryx != modalValues.allocationColumnMappingAlteryx || editDetails.vdsColumnMapping != modalValues.vdsColumnMapping || editDetails.capacityBucket != modalValues.capacityBucket  || editDetails.oscLaneIndicator != modalValues.oscLaneIndicator || editDetails.coldLaneIndicator != modalValues.coldLaneIndicator || editDetails.byPassIndicator != modalValues.byPassIndicator   ) {
                    let data = {
                        "secondaryProviderName": modalValues.secondaryProviderName,
                        "shipViaDesc": modalValues.shipViaDesc,
                        "nodeCode": modalValues.nodeCode,
                        "carrierGroupName": modalValues.carrierGroupName,
                        "serviceLevel": modalValues.serviceLevel,
                        "shipVia": modalValues.shipVia,
                        "modeCode": modalValues.modeCode,
                        "meansCode": modalValues.meansCode,
                        "scacCode": modalValues.scacCode,
                        "capacityBucket": modalValues.capacityBucket,
                        "channel": modalValues.channel,
                        "allocationColumnMappingAlteryx": modalValues.allocationColumnMappingAlteryx,
                        "vdsColumnMapping": modalValues.vdsColumnMapping,
                        "activeIndicator": parseInt(modalValues.activeIndicator),
                        "oscLaneIndicator": parseInt(modalValues.oscLaneIndicator),
                        "coldLaneIndicator": parseInt(modalValues.coldLaneIndicator),
                        "byPassIndicator":  parseInt(modalValues.byPassIndicator)
                    }
                    try{
                        const res = await apiService.put(
                            API_INTERFACE_URI.SAVE_SHIPVIA_DATA , data
                        );
                        if(res && res.data.shipViaRecord){                    
                            shipViaUpdated({message : "ShipVia is updated successfully.", status: "success"});
                            setModalValues({})
                            closeShipViaModal(!visible); 
                        }
                    } catch(e){
                        console.log('error', e);
                        setShowModalSnack({ message: "Something went wrong. Please contact your administrator.", status: "error" });
                    } 
                    
                } else{
                    closeShipViaModal(!visible); 
                }
              
            }
        }else{
            setActivateValidation(true);
            setValidation({...modalValues});
        }
    }
    function onDismissSnack(prop){
        setShowModalSnack(false)
    }

    function changeModalValues(field, value){
        setModalValues({...modalValues, [field]: value});
        if(activateValidation){
            setValidation({...modalValues, [field]: value});
        }
    }

    function changeChannelValues(field, value){
        setModalValues({...modalValues, [field]: value});
        if(value == 'COMMERCIAL'){
            setModalValues({...modalValues, [field]: value, "secondaryProviderName":"", "secondaryProviderLabel": "Native" });
        }
        if(activateValidation){
            setValidation({...modalValues, [field]: value});
        }
    }

    function selectDCValues(field, value, field2, value2){
        setModalValues({...modalValues, [field]: value, [field2]: value2});
        if(activateValidation){
            setValidation({...modalValues, [field]: value, [field2]: value2});
        }
    }

    function selectTMSValues(field, value, field2, value2){
        setModalValues({...modalValues, [field]: value, [field2]: value2});
        if(modalValues.channel == 'COMMERCIAL'){        
            if(value2 != 'Native'){
                setShowModalSnack({message: "If channel is COMMERCIAL then \r\n TMS Partner should be Native.", status:"error"});
            }
            setModalValues({...modalValues, "secondaryProviderName":"", "secondaryProviderLabel": "Native" });
        }
        if(activateValidation){
            setValidation({...modalValues, [field]: value, [field2]: value2});
        }
    }
    
    function blockSymbols(e){
        var k = e.which;
        if(!((k > 47 && k < 58) || (k > 64 && k < 91) || (k > 96 && k < 123) || k==8 || k==0)){
            e.preventDefault();
        }
    }
    return (
        <>
            <Modal
            onDismiss={() => closeShipViaModal(!visible)}
            isOpen={visible}
            headerSlot={'ShipVia Settings'}
            className="shipvia-modal"
            >
                {snackModalMessage && snackModalMessage.message ? 
                    <SnackBar   onDismissFunction={() => onDismissSnack()} 
                                params={{"status": snackModalMessage.status, "message": snackModalMessage.message}} 
                    /> 
                : null}
                <form className="shipvia-modal-content">
                    <div className="radio-field">
                        <RadioGroup
                        id="active"
                        subtitle="ACTIVE"
                        name="active-group"
                        valueSelected={modalValues.activeIndicator}
                        onChange={e => changeModalValues("activeIndicator", e.target.value)}
                        orientation="horizontal"
                        >
                            <Radio label="ON" value={"1"} id="active-on" />
                            <Radio label="OFF" value={"0"} id="active-off" />
                        </RadioGroup>
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <Select
                            name={"DCValue"}                                      
                            options={dcArray}
                            label="DC"
                            hasErrors={modalType == 'edit' ? null : (validation ? (validation.nodeCode ? false : true) : null)}
                            errorMessage="This field is required."
                            onChange={(newValue, object) => selectDCValues("nodeCode", newValue.value, "nodeName", newValue.label)}
                            value={{value:modalValues.nodeCode, label: modalValues.nodeName}}     
                            isDisabled={modalType == 'edit' ? true : false}                       
                        />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <Select
                            name={"TMSValue"}                               
                            options={tmsPartnerArray}
                            label="TMS PARTNER"
                            onChange={(newValue, object) => selectTMSValues("secondaryProviderName", newValue.value, "secondaryProviderLabel", newValue.label)}
                            value={{value: modalValues.secondaryProviderName, label: modalValues.secondaryProviderLabel}}
                            isDisabled={modalType == 'edit' ? true : false}
                        />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <Select
                            name={"CarrierValue"}
                            options={carrierArray}
                            label="CARRIER"
                            hasErrors={modalType == 'edit' ? null : (validation ? (validation.carrierGroupName ? false : true) : null)}
                            errorMessage="This field is required."
                            onChange={(newValue, object) => changeModalValues("carrierGroupName", newValue.value)}
                            value={{value: modalValues.carrierGroupName, label: modalValues.carrierGroupName}}
                            isDisabled={modalType == 'edit' ? true : false}
                        />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <Select
                            name={"ServiceLevelValue"}                         
                            options={serviceLevelArray}
                            label="SERVICE LEVEL"
                            hasErrors={modalType == 'edit' ? null : (validation ? (validation.serviceLevel ? false : true) : null)}
                            errorMessage="This field is required."
                            onChange={(newValue, object) => changeModalValues("serviceLevel", newValue.value)}
                            value={{value: modalValues.serviceLevel, label: modalValues.serviceLevel}}
                            isDisabled={modalType == 'edit' ? true : false}
                        />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <Select
                            name={"ModeValue"}                                 
                            options={modeArray}
                            label="MODE"
                            hasErrors={modalType == 'edit' ? null : (validation ? (validation.modeCode ? false : true) : null)}
                            errorMessage="This field is required."
                            onChange={(newValue, object) => changeModalValues("modeCode", newValue.value)}
                            value={{value: modalValues.modeCode, label: modalValues.modeCode}}
                            isDisabled={modalType == 'edit' ? true : false}
                        />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <Select
                            name={"MeansValue"}                                
                            options={meansArray}
                            label="MEANS"
                            hasErrors={modalType == 'edit' ? null : (validation ? (validation.meansCode ? false : true) : null)}
                            errorMessage="This field is required."
                            onChange={(newValue, object) => changeModalValues("meansCode", newValue.value)}
                            value={{value: modalValues.meansCode, label: modalValues.meansCode}}
                            isDisabled={modalType == 'edit' ? true : false}
                        />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <TextField
                            id="scacCode"
                            onChange={e => changeModalValues("scacCode", e.target.value)}
                            value={modalValues.scacCode}
                            type="text"
                            hasErrors={modalType == 'edit' ? null : (validation ? (validation.scacCode ? false : true) : null)}
                            errorMessage="This field is required."
                            label="SCAC"
                            maxLength={4}
                            onKeyPress={(event)=>blockSymbols(event)}
                            disabled={modalType == 'edit' ? true : false}
                            />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <TextField
                            id="shipVia"
                            onChange={e => changeModalValues("shipVia", e.target.value)}
                            value={modalValues.shipVia}
                            type="text"
                            hasErrors={modalType == 'edit' ? null : (validation ? (validation.shipVia ? false : true) : null)}
                            errorMessage="This field is required."
                            label="SHIPVIA"
                            maxLength={4}
                            onKeyPress={(event)=>blockSymbols(event)}
                            disabled={modalType == 'edit' ? true : false}
                            />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <TextField
                            id="shipViaDesc"
                            onChange={e => changeModalValues("shipViaDesc", e.target.value)}
                            value={modalValues.shipViaDesc}
                            type="text"
                            hasErrors={modalType == 'edit' ? null : (validation ? (validation.shipViaDesc ? false : true) : null)}
                            errorMessage="This field is required."
                            label="SHIPVIA DESCRIPTION"
                            maxLength={128}
                            disabled={modalType == 'edit' ? true : false}
                            />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <TextField
                            id="capacityBucket"
                            onChange={e => changeModalValues("capacityBucket", e.target.value)}
                            value={modalValues.capacityBucket}
                            type="text"
                            hasErrors={validation ? (validation.capacityBucket ? false : true) : null}
                            errorMessage="This field is required."
                            maxLength={4}
                            onKeyPress={(event)=>blockSymbols(event)}
                            label="CAPACITY BUCKET"
                            />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <Select
                            name={"ChannelValue"}                                          
                            options={channelsArray}
                            label="CHANNEL"
                            hasErrors={modalType == 'edit' ? null : (validation ? (validation.channel ? false : true) : null)}
                            errorMessage="This field is required."
                            onChange={(newValue, object) => changeChannelValues("channel", newValue.value)}
                            value={{value: modalValues.channel, label: modalValues.channel}}
                            isDisabled={modalType == 'edit' ? true : false}
                        />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <TextField
                            id="allocationColumnMappingAlteryx"
                            onChange={e => changeModalValues("allocationColumnMappingAlteryx", e.target.value)}
                            value={modalValues.allocationColumnMappingAlteryx}
                            type="text"
                            hasErrors={validation ? (validation.allocationColumnMappingAlteryx ? false : true) : null}
                            errorMessage="This field is required."
                            maxLength={100}
                            label="ALLOCATION COLUMN"
                            />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <TextField
                            id="vdsColumnMapping"
                            onChange={e => changeModalValues("vdsColumnMapping", e.target.value)}
                            value={modalValues.vdsColumnMapping}
                            type="text"
                            hasErrors={validation ? (validation.vdsColumnMapping ? false : true) : null}
                            errorMessage="This field is required."
                            maxLength={100}
                            label="VDS COLUMN"
                            />
                    </div>
                    <div className="radio-field">
                        <RadioGroup
                        id="bypass-lane"
                        subtitle="BYPASS LANE"
                        name="bypass-lane-group"
                        valueSelected={modalValues.byPassIndicator}
                        onChange={e => changeModalValues("byPassIndicator", e.target.value)}
                        orientation="horizontal"
                        >
                            <Radio label="Yes" value={"1"} id="bypass-lane-yes" />
                            <Radio label="No" value={"0"} id="bypass-lane-no" />
                        </RadioGroup>
                    </div>
                    <div className="radio-field">
                        <RadioGroup
                        id="osc-lane"
                        subtitle="OSC LANE &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        name="osc-lane-group"
                        valueSelected={modalValues.oscLaneIndicator}
                        onChange={e => changeModalValues("oscLaneIndicator", e.target.value)}
                        orientation="horizontal"
                        >
                            <Radio label="Yes" value={"1"} id="osc-lane-yes" />
                            <Radio label="No" value={"0"} id="osc-lane-no" />
                        </RadioGroup>
                    </div>
                    <div className="radio-field">
                        <RadioGroup
                        id="cold-lane"
                        subtitle="COLD LANE &nbsp;&nbsp;&nbsp;"
                        name="cold-lane-group"
                        valueSelected={modalValues.coldLaneIndicator}
                        onChange={e => changeModalValues("coldLaneIndicator", e.target.value)}
                        orientation="horizontal"
                        >
                            <Radio label="Yes" value={"1"} id="cold-lane-yes" />
                            <Radio label="No" value={"0"} id="cold-lane-no" />
                        </RadioGroup>
                    </div>
                    <div className="padding-top-bottom-1rem text-align-left">
                        { modalType == 'edit' ?
                            <Button onClick={() => updateShipVia()} size="small">
                                Update
                            </Button>
                        :
                            <Button onClick={()=> saveShipVia()} size="small">
                                Save
                            </Button>                        
                        }
                    </div>
                </form>
            </Modal>
        </>
    );
}
export default AddEditShipVia;
