exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___5UPfT {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___1d4qb {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___8EWaj {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___ApcUH {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.ripple___OIXw_ {\n  overflow: hidden;\n  transform: translateZ(0);\n}\n.ripple___OIXw_:active:after {\n  transform: scale(0);\n  opacity: 0.5;\n  transition: 0s;\n}\n.ripple___OIXw_:after {\n  content: '';\n  display: block;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  pointer-events: none;\n  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);\n  background-repeat: no-repeat;\n  background-position: 50%;\n  transform: scale(10);\n  opacity: 0;\n  transition: transform 0.5s, opacity 1s;\n}\n.container___39ULb {\n  width: 100%;\n  padding-right: 1rem;\n  padding-left: 1rem;\n  margin-right: auto;\n  margin-left: auto;\n}\n@media (min-width: 1200px) {\n  .container___39ULb {\n    max-width: 1140px;\n  }\n}\n@media (min-width: 1800px) {\n  .container___39ULb {\n    max-width: 1600px;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___5UPfT",
	"epic-font-base-md": "epic-font-base-md___1d4qb",
	"epic-font-marketing": "epic-font-marketing___8EWaj",
	"epic-font-brand": "epic-font-brand___ApcUH",
	"ripple": "ripple___OIXw_",
	"container": "container___39ULb"
};