import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.styl'

const Container = ({ children, className = '' }) => (
  <div className={`${styles.container} ${className}`}>{children}</div>
)

Container.propTypes = {
  children: PropTypes.PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Allow custom className to the component */
  className: PropTypes.string,
}

Container.defaultProps = {
  className: '',
}

export default Container
