import React, { useEffect, useState } from "react";
import { Text, Modal, Table, TableHeading, TableCell, TabGroup, Tab } from "@nike/eds";
import './InfoModal.css';
import "@nike/eds/dist/index.css";


function InfoModal({ infoModal }) {
    const [visible, setVisible] = useState(false);
    const [activeId, setActiveId] = useState("tab-1");
    useEffect(() => {
        setVisible(infoModal.showInfoModal);
    }, [infoModal]);

    return (
        <>
            <Modal
                onDismiss={() => setVisible(!visible)}
                isOpen={visible}
                headerSlot={<TabGroup
                    name="tab-button-group"
                    activeId={activeId}
                    onChange={e => setActiveId(e.target.id)}
                >
                    <Tab id="tab-1">Status</Tab>
                    <Tab id="tab-2">History</Tab>
                </TabGroup>}
                footerSlot={''}
                className="info-vds-modal"
            >
                <div className="info-vds-table">
                    {activeId == "tab-1" &&
                        <Table>
                            <thead>
                                <TableHeading>System Name</TableHeading>
                                <TableHeading>Updated Date</TableHeading>
                                <TableHeading>Status</TableHeading>
                                <TableHeading>Failure Reason</TableHeading>


                            </thead>
                            <tbody>
                                {
                                    infoModal.statuses && infoModal.statuses.map((element, index) => {
                                        return (
                                            <tr key={index}>
                                                <TableCell>{element.sourceSystemName ?element.sourceSystemName:"-"}</TableCell>
                                                <TableCell>{element.modifiedTimeStamp?element.modifiedTimeStamp:"-" }</TableCell>
                                                <TableCell>{element.status}</TableCell>
                                                <TableCell>{element.failureReason ? element.failureReason : "-"}</TableCell>

                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                    {
                        activeId == "tab-2" &&
                        <Table>
                            <thead>
                                <TableHeading>User</TableHeading>
                                <TableHeading>Date</TableHeading>
                                <TableHeading>Source</TableHeading>
                                <TableHeading>VDS Percentage</TableHeading>
                            </thead>
                            <tbody>
                                {
                                    infoModal.history && infoModal.history.map((e, index) => {
                                        return (
                                            <tr key={index}>
                                                <TableCell>{e.modifiedUser}</TableCell>
                                                <TableCell>{e.modifiedTimeStamp}</TableCell>
                                                <TableCell>{e.modifiedSource ? e.modifiedSource : "-"}</TableCell>
                                                <TableCell className="text-align-center">{e.volume_percentage ? e.volume_percentage : "-"}</TableCell>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </div>
            </Modal>
        </>
    );
}

export default InfoModal;