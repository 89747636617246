import styles from './styles.styl'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DefaultLink } from '../util/index.js'

function Breadcrumbs({ RouterLink, links, className }) {
  const Link = RouterLink || DefaultLink
  return (
    <div className={classnames(styles.breadcrumbs, className)}>
      <div className={styles.links}>
        {links.map(({ title, href }, i) => {
          const link = href ? <Link href={href}>{title}</Link> : title
          return (
            <span key={title + i} className={styles.link}>
              {i > 0 && i <= links.length && <span className={styles.slash}>{'/'}</span>}
              {link}
            </span>
          )
        })}
      </div>
    </div>
  )
}

Breadcrumbs.propTypes = {
  /** Links for breadcrumbs */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      /** path of link */
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      /** link text */
      href: PropTypes.string,
    })
  ),
  /** Classname applied to container div */
  className: PropTypes.string,
  /** RouterLink component used for links */
  RouterLink: PropTypes.func,
}

Breadcrumbs.defaultProps = {
  links: [],
  className: '',
  iconType: 'Home',
}

export default Breadcrumbs
