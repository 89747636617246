import React from 'react'
import classnames from 'classnames'
import ReactSelect from 'react-select'
import { default as ReactSelectCreatable } from 'react-select/creatable'
import PropTypes from 'prop-types'
import { Label } from '@EPIC'

import styles from './Select.styl'

const Select = ({
  className,
  isCreatable,
  border,
  hasErrors,
  errorMessage,
  label,
  required,
  inverse,
  ...rest
}) => {
  const Component = isCreatable ? ReactSelectCreatable : ReactSelect
  let selectClass = classnames(styles.Select, className)
  const customStyles = {
    dropdownIndicator: (base) => ({
      ...base,
      color: 'inherit',
      '&:hover': {
        color: 'inherit',
      },
    }),
    control: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
  }

  if (border) {
    selectClass = classnames(selectClass, styles.withBorder)
  }

  if (hasErrors) {
    selectClass = classnames(selectClass, styles.hasErrors)
  }

  if (inverse) {
    selectClass = classnames(selectClass, styles.inverse)
  }
  return (
    <Label label={label} hasErrors={hasErrors} errorMessage={errorMessage} required={required}>
      <Component
        styles={customStyles}
        className={selectClass}
        classNamePrefix={'Select'}
        {...rest}
      />
    </Label>
  )
}

Select.propTypes = {
  /** The options the user can select */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  /** Add a border to the select */
  border: PropTypes.bool,
  /** Class Name escape hatch */
  className: PropTypes.string,
  /** Currently selected option */
  value: PropTypes.any,
  /** Label for select */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /** Function triggered on change events */
  onChange: PropTypes.func.isRequired,
  /** Focus this field on load */
  autoFocus: PropTypes.bool,
  /** Disable this field */
  isDisabled: PropTypes.bool,
  /** Make clearable this field */
  isClearable: PropTypes.bool,
  /** Placeholder text displayed when no option is selected */
  placeholder: PropTypes.string,
  /** Current filter input value */
  inputValue: PropTypes.string,
  /** Function triggered on filter input change */
  onInputChange: PropTypes.func,
  /** Allows the user to add options */
  isCreatable: PropTypes.bool,
  /** Function triggered on selection of user-provided option (requires *isCreatable*) */
  onCreateOption: PropTypes.func,
  /** Select is currently open */
  menuIsOpen: PropTypes.bool,
  /** Function triggered on select open */
  onMenuOpen: PropTypes.func,
  /** Function triggered on select closed */
  onMenuClose: PropTypes.func,
  /** Sets input as required and also adds red star to label */
  required: PropTypes.bool,
  /** Sets whether input is in error state */
  hasErrors: PropTypes.bool,
  /** Error message to be displayed to user, appears in top right of input */
  errorMessage: PropTypes.string,
  /** Inverse color option */
  inverse: PropTypes.bool,
}

Select.defaultProps = {
  options: [],
  autoFocus: false,
  isDisabled: false,
  isClearable: false,
  isCreatable: false,
  border: true,
  placeholder: 'Select...',
  inverse: false,
}

export default Select
