import PropTypes from 'prop-types'
import React, { useState } from 'react'
import classnames from 'classnames'
import styles from './styles.styl'
import { DefaultLink } from '../../util/index.js'
import { ActionInput, Icon } from '@EPIC'

const keyGen = (...args) => args.join('-').trim()
const ExternalNavLink = ({ path, children, className = null }) => (
  <a href={path} className={className} target='_blank' rel='noopener noreferrer'>
    {children}
  </a>
)

function NavBarLinks(props) {
  const { routes, RouterLink, activeRoute, fixed } = props
  return routes.map(({ path, text, external, exact, title, menuItems, className }) => {
    let activeClass = ''
    if (exact) {
      activeClass = activeRoute === path ? styles.active : ''
    } else if (path) {
      activeClass = activeRoute.includes(path) ? styles.active : ''
    }

    // Show external link
    if (external)
      return (
        <ExternalNavLink key={keyGen(text, path)} path={path} className={className}>
          <span className={styles.link}>{text}</span>
        </ExternalNavLink>
      )

    // Show dropdown link (MegaNav)
    if (menuItems) {
      return (
        <SubNav
          key={keyGen(text, path)}
          title={title}
          menuItems={menuItems}
          RouterLink={RouterLink}
          fixed={fixed}
        >
          <RouterLink href={path} className={className}>
            <span className={classnames(styles.underline, styles.link, activeClass)}>{text}</span>
          </RouterLink>
        </SubNav>
      )
    }

    // Show default link
    return (
      <RouterLink key={keyGen(text, path)} href={path} className={className}>
        <span className={classnames(styles.underline, styles.link, activeClass)}>{text}</span>
      </RouterLink>
    )
  })
}

function SubNav({ title, menuItems, RouterLink, children, fixed }) {
  const groupedMenuItems = []
  for (let i = 0; i < menuItems.length; i += 10) {
    groupedMenuItems.push(menuItems.slice(i, i + 10))
  }
  const [scrollOffset, setScrollOffset] = useState(0)
  const [subNavOpen, setSubNavOpen] = useState(false)
  const onOpenSubNav = () => {
    setSubNavOpen(true)
    if (!fixed) {
      document.body.style.overflowY = 'hidden'
      setScrollOffset(window.scrollY)
    }
  }
  const onCloseSubNav = () => {
    setSubNavOpen(false)
    if (!fixed) {
      document.body.style.overflowY = 'auto'
    }
  }

  return (
    <div
      className={classnames(styles.subNavWrapper, { [styles.hover]: subNavOpen })}
      onMouseEnter={onOpenSubNav}
      onMouseLeave={onCloseSubNav}
      onClick={onCloseSubNav}
    >
      {children}
      <div className={classnames(styles.subNavBg, styles.subNavBgOverlay)}>
        <div
          className={styles.subNavBg}
          style={{
            transform: `translateY(-${scrollOffset}px)`,
          }}
        >
          <div className={styles.subNavContent}>
            {groupedMenuItems.map((group, i) => {
              return (
                <div key={i} className={styles.subNavColumn}>
                  <div className={styles.subNavTitle}>{title && i === 0 && <h4>{title}</h4>}</div>
                  {group.map(({ text, path }) => {
                    return (
                      <RouterLink
                        key={keyGen(text, path)}
                        className={styles.subNavLink}
                        href={path}
                      >
                        <span>{text}</span>
                      </RouterLink>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

function NavBar({
  name,
  logo,
  aside,
  routes,
  inverse,
  onSearch,
  searchPlaceholder,
  rootPath,
  className,
  RouterLink,
  fixed,
}) {
  const [activeRoute, setActiveRoute] = useState(window.location.pathname)
  const navClassName = classnames(styles.navbar, className, {
    [styles.inverse]: inverse,
  })

  function NavLink(props) {
    let Component = RouterLink || DefaultLink
    return (
      <Component
        {...props}
        onClick={() => {
          setActiveRoute(props.href)
        }}
      />
    )
  }

  return (
    <div className={classnames(navClassName, { [styles.fixed]: fixed })}>
      <nav className={styles.navigation}>
        <NavLink className={styles.logo} href={rootPath} aria-label='home link'>
          {logo &&
            (typeof logo === 'string' ? (
              <img className={className} src={logo} alt={`${name} logo`} />
            ) : (
              logo
            ))}
          {logo === undefined && <Icon size='l' type='NikeApp' className={styles.nikeLogo} />}
          {name ? <span className={styles.title}>{name}</span> : null}
        </NavLink>

        <div className={styles.innerNav}>
          <NavBarLinks
            activeRoute={activeRoute}
            routes={routes}
            RouterLink={NavLink}
            rootPath={rootPath}
            fixed={fixed}
          />
        </div>

        {aside && <div className={styles.asideContainer}>{aside}</div>}
        {onSearch && (
          <div className={classnames(styles.asideContainer, styles.searchContainer)}>
            <ActionInput onAction={onSearch} ariaLabel='Search' placeholder={searchPlaceholder} />
          </div>
        )}
      </nav>
    </div>
  )
}

NavBar.propTypes = {
  /** Application Name */
  name: PropTypes.string,
  /** Logo (http url or node) */
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Route objects that describe a react-router route */
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      /** Route path */
      path: PropTypes.string,
      /** Text to display for the route */
      text: PropTypes.string.isRequired,
      /** Search value for nav route */
      search: PropTypes.string,
      /** Match the route exact: true [default] OR false */
      exact: PropTypes.bool,
      /** Should render as external anchor tag */
      external: PropTypes.bool,
      /** class applied to dropdown button */
      className: PropTypes.string,
    })
  ).isRequired,
  /** The root path to the application (eg / or /some value) */
  rootPath: PropTypes.string,
  /** Escape hatch for styling */
  className: PropTypes.string,
  /** Based on the react router you are using pass in the Link component */
  RouterLink: PropTypes.func,
  /** Right side of navbar */
  aside: PropTypes.object,
  /** Makes primary color black, secondary white */
  inverse: PropTypes.bool,
  /** Function to run on search action, enables search input */
  onSearch: PropTypes.func,
  /** Optional placeholder for search input */
  searchPlaceholder: PropTypes.string,
  /** NavBar is fixed to the top of the window */
  fixed: PropTypes.bool,
}

NavBar.defaultProps = {
  rootPath: '/',
  routes: [],
  searchPlaceholder: 'Search',
}

export default NavBar
