import React from 'react'
import PropTypes from 'prop-types'
import ReactCollapsible from 'react-collapsible'
import classnames from 'classnames'
import { Icon } from '@EPIC'

import styles from './Collapsible.styl'

const Trigger = ({ isOpen, title, subtitle }) => (
  <div className={styles.trigger}>
    <div className={styles.triggerIcon}>
      <Icon type={isOpen ? 'CaretUp' : 'CaretDown'} fontSize='28px' />
    </div>
    <div>
      <div className={styles.title}>
        <span>{title}</span>
      </div>
      {subtitle && (
        <div className={styles.subtitle}>
          <span>{subtitle}</span>
        </div>
      )}
    </div>
  </div>
)

function Collapsible({
  className,
  innerClassName,
  title,
  subtitle,
  transitionTime,
  children,
  isOpen,
}) {
  return (
    <ReactCollapsible
      className={className}
      openedClassName={className}
      transitionTime={transitionTime}
      open={isOpen}
      triggerWhenOpen={<Trigger title={title} subtitle={subtitle} isOpen />}
      trigger={<Trigger title={title} subtitle={subtitle} />}
    >
      <div className={classnames(styles.collapsibleInner, innerClassName)}>{children}</div>
    </ReactCollapsible>
  )
}

Collapsible.propTypes = {
  /** User can pass in a class name to override styles */
  className: PropTypes.string,
  /** Override inner container styles */
  innerClassName: PropTypes.string,
  /** Heading title */
  title: PropTypes.string.isRequired,
  /** Optional subtitle to appear under title */
  subtitle: PropTypes.string,
  /** Time (ms) to open collapsible */
  transitionTime: PropTypes.number,
  /** Begin component in the open state */
  isOpen: PropTypes.bool,
}

Collapsible.defaultProps = {
  className: '',
  innerClassName: '',
  title: '',
  subtitle: null,
  transitionTime: 50,
  isOpen: false,
}

export default Collapsible
