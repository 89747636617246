import styles from './style.styl'
import React from 'react'
import PropTypes from 'prop-types'

const Overlay = ({ show, onClick, className, children }) => (
  <div className={`${styles.overlayContainer}  ${className || ''} ${show ? styles.show : ''}`}>
    <div className={styles.overlay} onClick={onClick} />
    {children}
  </div>
)

Overlay.propTypes = {
  /** Dictates whether or not the overlay is visible */
  show: PropTypes.bool.isRequired,
  /** Optional function that fires when overlay is clicked */
  onClick: PropTypes.func,
  /** Optional additional custom className */
  className: PropTypes.string,
}

export default Overlay
