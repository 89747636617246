import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'

import styles from './RadioButton.styl'

// Static Value to ensure uniqueness
let ID = 0

// TODO add error reporting, disabled
const RadioButton = forwardRef(function RadioButton(
  { value, onChange, checked, name, label, ...rest },
  ref
) {
  // useState initial-value function is only called on first-render
  const [_id] = useState(() => `ep-checkbox-${++ID}`)
  const id = rest.id || _id
  return (
    <label className={styles.optionLabel} htmlFor={id}>
      <input
        onChange={onChange ? () => onChange(value) : undefined}
        checked={checked}
        className={styles.input}
        name={name}
        id={id}
        value={value}
        type='radio'
        ref={ref}
        {...rest}
      />
      <span className={styles.radio}>
        <span className={styles.inner}></span>
      </span>
      {label}
    </label>
  )
})

RadioButton.propTypes = {
  /** name attribute of radio input element */
  name: PropTypes.string,
  /** value of radio input element */
  value: PropTypes.any,
  /** label for radio input element */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /** current selection state of radio input element */
  checked: PropTypes.bool,
  /** onChange handler for radio input element */
  onChange: PropTypes.func.isRequired,
}

export default RadioButton
