import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './style.styl'

// The field set is used for the animation, legend is hidden
const TextFieldSet = ({ legend, floating }) => (
  <fieldset className={styles.textFieldset}>{floating ? <legend>{legend}</legend> : null}</fieldset>
)

const Label = ({ label, className, ...props }) => (
  <label {...props} className={classnames(styles.textInputLabel, className)}>
    <span className={styles.labelText}>{label}</span>
  </label>
)

// Going to be the Form based label
export const FormBase = ({ label, placeholder, required, ...props }) => {
  let classList = styles.container
  if (props.isActive) classList = classnames(classList, styles.active)
  if (props.disabled) classList = classnames(classList, styles.disabled)
  if (props.hasErrors) classList = classnames(classList, styles.hasErrors)
  label = label && required ? `${label}*` : label
  placeholder = placeholder && required ? `${placeholder}*` : placeholder

  const msg = props.helperText || ''
  return (
    <div className={classnames(classList, props.className)}>
      {props.children}
      {!placeholder && <Label label={label} required={required}></Label>}
      <span className={styles.helperTextContainer}>
        <span className={styles.helperText}>{props.hasErrors ? props.errorMessage : msg}</span>
        {!props.disabled && props.maxLength && (
          <span className={styles.maxLength}>{`${props.textLength} / ${props.maxLength}`}</span>
        )}
      </span>
      <TextFieldSet floating={!placeholder && props.floating} legend={props.isActive && label} />
    </div>
  )
}

FormBase.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
  textLength: PropTypes.number,
  maxLength: PropTypes.number,
  isActive: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  floating: PropTypes.bool,
  hasErrors: PropTypes.bool,
}

FormBase.defaultProps = {
  className: '',
  placeholder: '',
  disabled: false,
  floating: true,
}

const formTypes = [
  'input',
  'label',
  'select',
  'textarea',
  'button',
  'fieldset',
  'legend',
  'datalist',
  'output',
  'option',
  'optgroup',
]

// Form elements only: input, textarea, select, etc (see formTypes)
const FormBaseCtrl = React.forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value || '')
  const [active, setActive] = useState(false)
  const compProps = { ...props }

  // Gather base props
  const baseProps = Object.keys(FormBase.propTypes).reduce(
    (acc, cv) => ({ ...acc, [cv]: compProps[cv] }),
    {}
  )

  const {
    required,
    placeholder,
    children,
    label,
    errorMessage,
    helperText,
    hasErrors,
    floating,
    ...rest
  } = props
  ref = ref || useRef()

  const onChange = (e) => {
    setValue(e.target.value)
    props.onChange && props.onChange(e)
  }

  // Would use :active & :focus selectors but it depends if the input value is set or not as well.
  const onFocus = (e) => {
    props.onFocus && props.onFocus(e)
    setActive(true)
  }

  const onBlur = (e) => {
    props.onBlur && props.onBlur(e)
    // Check ref to handle the scenario that a user directly modifies input.value
    if (ref.current && ref.current.value === '') {
      setValue('')
      setActive(false)
    }
  }

  // Grab single element
  const boundedChild = React.cloneElement(children, {
    ref,
    required,
    placeholder: placeholder && required ? `${placeholder} *` : placeholder,
    value,
    ...rest,
    className: styles.baseFormElement,
    onChange,
    onFocus,
    onBlur,
  })

  return (
    <FormBase
      {...baseProps}
      textLength={
        boundedChild.props.value?.toString() ? boundedChild.props.value.toString().length : 0
      }
      label={label}
      hasErrors={hasErrors}
      errorMessage={errorMessage}
      helperText={helperText}
      isActive={active || !!boundedChild.props.value?.toString()?.length}
      floating={floating}
    >
      {boundedChild}
    </FormBase>
  )
})

FormBaseCtrl.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
  textLength: PropTypes.number,
  maxLength: PropTypes.number,
  isActive: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  floating: PropTypes.bool,
  hasErrors: PropTypes.bool,

  onChange: PropTypes.func,
  children(props, propName, componentName) {
    let prop = props[propName]
    let types = formTypes

    // Only accept a single child, of the appropriate type
    if (React.Children.count(prop) !== 1 || types.indexOf(prop.type) === -1) {
      return new Error(
        '`' +
          componentName +
          '` ' +
          'should have a single child of the following types: ' +
          ' `' +
          types.join('`, `') +
          '`.'
      )
    }
  },
}

export default FormBaseCtrl
