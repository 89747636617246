import styles from './Modal.styl'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Overlay } from '@EPIC'

class Modal extends React.Component {
  state = {
    show: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (typeof props.show === 'boolean') {
      return { ...state, show: props.show }
    }
    return null
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.keyDown)
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.keyDown)
  }

  close = () => {
    this.setState({ show: false })
    return this.props.onClose && this.props.onClose()
  }

  keyDown = (e) => {
    if (e.key === 'Escape') {
      this.close()
    }
  }

  render() {
    const {
      className,
      swoosh,
      children,
      title,
      closeButton,
      modalSize,
      fadeIn,
      overlayClassname,
    } = this.props
    const { show } = this.state

    let sizeClass
    if (modalSize === 'sm' || modalSize === 'md' || modalSize === 'lg') {
      sizeClass = modalSize
    }

    return (
      <Fragment>
        <Overlay
          className={`${fadeIn ? styles.fadeIn : ''} ${overlayClassname}`}
          show={show}
          onClick={this.close}
        />
        <div
          className={`${styles.modal} ${fadeIn ? styles.fadeIn : ''} ${
            sizeClass ? styles[sizeClass] : ''
          } ${show ? styles.show : ''} epic-font-base ${className}`}
        >
          {closeButton ? (
            <div className={styles.close}>
              <i onClick={this.close} className='epic-icon epic-icon-close-x'></i>
            </div>
          ) : null}
          {swoosh ? (
            <div className={styles.swoosh}>
              <i className='epic-icon epic-icon-nike-logo'></i>
            </div>
          ) : null}
          {title && <h2>{title}</h2>}
          <div className={styles.modalInner}>{children}</div>
        </div>
      </Fragment>
    )
  }
}

Modal.Title = function({ children }) {
  return <div className={styles.title}>{children}</div>
}

Modal.Content = function({ children }) {
  return <div className={styles.content}>{children}</div>
}

Modal.Actions = function({ children }) {
  return <div className={styles.actions}>{children}</div>
}

Modal.propTypes = {
  /** Dictates whether or not the modal is visible */
  show: PropTypes.bool,
  /** Legacy title prop */
  title: PropTypes.string,
  /** Custom Content for the modal, renders beneath the title */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Custom className for the modal */
  className: PropTypes.string,
  /** Custom className for the overlay (rendered behind modal) */
  overlayClassname: PropTypes.string,
  /** Nike swoosh at top center of modal */
  swoosh: PropTypes.bool,
  /** Specifies presence of `X` close button in top right of modal */
  closeButton: PropTypes.bool,
  /** Function to fire on modal close */
  onClose: PropTypes.func,
  /** Modal size specification (sm, md, lg) */
  modalSize: PropTypes.string,
  /** Specifies presence of fade animation */
  fadeIn: PropTypes.bool,
}

Modal.defaultProps = {
  show: false,
  swoosh: false,
  className: '',
  overlayClassname: '',
  closeButton: true,
  fadeIn: true,
}

export default Modal
