import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTable, useSortBy, useGlobalFilter, useRowSelect } from 'react-table'

import styles from './styles.styl'

import {
  TableContainer,
  Tbody,
  TableHeader,
  BulkSelectRow,
  IndeterminateCheckbox,
} from './CommonTableComponents.js'

import {
  SearchBar,
  FilterButton,
  FilterSection,
  ColumnFilterButton,
  NoPagination,
  NoDataRow,
} from './PagedFilterTableComponents.js'

const setMaxHeight = ({ visibleRows, isCompact }) => {
  const borderSize = 1.5
  const cellHeight = isCompact ? 16 * 2 + 20 + borderSize : 24 * 2 + 20 + borderSize
  return { maxHeight: `${visibleRows * cellHeight - 1}px` } // minus one to hide the last row line
}

function Table({
  columns,
  data,
  withSearchBar,
  withColumnFilter,
  filters,
  onFiltersChange,
  searchBarPlaceholder,
  className,
  visibleRows,
  isCompact,
  withSelectableRows,
  onSelectedRowChange,
  BulkSelectRowAside,
}) {
  const {
    state: { globalFilter, selectedRowIds },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
    getToggleHideAllColumnsProps,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,

    useRowSelect,
    (hooks) => {
      if (withSelectableRows) {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
          },
          ...columns,
        ])
      }
    }
  )

  const [showFilters, setShowFilters] = useState(false)
  const [showAllFilters, setShowAllFilters] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({})
  const [filterFilterQueries, setFilterFilterQueries] = useState({})
  const [columnFilterVisible, setColumnFilterVisible] = useState(false)
  const [columnFilterQuery, setColumnFilterQuery] = useState('')

  const setFilters = (newFilterState) => {
    setSelectedFilters(newFilterState)
    onFiltersChange && onFiltersChange(newFilterState)
  }
  const handleChangeSelectedFilters = (filterGroup, event) => {
    if (!selectedFilters[filterGroup]) selectedFilters[filterGroup] = []
    setFilters({
      ...selectedFilters,
      [filterGroup]: event.target.checked
        ? [...selectedFilters[filterGroup], event.target.name]
        : [...selectedFilters[filterGroup]].filter((item) => item !== event.target.name),
    })
  }
  const handleSelectAllFilters = (filterGroup, childFilterArray) => {
    setFilters({
      ...selectedFilters,
      [filterGroup]: childFilterArray.map((item) => item.accessor),
    })
  }
  const handleClearSelectedFilters = (filterGroup) => {
    if (filterGroup) {
      setFilters({
        ...selectedFilters,
        [filterGroup]: [],
      })
    } else {
      setFilters({})
    }
  }
  const handleUpdateFilterFilterQuery = (filterGroup, e) => {
    setFilterFilterQueries({
      ...filterFilterQueries,
      [filterGroup]: e.target.value,
    })
  }

  if (columns.length === 0) return null

  const searchBarProps = {
    globalFilter,
    setGlobalFilter,
    searchBarPlaceholder,
  }

  const headerProps = {
    headerGroups,
    isCompact,
  }

  const bodyProps = {
    rows,
    prepareRow,
    isCompact,
  }

  const bulkSelectProps = {
    selectedRowIds,
    selectedFlatRows,
    toggleAllRowsSelected,
    clearSelectedRow: () => toggleAllRowsSelected(false),
  }

  withSelectableRows &&
    onSelectedRowChange &&
    onSelectedRowChange({ selectedRowIds, selectedFlatRows })

  const filterCount = useMemo(
    () => Object.keys(selectedFilters).reduce((acc, curr) => acc + selectedFilters[curr].length, 0),
    [selectedFilters]
  )

  const handleFilterBtnClick = useCallback(() => setShowFilters(!showFilters), [showFilters])

  return (
    <div {...className}>
      <div className={styles.searchFilterSection}>
        {withSearchBar && <SearchBar {...searchBarProps} />}
        {!!filters.length && (
          <FilterButton
            showFilters={showFilters}
            handleFilterBtnClick={handleFilterBtnClick}
            filterCount={filterCount}
          />
        )}
        {withColumnFilter && (
          <ColumnFilterButton
            columns={allColumns}
            toggleAll={getToggleHideAllColumnsProps}
            columnFilterVisible={columnFilterVisible}
            setColumnFilterVisible={setColumnFilterVisible}
            columnFilterQuery={columnFilterQuery}
            setColumnFilterQuery={setColumnFilterQuery}
            withSelectableRows={withSelectableRows}
          />
        )}
      </div>
      {showFilters ? (
        <FilterSection
          selectedFilters={selectedFilters}
          handleChangeSelectedFilters={handleChangeSelectedFilters}
          handleSelectAllFilters={handleSelectAllFilters}
          handleClearSelectedFilters={handleClearSelectedFilters}
          showAllFilters={showAllFilters}
          setShowAllFilters={setShowAllFilters}
          filters={filters}
          filterFilterQueries={filterFilterQueries}
          handleUpdateFilterFilterQuery={handleUpdateFilterFilterQuery}
        />
      ) : null}
      <div className={`${styles.scrollTable}`}>
        {withSelectableRows && selectedFlatRows.length > 0 && (
          <BulkSelectRow {...bulkSelectProps}>
            {BulkSelectRowAside && <BulkSelectRowAside {...bulkSelectProps} />}
          </BulkSelectRow>
        )}

        {data.length === 0 ? (
          <NoDataRow />
        ) : (
          <TableContainer
            className={classnames(
              withSelectableRows && styles.withCheckboxColumn,
              withSelectableRows && selectedFlatRows.length > 0 && styles.bulkSelectRowActive
            )}
            {...getTableProps()}
          >
            <>
              <TableHeader {...headerProps} />
              <Tbody {...getTableBodyProps()} style={setMaxHeight({ visibleRows, isCompact })}>
                {data.length === 0 ? <NoDataRow /> : <NoPagination {...bodyProps} />}
              </Tbody>
            </>
          </TableContainer>
        )}
      </div>
    </div>
  )
}

Table.propTypes = {
  /** Allow custom className to the component */
  className: PropTypes.string,
  /** Column headers (`Header` value is the text that will show up in the table & `accessor` value is which value shows up in the cell)*/
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      accessor: PropTypes.string.isRequired,
      defaultCanSort: PropTypes.bool,
      disableSortBy: PropTypes.bool,
      sortDescFirst: PropTypes.bool,
      sortInverted: PropTypes.bool,
      sortType: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ).isRequired,
  /** Array of data with `accessor` as the keys  */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Allow for table text search */
  withSearchBar: PropTypes.bool,
  /** Object of filter properties */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          accessor: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  /** Function called when selected filters change, is passed selected filter state */
  onFiltersChange: PropTypes.func,
  /** Placeholder text to show up in the Table searchBar */
  searchBarPlaceholder: PropTypes.string,
  /** Less padding around the Header & Body's cells */
  isCompact: PropTypes.bool,
  /** (Default table only) Allow for body table scrolling, number of rows to show */
  visibleRows: PropTypes.number,
  /** Provide a side panel with additional information about a selected row */
  withSelectableRows: PropTypes.bool,
  /** Get the selected */
  onSelectedRowChange: PropTypes.func,
  /** Pass in a Component to be shown in the Bulk Action Row with access to `selectedRowIds`, `selectedFlatRows`, & `clearSelectedRow` parameters */
  BulkSelectRowAside: PropTypes.func,
  /** Allow for column filtering */
  withColumnFilter: PropTypes.bool,
}

Table.defaultProps = {
  className: '',
  columns: [],
  data: [],
  withSearchBar: false,
  filters: [],
  onFiltersChange: null,
  searchBarPlaceholder: 'Search',
  isCompact: false,

  visibleRows: 10,
  withSelectableRows: false,
  withColumnFilter: false,
}
export default Table
