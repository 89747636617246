exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___32M_N {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___3_ETz {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___2ggnr {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___25FnW {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.ripple___3beEP {\n  overflow: hidden;\n  transform: translateZ(0);\n}\n.ripple___3beEP:active:after {\n  transform: scale(0);\n  opacity: 0.5;\n  transition: 0s;\n}\n.ripple___3beEP:after {\n  content: '';\n  display: block;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  pointer-events: none;\n  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);\n  background-repeat: no-repeat;\n  background-position: 50%;\n  transform: scale(10);\n  opacity: 0;\n  transition: transform 0.5s, opacity 1s;\n}\n.navTitle___ETYNI {\n  border-bottom: solid 2px #b2b2b4;\n  font-size: 22px;\n}\n.navLinks___2lf72 {\n  font-size: 18px;\n  font-weight: 400;\n  margin: 15px 0;\n  padding-left: 0;\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.activeNavItem___BNFx5 .activeLinkBar___25oVt {\n  display: block;\n  background-color: #fa5400;\n  position: absolute;\n  line-height: 1px;\n  height: 4px;\n  border-radius: 2px;\n  width: 100%;\n  left: 0;\n  top: 25px;\n}\n.uppercase___MVtDn {\n  text-transform: uppercase;\n}\n.listItem___3hnoJ {\n  text-align: left;\n  padding-left: 5px;\n  line-height: 46px;\n  padding-left: 0;\n  list-style: none;\n}\n.listItem___3hnoJ a {\n  color: #0070f5;\n  text-decoration: none;\n  position: relative;\n}\n.listItem___3hnoJ a:hover {\n  color: #2a72b2;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___32M_N",
	"epic-font-base-md": "epic-font-base-md___3_ETz",
	"epic-font-marketing": "epic-font-marketing___2ggnr",
	"epic-font-brand": "epic-font-brand___25FnW",
	"ripple": "ripple___3beEP",
	"navTitle": "navTitle___ETYNI",
	"navLinks": "navLinks___2lf72",
	"activeNavItem": "activeNavItem___BNFx5",
	"activeLinkBar": "activeLinkBar___25oVt",
	"uppercase": "uppercase___MVtDn",
	"listItem": "listItem___3hnoJ"
};