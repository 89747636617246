import React, { useEffect, useState, useRef } from "react";
import { Text, TextField, Icon, Select, Button, Table, TableCell, TableHeading, ButtonGroup, Modal, Snack, Snackbar, Popover, Spinner, RadioGroup, Radio} from "@nike/eds";
import axios from 'axios';
import apiService from '../../services/service';
import API_INTERFACE_URI from "../../constants/uri.constants";
import "./AddEditVDS.css";
import SnackBar from "../SnackBar/SnackBar.js";

function AddEditVDS({showModal, closeAddEditModal, editDetails, vdsUpdated, dcArray, modeArray}) {
    const [visible, setVisible] = useState(showModal);
    const [snackModalMessage, setShowModalSnack] = useState({message: "", status:""});
    const [validation, setValidation] = useState();
    const [activateValidation, setActivateValidation] = useState(true);
    const [modalValues, setModalValues] = useState({});
    async function closeModal(prop){
        await setVisible(false);
        closeAddEditModal(prop);
    }
    
    useEffect(()=>{
        if(editDetails && editDetails.id){
            var d = editDetails.date.split('/');
            let tempDate = d[2] + '-' + d[0] + '-' + d[1];
            setModalValues({...editDetails, date: tempDate})
        }
    }, [editDetails])

    async function updateData(){
        let data = {records:[]};
        let checksum = 0.00;
        let isVdsRowEligibleForUpdate=false;
        Object.entries(modalValues).forEach(([key, value], index) => {
            if(['nodeCode', 'modeCode', 'date', 'nodeName'].includes(key)){
                data = {
                    ...data,
                    [key]: value,
                }
            }else if(!['checksum', 'providerName', 'providerServiceId', 'shipVia', 'nikeServiceLevelCode', 'vdsColumnName', 'id', 'vdsPercentage', 'history', 'statuses', 'vds'].includes(key) && !['id', 'statuses', 'history'].includes(key.split('|')[0])){
                if (value != editDetails[key]) {
                    isVdsRowEligibleForUpdate=true;       
                } 
                data.records.push({
                    "vdsColumnName": key,
                    "vdsPercentage": value ? value : 0
                })
                checksum = parseInt(checksum + (value * 100));
            }

            if(key.split('|')[0] == 'id'){
                data.records.map((ele, index)=>{
                    if(ele.vdsColumnName == key.split('|')[1]){
                        data.records[index] = {...ele,
                            id : value.split('|')[5] != "undefined" ? value.split('|')[5] : "",
                            serviceLevel : value.split('|')[4],
                            providerServiceId : value.split('|')[3],
                            providerName : value.split('|')[2],
                            shipVia : value.split('|')[1]
                        }
                    }
                })
            }
        });
        if (isVdsRowEligibleForUpdate) {
            if((checksum/100) == 1 ){ 
                    try{
                        const res = await apiService.put(
                            API_INTERFACE_URI.UPDATE_VDS_DATA , data
                        );
                        if(res && res.data){                    
                            vdsUpdated({message : "VDS Percentage is updated successfully.", status: "success"});
                            setModalValues({})
                            closeModal(!visible); 
                        }
                    } catch(e){
                        console.log('error', e);
                        setShowModalSnack({ message: "Something went wrong. Please contact your administrator.", status: "error" });
                    }
            }else{
                setShowModalSnack({ message: "Checksum of all VDS Percentage should be 1 !", status: "error" });
            }    
        }
        else{
            closeModal(!visible); 
        }
    }

    function onDismissSnack(prop){
        setShowModalSnack(false)
    }
    
    function changeModalValues(field, value){
        setModalValues({...modalValues, [field]: value});
        if(activateValidation){
            setValidation({...modalValues, [field]: value});
        }
    }

    function fixedTwoDecimalPlaces(field, value){
        setModalValues({...modalValues, [field]: Math.round((value ? value : 0) * 100) / 100});
    }
    
    function blockE(event) {
        if (event.which != 8 && event.which != 0 && (event.which < 46 || event.which > 57) && event.which != 47) {
            event.preventDefault();
        }
    }

    return (
        <div>
            <Modal
            onDismiss={() => closeModal(!visible)}
            isOpen={visible}
            headerSlot={'VDS'}
            className="vds-modal"
            >
                {snackModalMessage && snackModalMessage.message ? 
                    <SnackBar   onDismissFunction={() => onDismissSnack()} 
                                params={{"status": snackModalMessage.status, "message": snackModalMessage.message}} 
                    /> 
                : null}
                <form className="vds-modal-content">
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <TextField
                            id="date"
                            value={modalValues.date}
                            type="date"
                            subtitle="DATE"
                            disabled
                        />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <Select
                            name={"DCValue"}                                      
                            options={dcArray}
                            label="DC"
                            value={{value:modalValues.nodeCode, label: modalValues.nodeName}}     
                            isDisabled={true}                       
                        />
                    </div>
                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                        <Select
                            name={"ModeValue"}                                 
                            options={modeArray}
                            label="MODE"
                            value={{value: modalValues.modeCode, label: modalValues.modeCode}}
                            isDisabled={true}
                        />
                    </div>
                    {
                        Object.entries(modalValues).sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0)).map(([que, ans])=>{
                            let extraKey = ['history', 'id', 'statuses', 'vds', 'nodeCode', 'nodeName', 'date', 'modeCode', 'vdsColumnName', 'nikeServiceLevelCode', 'vdsPercentage', 'checksum', 'providerName', 'providerServiceId', 'shipVia'];
                            let extraFields = ['id', 'statuses', 'history']
                            if(!extraKey.includes(que) && !extraFields.includes(que.split('|')[0])){
                                return(
                                    <div className="text-align-left input-box padding-top-bottom-point-5-rem">
                                        <TextField
                                            id={que}
                                            onChange={e => changeModalValues([que], e.target.value)}
                                            value={ans}
                                            type="number"
                                            label={que.toUpperCase()}
                                            onKeyPress={(event) => blockE(event)}
                                            onBlur={(e)=>fixedTwoDecimalPlaces([que], e.target.value)}
                                            min={0.00}
                                            max={1.00}
                                            hasErrors={validation ? (validation[que] <= 1.00 ? false : true) : null}
                                            errorMessage="VDS Percentage should be less than or equals to 1."
                                            />
                                    </div>     
                                );
                            }
                        })
                    }                                   
                    <div className="padding-top-bottom-1rem text-align-left">                        
                        <Button onClick={() => updateData()} size="small">
                            Update
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    );
}
export default AddEditVDS;